import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";

const css = `
<style>
</style>
`

export class DeletionContainer extends BaseComponent {
    initCallback() {
        document.querySelector('deletion-container').classList.add('deletion')
        document.head.insertAdjacentHTML("beforeend", css)
        document.getElementById('phoneInputPlaceholder').innerText = I18n.translateString('deletionContainer.phoneInputPlaceholder')

        if (window.auth.currentUser === undefined || window.auth.currentUser === null){
            document.getElementById('numberToDelete').remove()
            document.getElementById('loginH1').textContent = I18n.translateString('deletionContainer.notLoggedIn')
            document.getElementById('loginH2').textContent = I18n.translateString('deletionContainer.mustBeLoggedIn')
            document.getElementById('loginH3').innerHTML = I18n.translateString('deletionContainer.loginHere')
            document.getElementById('loginH3').style.display = ''
            document.getElementById('redirectLogin').addEventListener('click', function () {
                RouterAssistant.redirect('login-page')
            })
        } else {
            document.getElementById('exitDeletion').style.display = ''
            document.getElementById('loginH1').textContent = I18n.translateString('deletionContainer.deleteData')
            document.getElementById('loginH2').textContent = I18n.translateString('deletionContainer.allDataWillBeDeleted')
            document.getElementById('phone-number-input').addEventListener('input', function (e) {
                if(e.target.value.length === 10){
                    document.getElementById('numberToDelete').remove()
                    if (FirebaseAssistant.getUserPhoneNumber().includes(e.target.value)){
                        document.getElementById('loginH3').innerHTML = I18n.translateString('deletionContainer.sendingDeletionRequest')
                        document.getElementById('loginH3').style.display = ''
                        FirebaseAssistant.requestDataDeletion(function () {
                            document.getElementById('loginH3').innerHTML = `${I18n.translateString('deletionContainer.deletionToHappenAfter7days')} <a href="mailto:pastamatakis@gmail.com?subject=${I18n.translateString('deletionContainer.user')} ${e.target.value}: ${I18n.translateString('deletionContainer.emailSubject')}">${I18n.translateString('deletionContainer.contactUs')}</a> ${I18n.translateString('deletionContainer.detailsToCancelDeletion')}`
                        })
                    } else {
                        document.getElementById('loginH3').innerText = I18n.translateString('deletionContainer.notYourNumber')
                        document.getElementById('loginH3').style.display = ''
                    }
                }
            })
        }
        document.getElementById('exitDeletion').addEventListener('click', function () {
            RouterAssistant.redirect('profiles-page')
        })
    }
}

DeletionContainer.template = /*html*/ `    
         <button id="exitDeletion" type="button" class="btn" style="display: none; font-size: xxx-large; position: fixed; z-index: 5000; left: 1rem; top: 1rem; color: lightblue; "><i class="bx bx-arrow-back"></i></button>    
    <div class="flex-container" style="
    text-align: center;
">
    <div id="welcomeRow" class="row justify-content-center" style="margin-top: 30dvh;">
        <div class="col-11">
            <h1 id="loginH1" style="font-weight: 900" class="mb-4">Διαγραφή δεδομένων</h1>
            <h3 id="loginH2" class="mb-4">Όλα τα δεδομένα σας θα διαγραφούν απο το BLE</h3>
            <div id="numberToDelete" class="mt-5" style="
                width: 25rem;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
">
    <div id="phoneNumberContainer" class="input-group mb-3" style="    width: 25rem;">
        <select id="phone-number-prefix" class=" input-group-text " disabled="">
            <option selected="" value="+30">+30</option>
        </select>
        <div class="form-floating">
            <input id="phone-number-input" type="tel" class="form-control" placeholder="name@example.com" maxlength="10">
            <label id="phoneInputPlaceholder" for="phone-number-input">Αριθμός κινητού τηλεφώνου</label>
        </div>
    </div> 
</div>
        <p id="loginH3" style="
    display: none;
">Θα επικοινωνησουμε μαζι σου για να επιβεβαιωσουμε την διαγραφη</p></div>
        
    </div>

</div>
`;

DeletionContainer.reg('deletion-container');
