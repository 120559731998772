import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";
import {LoginContainer} from "../login-container/login-container";

const css = `
    <style>
        phone-input{
            max-width: 400px;
            display:block;
            text-align: center;
        }
        
        #phoneNumberContainer {
            transition: opacity 1s ease-in-out;
        }
        
        #validation {
            transition: opacity 1s ease-in-out;
        }
        
         @media (orientation: portrait) {
              phone-input{
                  margin-top: 4.6dvh;
                     position: relative;
                     left: 50%;
                     transform:translate(-50%, 0)
                    }
         }
         @media (orientation: landscape) {
                 phone-input{
                    margin-top: 6rem
                    }
         }
         
         @media (max-width: 600px) {
              phone-input {
                    transform:  translate(-50%, 0) scale(0.9);
                    left: 50%;
              }
        }
        
        /* @media (min-width: 600px) {*/
        /*      phone-input {*/
        /*           !*margin-top: 3rem;*!*/
        
        /*          */
        /*      }*/
        /*}*/
    </style>
 `

export class PhoneInput extends BaseComponent {

    static idOfPhoneInputContainer= "phoneNumberContainer";
    static idOfSmsInputContainer= "validation";
    static idOfPhonePrefixInputField= "phone-number-prefix";
    static idOfPhoneInputField= "phone-number-input";
    static idOfPhoneSubmitButton= "sign-in-button";
    static ifOfConfirmationSmsCodeInputField= "sms-confirmation-input";
    static ifOfConfirmationSmsCodeSubmitButton= "sms-confirm-button";

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        RouterAssistant.checkForSingleContainer('login-container', PhoneInput.initiator)
    }

    static initiator(){
        
        FirebaseAssistant.setUpLoginFunctionality(PhoneInput.idOfPhoneInputContainer, PhoneInput.idOfSmsInputContainer, PhoneInput.idOfPhonePrefixInputField, PhoneInput.idOfPhoneInputField, PhoneInput.idOfPhoneSubmitButton, PhoneInput.ifOfConfirmationSmsCodeInputField, PhoneInput.ifOfConfirmationSmsCodeSubmitButton)
        // document.getElementById('resetter1').addEventListener("click", function () {
        // document.getElementById('exitLoginValidation').addEventListener("click", function () {
        //     RouterAssistant.redirect('login-page');
        // })
        document.getElementById('translatablePlaceholder1').innerText = I18n.translateString('loginPage.inputPlaceholder')
        document.getElementById('translatablePlaceholder2').innerText = I18n.translateString('loginValidationPage.smsPrompt')
    }

    static removePhoneThenShowSms(){
        const phoneContainer = document.getElementById(PhoneInput.idOfPhoneInputContainer)
        const smsContainer = document.getElementById(PhoneInput.idOfSmsInputContainer)
        phoneContainer.style.opacity = '0';
        smsContainer.style.display = 'flex';
        setTimeout(function () {
            phoneContainer.style.display = 'none';
            smsContainer.style.opacity = '1';
            document.getElementById('sms-confirmation-input').focus()
        }, 1500)
    }
}

PhoneInput.template = /*html*/ `
    <div id="${PhoneInput.idOfPhoneInputContainer}" class="input-group mb-3">
        <select id="${PhoneInput.idOfPhonePrefixInputField}" class=" input-group-text " disabled>
            <option selected value="+30">+30</option>
        </select>
        <div class="form-floating">
            <input id="${PhoneInput.idOfPhoneInputField}" type="tel" class="form-control" placeholder="name@example.com" maxlength="10">
            <label id="translatablePlaceholder1" for="${PhoneInput.idOfPhoneInputField}">Αριθμός κινητού τηλεφώνου</label>
        </div>
    </div>
    <div class="input-group mb-3" id="${PhoneInput.idOfSmsInputContainer}" style="display: none; opacity: 0">
        <div class="form-floating">
            <input id="${PhoneInput.ifOfConfirmationSmsCodeInputField}" type="tel" class="form-control" placeholder="name@example.com" maxlength="10">
            <label id="translatablePlaceholder2" for="${PhoneInput.ifOfConfirmationSmsCodeInputField}">Γράψε τον 6ψήφιο αριθμό που θα βρεις στο SMS</label>
        </div>
    </div>
<!--    <a id="resetter1" href="#" style="transition: opacity 1s; opacity: 0;"></a>-->
<!--    <button id="exitLoginValidation" type="button" class="btn" style="transition: opacity 1s; opacity: 0;font-size: xxx-large; position: fixed; z-index: 5000; left: 1rem; top: 1rem; color: lavenderblush; "><i class="bx bx-arrow-back"></i></button>-->

`;

PhoneInput.reg('phone-input');
