//https://github.com/geocortex/showcaser

import Showcaser from "showcaser";
import {RouterAssistant} from "../router-assistant/router-assistant.js";
import {I18n} from "../i18n/i18n.js";
import {AnnouncementAssistant} from "../../components/announcement-assistant/announcement-assistant.js";
import {EventEditor} from "../../components/event-editor/event-editor";
import {UserNotifier} from "../../components/user-notifier/user-notifier";
import '../../components/event-editor/assets/long-press-event.min'
import {FirebaseAssistant} from "../firebase-assistant/firebase-assistant";


Showcaser._isSafari = function () {
    return false
}
window.showcaser = Showcaser

// document.addEventListener('timelinePopulationDone', function () {
//     if (localStorage.getItem("guidedTours_v1.1") === null
//         && document.querySelectorAll('.clr-timeline li').length !== 0) {
//         setTimeout(function () {
//             window.showcaser.showcase(I18n.translateString('showcaseAssistant.guidedTours_v1_1'), document.getElementById('newEventButton'), {
//                 backgroundColor: {
//                     r: 59,
//                     g: 137,
//                     b: 246,
//                     a: 0.75
//                 },
//                 paddingPx: 15,
//                 allowSkip: false,
//                 buttonText: ' ',
//                 position: {
//                     horizontal: "center",
//                     vertical: "top"
//                 }
//             });
//             const element = document.querySelector('.showcaser')
//             element.style.cursor = "pointer";
//             element.setAttribute('data-bs-toggle', 'modal')
//             element.setAttribute('data-bs-target', '#exampleModal')
//             element.addEventListener('click', function () {
//                 if (document.querySelector('.showcaser-container')) {
//                     window.showcaser.close()
//                     window.localStorage.setItem('guidedTours_v1.1', 'hasBeenShown')
//                 }
//             })
//         }, 150)
//
//     } else if (window.kUser.isProvider) {
//         window.localStorage.setItem('guidedTours_v1.1', 'hasBeenShown')
//     }
// })

function tour2() {
    const somethingIsActive = EventEditor.eventEditorIsActive() || UserNotifier.isActive()
    if (document.querySelectorAll('.clr-timeline li').length !== 0 && localStorage.getItem("guidedTours_v1.2") === null && !somethingIsActive) {
        let element;
        let shape;
        let paddingPixels;
        let position;
        if (window.innerHeight > window.innerWidth) {
            const total = document.querySelectorAll('#vertical .hoverer').length
            if (total > 3) {
                element = document.querySelectorAll('#vertical .hoverer')[total-2]
            } else {
                element = document.querySelectorAll('#vertical .hoverer')[total-1]
            }
            position = {
                horizontal: "center",
                vertical: "bottom"
            }
            shape = 'rectangle'
            paddingPixels = 15
        } else {
            const total = document.querySelectorAll('#horizontal .hoverer').length
            if (total > 3) {
                element = document.querySelectorAll('#horizontal .hoverer')[total-2]
                position = {
                    horizontal: "center",
                    vertical: "bottom"
                }
            } else {
                element = document.querySelectorAll('#horizontal .hoverer')[total-1]
                position = {
                    horizontal: "right",
                    vertical: "middle"
                }
            }
            shape = 'circle'
            paddingPixels = 100
        }
        window.showcaseAssistantOriginalZindex = element.style.zIndex;
        element.style.zIndex = '99999999'
        element.addEventListener('click', function () {
            if (document.querySelector('.showcaser-container')) {
                window.showcaser.close()
                window.localStorage.setItem('guidedTours_v1.2', 'hasBeenShown')
                element.style.zIndex = window.showcaseAssistantOriginalZindex
                setTimeout(tour3, 1000)
            }
        })
        window.showcaser.showcase(I18n.translateString('showcaseAssistant.guidedTours_v1_2'), element, {
            backgroundColor: {
                r: 59,
                g: 137,
                b: 246,
                a: 0.90
            },
            paddingPx: paddingPixels,
            shape: shape,
            allowSkip: false,
            buttonText: ' ',
            position: position
        });
    }
}

function tour3() {
    const somethingIsActive = EventEditor.eventEditorIsActive() || UserNotifier.isActive()
    if (localStorage.getItem("guidedTours_v1.2") === 'hasBeenShown' && localStorage.getItem("guidedTours_v1.3") === null && !somethingIsActive) {
        window.showcaser.showcase(I18n.translateString('showcaseAssistant.guidedTours_v1_3'), document.getElementById('profilesPageNav'), {
            backgroundColor: {
                r: 59,
                g: 137,
                b: 246,
                a: 0.90
            },
            paddingPx: 15,
            allowSkip: false,
            buttonText: ' ',
            // position: {
            //     horizontal: "left",
            //     vertical: "top"
            // }
        });
        const element = document.querySelector('.showcaser')
        element.style.cursor = "pointer";
        element.addEventListener('click', function () {
            if (document.querySelector('.showcaser-container')) {
                RouterAssistant.redirect('profiles-page')
                window.showcaser.close()
                window.localStorage.setItem('guidedTours_v1.3', 'hasBeenShown')
                setTimeout(function () {
                    AnnouncementAssistant.makeTrialAnnouncement()
                    window.trialAnnouncementHasBeenMade = true
                    FirebaseAssistant.userIsProvider((isProvider)=> {
                       if (!isProvider) {
                           try {
                               document.getElementById('proBadge').style.display = ''
                           } catch (e){
                               console.debug(e)
                           }
                       }
                    })
                }, 3000)
            }
        })
    }
}

export function tour4() {
    const somethingIsActive = EventEditor.eventEditorIsActive() || UserNotifier.isActive()
    if (localStorage.getItem("guidedTours_v1.3") === 'hasBeenShown' && localStorage.getItem("guidedTours_v1.4") === null && !somethingIsActive) {
        const userRows = document.querySelectorAll('.clickableRow')
        if (window.innerHeight > window.innerWidth && userRows.length !== 0 && !document.querySelector('.showcaser-container')) {
            window.showcaser.showcase(I18n.translateString('showcaseAssistant.guidedTours_v1_4'), userRows[userRows.length - 1], {
                backgroundColor: {
                    r: 59,
                    g: 137,
                    b: 246,
                    a: 0.90
                },
                paddingPx: 0,
                allowSkip: false,
                buttonText: I18n.translateString('showcaseAssistant.guidedTours_v1_4_2'),
                position: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                shape: 'rectangle',
                close: () => {
                    window.localStorage.setItem('guidedTours_v1.4', 'hasBeenShown')
                }
            });
            const button = document.querySelector('.showcaser-button')
            button.style.fontWeight = 'bold'
            button.style.color = 'darkslategray'
            button.style.background = 'white'
        }
    }
}

document.addEventListener('timelinePopulationDone', function () {
    setTimeout(tour2, 1000)
})
document.addEventListener('hidden.bs.modal', function () {
    if (window.location.href.includes('timeline-page'))
        setTimeout(tour2, 1000)
})

//v1.3 -> triggers when the editor modal closes for the second time  -> prompts user to go to profile page
document.addEventListener('hidden.bs.modal', function () {
    setTimeout(tour3, 1000)
})


