/*eslint no-undef: "off"*/
import Translator from '@andreasremdt/simple-translator';
import {englishTranslation} from "./translations/en.js";
import {greekTranslation} from "./translations/el.js";
import {GeofencingGuard} from "../geofencing-guard/geofencing-guard";


export class I18n {
    static initiate(){
        // The below provided options are default.
        window.i18n = new Translator({
            defaultLanguage: "en",
            detectLanguage: false,
            selector: "[data-i18n]",
            debug: false,
            registerGlobally: "__",
            persist: false,
            persistKey: "preferred_language",
            filesLocation: "./i18n"
        });

        window.i18n.add('en', englishTranslation)
        window.i18n.add('el', greekTranslation)
        window.translateTo = I18n.getUsersPreferredLanguage()

        GeofencingGuard.getUserCountry(country => {window.currentLocation = country})
    }

    static getUsersPreferredLanguage() {
        const userLang = window.navigator.language.split("-")[0]
        return I18n.getCurrentlySupportedLanguages().includes(userLang) ? userLang : window.i18n.defaultLanguage;
    }

    static getCurrentlySupportedLanguages(){
        return Array.from(window.i18n.languages.keys())
    }

    static getUsersCurrentLocation() {
        return window.currentLocation
    }

    static translateString(key){
        // translator.translateForKey('header.title', 'de');
        // window.i18n.translateForKey('timelinePage.timestamps.months', 'el');
        // console.debug(window.i18n)
        return window.i18n.translateForKey(key, window.translateTo);
    }

    static mobileNumberIsValid(text){
        //todo make all number validation stuff pass from here
        const beginning = text.substring(0, 5)
        const isNum = /^\d+$/.test(text.substring(1, 99));
        return text.length === 13 && beginning === '+3069' && isNum;
    }
}


