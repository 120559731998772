import { SvelteComponent, init, safe_not_equal, create_slot, element, attr, insert, append, update_slot_base, get_all_dirty_from_scope, get_slot_changes, transition_in, transition_out, detach, component_subscribe, space, action_destroyer, listen, is_function, noop, run_all, binding_callbacks, empty, group_outros, check_outros, text, set_data, update_keyed_each, outro_and_destroy_block, create_component, mount_component, destroy_component, set_store_value, destroy_each } from 'svelte/internal';
import { getContext, onMount, setContext } from 'svelte';
import { setContent, toEventWithLocalDates, toViewWithLocalDates, createEventContent, setMidnight, createDate, addDay, cloneDate, eventIntersects, createEventChunk, sortEventChunks, datesEqual, toLocalDate, toISOString, setFn, intl } from '@event-calendar/common';

/* packages/list/src/Body.svelte generated by Svelte v3.55.1 */

function create_fragment$3(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (default_slot) default_slot.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].content);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].body);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].body)) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $theme;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { theme } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [$theme, theme, $$scope, slots];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$3, create_fragment$3, safe_not_equal, {});
	}
}

/* packages/list/src/Event.svelte generated by Svelte v3.55.1 */

function create_fragment$2(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let setContent_action;
	let div2_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].eventTag);
			attr(div0, "style", /*style*/ ctx[2]);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].eventBody);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[0].event);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t);
			append(div2, div1);
			/*div2_binding*/ ctx[29](div2);

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div1, /*content*/ ctx[3])),
					listen(div2, "click", function () {
						if (is_function(/*createHandler*/ ctx[19](/*$eventClick*/ ctx[4]))) /*createHandler*/ ctx[19](/*$eventClick*/ ctx[4]).apply(this, arguments);
					}),
					listen(div2, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[19](/*$eventMouseEnter*/ ctx[5]))) /*createHandler*/ ctx[19](/*$eventMouseEnter*/ ctx[5]).apply(this, arguments);
					}),
					listen(div2, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[19](/*$eventMouseLeave*/ ctx[6]))) /*createHandler*/ ctx[19](/*$eventMouseLeave*/ ctx[6]).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].eventTag)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*style*/ 4) {
				attr(div0, "style", /*style*/ ctx[2]);
			}

			if (dirty[0] & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].eventBody)) {
				attr(div1, "class", div1_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 8) setContent_action.update.call(null, /*content*/ ctx[3]);

			if (dirty[0] & /*$theme*/ 1 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[0].event)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			/*div2_binding*/ ctx[29](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $_view;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventColor;
	let $eventBackgroundColor;
	let $_resBgColor;
	let $eventClick;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let { chunk } = $$props;
	let { displayEventEnd, eventBackgroundColor, eventColor, eventContent, eventClick, eventDidMount, eventMouseEnter, eventMouseLeave, theme, _view, _intlEventTime, _resBgColor } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(25, $displayEventEnd = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(27, $eventBackgroundColor = value));
	component_subscribe($$self, eventColor, value => $$invalidate(26, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(24, $eventContent = value));
	component_subscribe($$self, eventClick, value => $$invalidate(4, $eventClick = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(31, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(5, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(6, $eventMouseLeave = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(22, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(23, $_intlEventTime = value));
	component_subscribe($$self, _resBgColor, value => $$invalidate(28, $_resBgColor = value));
	let el;
	let event;
	let style;
	let content;
	let timeText;

	onMount(() => {
		if (is_function($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	function createHandler(fn) {
		return jsEvent => {
			if (is_function(fn)) {
				fn({
					event: toEventWithLocalDates(event),
					el,
					jsEvent,
					view: toViewWithLocalDates($_view)
				});
			}
		};
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(20, chunk = $$props.chunk);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*chunk*/ 1048576) {
			$$invalidate(21, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, $_resBgColor, $eventBackgroundColor, $eventColor*/ 471859200) {
			{
				// Class & Style
				let bgColor = event.backgroundColor || $_resBgColor(event) || $eventBackgroundColor || $eventColor;

				if (bgColor) {
					$$invalidate(2, style = `background-color:${bgColor};`);
				}
			}
		}

		if ($$self.$$.dirty[0] & /*chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view*/ 63963137) {
			{
				// Content
				$$invalidate(3, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
			}
		}
	};

	return [
		$theme,
		el,
		style,
		content,
		$eventClick,
		$eventMouseEnter,
		$eventMouseLeave,
		displayEventEnd,
		eventBackgroundColor,
		eventColor,
		eventContent,
		eventClick,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		theme,
		_view,
		_intlEventTime,
		_resBgColor,
		createHandler,
		chunk,
		event,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventColor,
		$eventBackgroundColor,
		$_resBgColor,
		div2_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$2, safe_not_equal, { chunk: 20 }, null, [-1, -1]);
	}
}

/* packages/list/src/Day.svelte generated by Svelte v3.55.1 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	return child_ctx;
}

// (68:0) {#if chunks.length}
function create_if_block$1(ctx) {
	let div;
	let t0_value = /*$_intlListDayFormat*/ ctx[7].format(/*date*/ ctx[0]) + "";
	let t0;
	let t1;
	let span;
	let t2_value = /*$_intlListDaySideFormat*/ ctx[8].format(/*date*/ ctx[0]) + "";
	let t2;
	let span_class_value;
	let div_class_value;
	let t3;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let mounted;
	let dispose;
	let each_value = /*chunks*/ ctx[2];
	const get_key = ctx => /*chunk*/ ctx[24].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$1(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$1(key, child_ctx));
	}

	return {
		c() {
			div = element("div");
			t0 = text(t0_value);
			t1 = space();
			span = element("span");
			t2 = text(t2_value);
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			attr(span, "class", span_class_value = /*$theme*/ ctx[5].daySide);

			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[5].day + (/*isToday*/ ctx[3] ? ' ' + /*$theme*/ ctx[5].today : '') + (/*highlight*/ ctx[4]
			? ' ' + /*$theme*/ ctx[5].highlight
			: '')));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, t1);
			append(div, span);
			append(span, t2);
			/*div_binding*/ ctx[19](div);
			insert(target, t3, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(div, "click", function () {
					if (is_function(/*createClickHandler*/ ctx[16](/*$dateClick*/ ctx[6]))) /*createClickHandler*/ ctx[16](/*$dateClick*/ ctx[6]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if ((!current || dirty & /*$_intlListDayFormat, date*/ 129) && t0_value !== (t0_value = /*$_intlListDayFormat*/ ctx[7].format(/*date*/ ctx[0]) + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*$_intlListDaySideFormat, date*/ 257) && t2_value !== (t2_value = /*$_intlListDaySideFormat*/ ctx[8].format(/*date*/ ctx[0]) + "")) set_data(t2, t2_value);

			if (!current || dirty & /*$theme*/ 32 && span_class_value !== (span_class_value = /*$theme*/ ctx[5].daySide)) {
				attr(span, "class", span_class_value);
			}

			if (!current || dirty & /*$theme, isToday, highlight*/ 56 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[5].day + (/*isToday*/ ctx[3] ? ' ' + /*$theme*/ ctx[5].today : '') + (/*highlight*/ ctx[4]
			? ' ' + /*$theme*/ ctx[5].highlight
			: '')))) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*chunks*/ 4) {
				each_value = /*chunks*/ ctx[2];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block$1, each_1_anchor, get_each_context$1);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			/*div_binding*/ ctx[19](null);
			if (detaching) detach(t3);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (detaching) detach(each_1_anchor);
			mounted = false;
			dispose();
		}
	};
}

// (77:4) {#each chunks as chunk (chunk.event)}
function create_each_block$1(key_1, ctx) {
	let first;
	let event;
	let current;
	event = new Event({ props: { chunk: /*chunk*/ ctx[24] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty & /*chunks*/ 4) event_changes.chunk = /*chunk*/ ctx[24];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(event, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*chunks*/ ctx[2].length && create_if_block$1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*chunks*/ ctx[2].length) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*chunks*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $_view;
	let $highlightedDates;
	let $_events;
	let $theme;
	let $dateClick;
	let $_intlListDayFormat;
	let $_intlListDaySideFormat;
	let { date } = $$props;
	let { _events, _intlDayHeader, _view, date: currentDate, dateClick, highlightedDates, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(18, $_events = value));
	component_subscribe($$self, _view, value => $$invalidate(20, $_view = value));
	component_subscribe($$self, dateClick, value => $$invalidate(6, $dateClick = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(17, $highlightedDates = value));
	component_subscribe($$self, theme, value => $$invalidate(5, $theme = value));
	let { _intlListDayFormat, _intlListDaySideFormat } = getContext('view-state');
	component_subscribe($$self, _intlListDayFormat, value => $$invalidate(7, $_intlListDayFormat = value));
	component_subscribe($$self, _intlListDaySideFormat, value => $$invalidate(8, $_intlListDaySideFormat = value));
	let el;
	let chunks;
	let today = setMidnight(createDate()), isToday, highlight;

	function createClickHandler(fn) {
		return is_function(fn)
		? jsEvent => {
				fn({
					allDay: true,
					date: toLocalDate(date),
					dateStr: toISOString(date),
					dayEl: el,
					jsEvent,
					view: toViewWithLocalDates($_view)
				});
			}
		: undefined;
	}

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*date, $_events, chunks*/ 262149) {
			{
				$$invalidate(2, chunks = []);
				let start = date;
				let end = addDay(cloneDate(date));

				for (let event of $_events) {
					if (event.display === 'auto' && eventIntersects(event, start, end)) {
						let chunk = createEventChunk(event, start, end);
						chunks.push(chunk);
					}
				}

				sortEventChunks(chunks);
			}
		}

		if ($$self.$$.dirty & /*date, $highlightedDates*/ 131073) {
			{
				$$invalidate(3, isToday = datesEqual(date, today));
				$$invalidate(4, highlight = $highlightedDates.some(d => datesEqual(d, date)));
			}
		}

		if ($$self.$$.dirty & /*el, date*/ 3) {
			// dateFromPoint
			if (el) {
				setFn(el, () => date);
			}
		}
	};

	return [
		date,
		el,
		chunks,
		isToday,
		highlight,
		$theme,
		$dateClick,
		$_intlListDayFormat,
		$_intlListDaySideFormat,
		_events,
		_view,
		dateClick,
		highlightedDates,
		theme,
		_intlListDayFormat,
		_intlListDaySideFormat,
		createClickHandler,
		$highlightedDates,
		$_events,
		div_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, { date: 0 });
	}
}

class State {
    constructor(state) {
        this._intlListDayFormat = intl(state.locale, state.listDayFormat);
        this._intlListDaySideFormat = intl(state.locale, state.listDaySideFormat);
    }
}

/* packages/list/src/View.svelte generated by Svelte v3.55.1 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	return child_ctx;
}

// (49:0) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*$_viewDates*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$_viewDates*/ 2) {
				each_value = /*$_viewDates*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (47:0) {#if noEvents}
function create_if_block(ctx) {
	let div;
	let div_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[3].noEvents);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[0])),
					listen(div, "click", /*handleClick*/ ctx[11])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 8 && div_class_value !== (div_class_value = /*$theme*/ ctx[3].noEvents)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*content*/ 1) setContent_action.update.call(null, /*content*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (50:4) {#each $_viewDates as date}
function create_each_block(ctx) {
	let day;
	let current;
	day = new Day({ props: { date: /*date*/ ctx[18] } });

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};
			if (dirty & /*$_viewDates*/ 2) day_changes.date = /*date*/ ctx[18];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

// (46:0) <Body>
function create_default_slot(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*noEvents*/ ctx[2]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let body;
	let current;

	body = new Body({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(body, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const body_changes = {};

			if (dirty & /*$$scope, $theme, content, noEvents, $_viewDates*/ 2097167) {
				body_changes.$$scope = { dirty, ctx };
			}

			body.$set(body_changes);
		},
		i(local) {
			if (current) return;
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(body, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_view;
	let $noEventsClick;
	let $noEventsContent;
	let $_events;
	let $_viewDates;
	let $_viewClass;
	let $theme;
	let { _events, _view, _viewDates, _viewClass, noEventsClick, noEventsContent, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(13, $_events = value));
	component_subscribe($$self, _view, value => $$invalidate(14, $_view = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(1, $_viewDates = value));
	component_subscribe($$self, _viewClass, value => $$invalidate(16, $_viewClass = value));
	component_subscribe($$self, noEventsClick, value => $$invalidate(15, $noEventsClick = value));
	component_subscribe($$self, noEventsContent, value => $$invalidate(12, $noEventsContent = value));
	component_subscribe($$self, theme, value => $$invalidate(3, $theme = value));
	let state = new State(getContext('state'));
	setContext('view-state', state);
	set_store_value(_viewClass, $_viewClass = 'list', $_viewClass);
	let noEvents, content;

	function handleClick(jsEvent) {
		if (is_function($noEventsClick)) {
			$noEventsClick({
				jsEvent,
				view: toViewWithLocalDates($_view)
			});
		}
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_viewDates, $_events*/ 8194) {
			{
				$$invalidate(2, noEvents = true);

				if ($_viewDates.length) {
					let start = $_viewDates[0];
					let end = addDay(cloneDate($_viewDates[$_viewDates.length - 1]));

					for (let event of $_events) {
						if (event.display === 'auto' && event.start < end && event.end > start) {
							$$invalidate(2, noEvents = false);
							break;
						}
					}
				}
			}
		}

		if ($$self.$$.dirty & /*$noEventsContent, content*/ 4097) {
			{
				$$invalidate(0, content = is_function($noEventsContent)
				? $noEventsContent()
				: $noEventsContent);

				if (typeof content === 'string') {
					$$invalidate(0, content = { html: content });
				}
			}
		}
	};

	return [
		content,
		$_viewDates,
		noEvents,
		$theme,
		_events,
		_view,
		_viewDates,
		_viewClass,
		noEventsClick,
		noEventsContent,
		theme,
		handleClick,
		$noEventsContent,
		$_events
	];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		// Common options
		options.buttonText.listDay = 'list';
		options.buttonText.listWeek = 'list';
		options.buttonText.listMonth = 'list';
		options.buttonText.listYear = 'list';
		options.listDayFormat = {weekday: 'long'};
		options.listDaySideFormat = {year: 'numeric', month: 'long', day: 'numeric'};
		options.noEventsClick = undefined;  // ec option
		options.noEventsContent = 'No events';
		options.theme.daySide = 'ec-day-side';
		options.theme.eventTag = 'ec-event-tag';
		options.theme.list = 'ec-list';
		options.theme.noEvents = 'ec-no-events';
		options.view = 'listWeek';
		options.views.listDay = {
			component: View,
			duration: {days: 1}
		};
		options.views.listWeek = {
			component: View,
			duration: {weeks: 1}
		};
		options.views.listMonth = {
			component: View,
			duration: {months: 1}
		};
		options.views.listYear = {
			component: View,
			duration: {years: 1}
		};
	}
};

export { index as default };
