
import {BaseComponent} from "@symbiotejs/symbiote";
import '../../components/phone-input/phone-input'
import {I18n} from "../../utilities/i18n/i18n";
import {ProfileContainer} from "../profile-container/profile-container";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";
import {UserNotifier} from "../user-notifier/user-notifier";
const page = require('../../pages/calendar-page.html')
const background1 = require('./assets/background (1).webp')
// const background2 = require('./assets/background-mobile (1).webp')
const background2 = require('./assets/test-bg.webp')

const css = `
    <style>
        login-container {
            width: 100dvw;
            height: 100dvh;
            display: inline-block;
        
        }
        
        #disclaimer {
            position: fixed;
            opacity: 0.3;
            font-size: 0.7em;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
        }
        
        login-container>button {
            background: #039be5;
            border: 3px solid #039be5;
        }
        
        @media (orientation: landscape) {
            login-container {
                background: linear-gradient(to right, rgba(34, 37, 41, 1)40%, rgba(34, 37, 41, 0.8)55%, rgba(0, 0, 0, 0))65%,url('${background1}');
                background-size: cover;
                background-position: center right;
            }
            
            login-container h1{
                font-size: 70px;
            }
        
            #welcomeColumn {
                margin-left: 5dvw;
            }
        
            #disclaimer {
                /*position: fixed;*/
                margin-left: 5dvw;
                bottom: 5%;
            }
        
            phone-input {
                margin-top: 6rem
            }
            
           
        }
        
        @media (orientation: portrait) {
            login-container {
                background: linear-gradient(to top, rgba(34, 37, 41, 1)40%, rgba(34, 37, 41, 0.8)55%, rgba(0, 0, 0, 0))65%, url('${background2}');
                background-size: cover;
                background-position: center right;
            }
            
            login-container h1{
                font-size: 50px;
            }
        
            #disclaimer {
                            text-align: center;
                bottom: 6%;
            }
            
            #disclaimerRow {
                position: fixed;
                bottom:0;
            }
                
            #disclaimerContainer {
                text-align: center
            }
            
            #welcomeRow {
                
            }
        }
        
        @media (orientation: portrait) and (max-width: 600px) {
              login-container h2 {
                    margin-bottom: 1rem!important;
              }
        }
        
        @media (orientation: portrait) and (min-width: 600px) {
              login-container h2 {
                    margin-bottom: 3rem!important;
              }
        }
     </style>
 `

export class LoginContainer extends BaseComponent {
    init$ = {
        count: 0,
        increment: () => {
            this.$.count++;
        },
    };

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        RouterAssistant.checkForSingleContainer('login-container', LoginContainer.initiator)
    }

    static initiator(){

        document.getElementById('loginH1').innerText = I18n.translateString('loginPage.mainHeader')
        document.getElementById('loginH2').innerText = I18n.translateString('loginPage.secondaryHeader')
        // document.getElementById('loginH2').innerText = I18n.translateString('loginPage.secondaryHeader')
        document.querySelector('login-container').classList.add('login')
        window.addEventListener('resize', LoginContainer.performOrientationRequiredActions, true);
        if (window.visualViewport) {
            window.visualViewport.addEventListener("resize", LoginContainer.performOrientationRequiredActions);
        }
        LoginContainer.performOrientationRequiredActions();
        document.getElementById('exitLoginValidation').addEventListener("click", function () {
            RouterAssistant.redirect('login-page');
        })
        window.visualViewport.addEventListener('resize', LoginContainer.positionValidationResetter)
        LoginContainer.positionValidationResetter()
    }

    static positionValidationResetter(){
        try {
            // document.getElementById('exitLoginValidation').style.bottom = `${window.outerHeight * 0.90 - (window.outerHeight - window.innerHeight)}px`;
            document.getElementById('exitLoginValidation').style.bottom = `${window.outerHeight * 0.90 - (window.outerHeight - window.visualViewport.height)}px`;
            // UserNotifier.notifyUser('test', `window.outerHeight: ${window.outerHeight}, window.innerHeight: ${window.innerHeight}, window.visualViewport.height: ${window.visualViewport.height}`)
        } catch (e) {
            // ignore
        }
    }

    static performOrientationRequiredActions(){
        if (window.location.href.includes('login')) {
            try {
                const welcomeRow = document.getElementById('welcomeRow')
                const welcomeColumn = document.getElementById('welcomeColumn')
                const phoneInput = document.querySelector('phone-input')
                const disclaimer = document.getElementById('disclaimer')
                if (window.innerHeight > window.innerWidth) {
                    welcomeRow.style.marginTop = '60dvh'
                    welcomeRow.classList.add('text-center')
                    welcomeRow.classList.add('justify-content-center')
                    welcomeColumn.classList.remove('col-7')
                    welcomeColumn.classList.add('col-12')
                    disclaimer.innerHTML = `<small>${I18n.translateString("loginPage.reCaptchaDisclaimerMobile")}</small>`
                    // disclaimer.innerHTML = `<small>Αυτή η εφαρμογή προστατεύεται από reCAPTCHA και ισχύουν<br>η <a href="https://policies.google.com/privacy">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms">Όροι Παροχής Υπηρεσιών</a> της Google.</small>`
                } else {
                    welcomeRow.style.marginTop = '30dvh'
                    welcomeRow.classList.remove('justify-content-center')
                    welcomeColumn.classList.add('col-7')
                    welcomeColumn.classList.remove('col-12')
                    welcomeRow.classList.remove('text-center')
                    disclaimer.innerHTML = `<small>${I18n.translateString("loginPage.reCaptchaDisclaimerDesktop")}</small>`
                    // disclaimer.innerHTML = `<small>Αυτός ο ιστότοπος προστατεύεται από reCAPTCHA και ισχύουν<br>η <a href="https://policies.google.com/privacy">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms">Όροι Παροχής Υπηρεσιών</a> της Google.</small>`
                }
            } catch (e) {
                // console.debug(e)
            }
        }
    }
}

LoginContainer.template = /*html*/ `
    <div class="flex-container">
    <div id="welcomeRow" class="" >
        <div id="welcomeColumn"  class="">
            <h1 id="loginH1" style="font-weight: 900" class="mb-4"></h1>
            <h2 id="loginH2" ></h2>
            <phone-input></phone-input>
        </div>
        <div class="col-6">
        </div>
    </div>
<!--            <small id="disclaimer" class="w-100" style="bottom: 5%;">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</small>-->
            <div id="disclaimer"></div>
            <div id="recaptcha"></div>
</div>
     <button id="exitLoginValidation" type="button" class="btn" style="transition: opacity 1s; opacity: 0;font-size: xxx-large; position: absolute; z-index: 5000; left: 1rem; color: lavenderblush; "><i class="bx bx-arrow-back"></i></button>

`;

LoginContainer.reg('login-container');
