import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n.js";
import '../event-editor/assets/long-press-event.min'
import "./assets/timeline-container.css"
import {EventEditor} from "../event-editor/event-editor.js";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant.js";

const loader = require('./assets/loader_cropped.gif')

const emptyTimelineImages = {}
emptyTimelineImages['emptyTimelineEl'] = require(`./assets/empty_timeline_el.png`)
emptyTimelineImages['emptyTimelineEn'] = require(`./assets/empty_timeline_en.png`)

import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";
import {LoadingSplasher} from "../loading-splasher/loading-splasher.js";
import {ActionVerifier} from "../action-verifier/action-verifier.js";

const scrollNudgeImage = require('./assets/scroll_nudge.png')
const userSvg = `<svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" aria-hidden="true" role="img"><path d="M18,11a7,7,0,1,1-7,7,7,7,0,0,1,7-7" class="clr-i-outline clr-i-outline-path-1"></path><path d="M18,34A16,16,0,1,1,34,18,16,16,0,0,1,18,34ZM18,4A14,14,0,1,0,32,18,14,14,0,0,0,18,4Z" class="clr-i-outline clr-i-outline-path-2"></path></svg>`
const providerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="200pt" height="200pt" viewBox="0 0 200 200" version="1.1"><g id="#ffffffff"></g><g id="#000000ff"><path  opacity="1.00" d=" M 80.43 4.80 C 90.18 2.91 100.85 4.22 109.23 9.78 C 116.68 14.64 121.66 22.78 123.23 31.47 C 125.40 43.25 122.74 55.62 116.94 66.01 C 112.27 74.19 104.98 81.45 95.65 83.90 C 87.32 86.21 78.15 83.91 71.44 78.60 C 62.42 71.48 56.86 60.67 54.60 49.54 C 52.51 39.11 53.47 27.57 59.45 18.53 C 64.14 11.28 72.02 6.43 80.43 4.80 Z"/><path  opacity="1.00" d=" M 130.47 85.67 C 143.44 83.33 157.25 85.72 168.58 92.51 C 181.85 100.32 191.66 113.82 194.78 128.93 C 197.82 142.99 195.09 158.21 187.29 170.31 C 180.29 181.34 169.28 189.77 156.77 193.54 C 141.01 198.46 123.07 195.89 109.40 186.59 C 98.01 179.01 89.62 167.07 86.41 153.77 C 82.74 138.94 85.41 122.61 93.87 109.85 C 102.07 97.23 115.63 88.27 130.47 85.67 M 159.27 123.14 C 150.54 131.78 141.92 140.54 133.17 149.17 C 129.06 145.14 125.10 140.93 120.89 137.01 C 117.68 134.59 113.09 139.11 115.35 142.36 C 120.20 147.60 125.42 152.52 130.43 157.61 C 131.92 159.39 134.72 159.03 136.17 157.39 C 145.58 148.04 154.93 138.63 164.33 129.27 C 165.41 128.24 166.39 126.71 165.90 125.16 C 165.42 122.25 161.28 120.83 159.27 123.14 Z"/><path  opacity="1.00" d=" M 42.18 103.13 C 58.95 97.02 77.08 96.08 94.76 96.32 C 83.90 107.37 77.29 122.49 76.85 138.01 C 76.36 147.92 78.83 157.75 82.73 166.80 C 61.79 166.79 40.85 166.83 19.91 166.78 C 13.47 166.92 7.46 162.55 5.38 156.49 C 2.38 149.09 5.30 141.09 8.10 134.12 C 14.23 119.40 27.39 108.49 42.18 103.13 Z"/></g></svg>`

export class TimelineContainer extends BaseComponent {
    initCallback() {
        RouterAssistant.checkForSingleContainer('timeline-container', TimelineContainer.initiatorPart1)
    }

    static initiatorPart1() {

        // below line works even in first time because upon registration last accessed is set to initial timeline
        if (window.currentTimeline === undefined) window.currentTimeline = window.kUser.lastAccessedTimeline;

        const currentTimelineInProviderData = FirebaseAssistant.timelineIsInUserProviderData(window.currentTimeline)
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(window.currentTimeline);
        const currentTimelineIsArchived = FirebaseAssistant.timelineIsArchived(window.currentTimeline);

        window.showOnlyProviderEventsInTimeline = false
        FirebaseAssistant.userIsProvider(function (isProvider) {

            if (
                (window.currentTimeline === undefined)
                || (window.currentTimeline !== window.kUser.initialTimeline && !FirebaseAssistant.userIsPremium())
                || (!isProvider && currentTimelineIsArchived)
                // || (isProvider && currentTimelineIsArchived && !FirebaseAssistant.timelineIsShared(window.currentTimeline)) // removed bc provider needs to see his entries
                || (!isProvider && FirebaseAssistant.timelineIsShared(window.currentTimeline) && FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(window.currentTimeline))
            ) {
                window.currentTimeline = window.kUser.initialTimeline;
                window.kUser.lastAccessedTimeline = window.kUser.initialTimeline;
                FirebaseAssistant.updateUserFirebaseData(() => {
                })
            } else if (
                (isProvider && !FirebaseAssistant.timelineIsShared(window.currentTimeline) && !(window.currentTimeline in window.kUser.timelines))
                || (isProvider && currentTimelineIsArchived && !(window.currentTimeline in Object.keys(window.kUser.timelines)))
                || (isProvider && FirebaseAssistant.timelineIsShared(window.currentTimeline) && FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(window.currentTimeline))) {
                // can access  only my own (provider) stuff
                window.showOnlyProviderEventsInTimeline = true
                FirebaseAssistant.updateLastAccessedTimeline(window.currentTimeline)
            } else {
                FirebaseAssistant.updateLastAccessedTimeline(window.currentTimeline)
            }
            TimelineContainer.initiatorPart2()
        })
    }

    static initiatorPart2() {
        window.addEventListener('resize', function (event) {
            TimelineContainer.resizeActions()
            TimelineContainer.scrollTimelineToEnd()
        })

        TimelineContainer.redrawTimelines();

        document.getElementsByTagName('timeline-container')[0].addEventListener('wheel', (event) => {
            if (Math.abs(event.wheelDeltaX) < Math.abs(event.wheelDeltaY)) {
                // event.preventDefault()
                document.getElementById("scrollContainer").scrollBy({left: event.deltaY});
            }
            TimelineContainer.deHighlightScrollNudge()
        }, {passive:true});
        document.getElementById('emptyTimelineSplasherImg').src = emptyTimelineImages[I18n.translateString('timelinePage.emptyTimelineImage')]
        document.getElementById('scrollNudgeText').innerText = I18n.translateString('timelinePage.scrollNudgeText')
    }

    static resizeActions() {
        if (document.querySelectorAll('timeline-container').length !== 0) {
            if (window.innerHeight > window.innerWidth) {
                TimelineContainer.deActivateScrollNudge()
                document.getElementById("horizontal").style.display = "none";
                document.getElementById("vertical").style.display = "";
                document.getElementById("headerRow").classList.remove("header-margins-vertical");
                document.getElementById("headerRow").classList.add("header-margins-horizontal");
                // TimelineContainer.scrollTimelineToEnd()
                document.getElementById('timelineHeaderSharedBudge').style.marginLeft = 'unset'
                document.getElementById('timelineHeaderSharedBudge').style.transform = 'translate(-22%, -50%)'
                document.getElementById('timelineHeaderSharedBudge').style.left = '78%'
                if (FirebaseAssistant.timelineIsShared(window.currentTimeline))
                    document.querySelector('#headerRow h1').style.maxWidth = '17ch'
            } else {
                TimelineContainer.checkIfScrollNudgeShouldBeActivated()
                document.getElementById("horizontal").style.display = "";
                document.getElementById("vertical").style.display = "none";
                document.getElementById("headerRow").classList.add("header-margins-vertical");
                document.getElementById("headerRow").classList.remove("header-margins-horizontal");
                // TimelineContainer.scrollTimelineToEnd()
                document.getElementById('timelineHeaderSharedBudge').style.marginLeft = '1rem'
                document.getElementById('timelineHeaderSharedBudge').style.transform = 'translate(0, -50%)'
                document.getElementById('timelineHeaderSharedBudge').style.left = 'unset'
                // document.querySelector('#headerRow h1').style.maxWidth = '25ch'

            }
        }
        TimelineContainer.checkIfScrollNudgeShouldBeActivated()
    }

    static redrawTimelines(eventId = null) { // when eventId!=null then an event change is the reason
        document.querySelectorAll('.clr-timeline-step').forEach(el => el.remove());

        TimelineContainer.populateTimeline(window.currentTimeline)


        TimelineContainer.resizeActions()

        document.querySelectorAll(".clr-timeline-step").forEach((el) => TimelineContainer.addRequiredEventListenersToEvent(el));
        TimelineContainer.fireTimelinePopulationDoneEvent()

        if (eventId !== null) {
            TimelineContainer.pulsateEvent(eventId)
        } else {
            TimelineContainer.scrollTimelineToEnd()
        }
        TimelineContainer.hideSavingChangesBanner()
    }

    static addRequiredEventListenersToEvent(el) {
        el.addEventListener("mouseover", (event) => {
            if (window.innerWidth > window.innerHeight) {
                event.target.parentElement.querySelectorAll(".clr-timeline-step-header, clr-icon, .clr-timeline-step-title, .clr-timeline-step-description, .btn.files").forEach((element) => {
                    if (element.getAttribute('data-isprovider') === 'true')
                        element.style.transform = 'scale(1.15) translateY(-0.25rem)';
                    else
                        element.style.transform = 'scale(1.05)';
                })
            } else {
                // makes sense to have animation for mobiles?
            }
        });
        el.addEventListener("mouseout", (event) => {
            if (window.innerWidth > window.innerHeight) {
                event.target.parentElement.querySelectorAll(".clr-timeline-step-header, clr-icon, .clr-timeline-step-title, .clr-timeline-step-description, .btn.files").forEach((element) => {
                    if (element.getAttribute('data-isprovider') === 'true')
                        element.style.transform = 'scale(1.05) translateY(-0.25rem)';
                    else
                        element.style.transform = 'scale(1)';
                })
            } else {
                // makes sense to have animation for mobiles?
            }
        });
        el.addEventListener('click', (event) => {
            EventEditor.activateEditorFromExistingEvent(event);
        })

        el.addEventListener('long-press', clickEvent => {
            const eventListElement = clickEvent.target.closest('li');
            if (eventListElement !== null && eventListElement !== undefined) {
                TimelineContainer.archiveEvent(eventListElement)
            }
        })
    }

    static archiveEvent(eventListElement) {
        const eventId = eventListElement.getAttribute('data-eventid')
        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(window.currentTimeline);
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(window.currentTimeline);
        if (window.currentTimeline in window.kUser.timelines) {
            if (window.kUser.timelines[window.currentTimeline].events[eventId].isCreatedFromProvider === false) {
                ActionVerifier.enableActionVerifier(I18n.translateString('actionVerifier.archiveEntry'), I18n.translateString('actionVerifier.archive'), function () {
                    TimelineContainer.showSavingChangesBanner()
                    window.kUser.timelines[window.currentTimeline].events[eventId].isArchived = true;
                    FirebaseAssistant.updateUserFirebaseData(function () {
                        TimelineContainer.redrawTimelines()
                    })
                })
            }

        } else if (timelineIsInUserProviderData[0]) {
            if (window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[eventId].isCreatedFromProvider === FirebaseAssistant.getUserId()) {
                ActionVerifier.enableActionVerifier(I18n.translateString('actionVerifier.archiveEntry'), I18n.translateString('actionVerifier.archive'), function () {
                    TimelineContainer.showSavingChangesBanner()
                    window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[eventId].isArchived = true;
                    FirebaseAssistant.updateSharedTimelineData(window.currentTimeline, FirebaseAssistant.getCurrentTimestamp(), function () {
                        FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => { // to get back correct provider events
                            TimelineContainer.redrawTimelines() // 'SavingChangesBanner' is hidden within here
                        })
                    })
                })
            }
        } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
            if (window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[eventId].isCreatedFromProvider === FirebaseAssistant.getUserId()) {
                ActionVerifier.enableActionVerifier(I18n.translateString('actionVerifier.archiveEntry'), I18n.translateString('actionVerifier.archive'), function () {
                    TimelineContainer.showSavingChangesBanner()
                    window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[eventId].isArchived = true;
                    FirebaseAssistant.updateSharedTimelineData(window.currentTimeline, FirebaseAssistant.getCurrentTimestamp(), function () {
                        FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => { // to get back correct provider events
                            TimelineContainer.redrawTimelines() // 'SavingChangesBanner' is hidden within here
                        })
                    })
                })
            }
        } else if (FirebaseAssistant.timelineIsShared(window.currentTimeline)) {
            if (window.sharedTimelines[window.currentTimeline].events[eventId].isCreatedFromProvider === false) {
                ActionVerifier.enableActionVerifier(I18n.translateString('actionVerifier.archiveEntry'), I18n.translateString('actionVerifier.archive'), function () {
                    TimelineContainer.showSavingChangesBanner()
                    window.sharedTimelines[window.currentTimeline].events[eventId].isArchived = true;
                    FirebaseAssistant.updateSharedTimelineData(window.currentTimeline, FirebaseAssistant.getCurrentTimestamp(), function () {
                        TimelineContainer.redrawTimelines()
                    })
                })
            }
        }
    }

    static removeTimelineEntryElements(eventId) {
        document.querySelectorAll(`[data-eventid="${eventId}"]`).forEach(el => el.remove())
    }

    static pulsateEvent(eventId) { //todo the pulse needs to happen after the element is done being scrolled into view
        let currentlyActiveView = 'horizontal'
        if (document.getElementById('horizontal').style.display === 'none') currentlyActiveView = 'vertical'
        const element = document.querySelector(`#${currentlyActiveView} [data-eventid="${eventId}"]`);
        element.scrollIntoView({
            block: "end",
            behavior: "smooth"
        });
        setTimeout(function () {
            const iconData = element.querySelector('clr-icon').getBoundingClientRect()
            const newNode = document.createElement("div");
            newNode.classList.add("ripple");
            document.body.appendChild(newNode);
            newNode.classList.add("animate");
            newNode.style.left = (iconData.left + 6.5) + "px";
            newNode.style.top = (iconData.top + 7) + "px";
            setTimeout(function () {
                document.querySelector('.ripple').remove()
            }, 1000)
        }, 1000)
    }

    static scrollTimelineToEnd() {
        setTimeout(function () {
            try {
                const scrollContainer = document.getElementById('scrollContainer')
                if (document.getElementById('horizontal').style.display === 'none') {
                    scrollContainer.scrollTop = scrollContainer.scrollHeight + 5000
                } else {
                    scrollContainer.scrollLeft = scrollContainer.scrollWidth
                }
            } catch (e) {
                //ignore
            }
        }, 200)
    }

    static changeEmptyTimelineSplasherState(splasherOn) {
        if (splasherOn) {
            document.getElementById('emptyTimelineSplasher').style.display = ''
        } else {
            document.getElementById('emptyTimelineSplasher').style.display = 'none'
        }
    }

    static populateTimeline(timelineId) {
        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(timelineId)
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(timelineId);
        const timelineIsShared = FirebaseAssistant.timelineIsShared(timelineId)
        const verticalContainer = document.getElementById('vertical')
        const horizontalContainer = document.getElementById('horizontal')
        const sortedMap = []
        let timelineEvents;
        if (timelineIsInUserProviderData[0]) {
            document.querySelector("#headerRow h1").firstChild.nodeValue = window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].timelineName
            document.getElementById('timelineHeaderSharedBudge').style.display = (timelineIsShared)? '':'none';
            timelineEvents = Object.keys(window.providerData[timelineIsInUserProviderData[1]].timelines[timelineId].events);
        } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
            document.querySelector("#headerRow h1").firstChild.nodeValue = window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].timelineName
            document.getElementById('timelineHeaderSharedBudge').style.display = (timelineIsShared)? '':'none';
            timelineEvents = Object.keys(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[timelineId].events);
        } else if (timelineIsShared) {
            document.querySelector("#headerRow h1").firstChild.nodeValue = window.sharedTimelines[window.currentTimeline].timelineName
            const pillElement = document.getElementById('timelineHeaderSharedBudge')
            pillElement.style.display = ''
            if (window.showOnlyProviderEventsInTimeline) {
                timelineEvents = Object.keys(window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events)
                // pillElement.classList.remove('bg-success')
                // pillElement.classList.add('bg-warning')
                pillElement.style.display = 'none'
            } else {
                timelineEvents = Object.keys(window.sharedTimelines[timelineId].events);
                pillElement.classList.remove('bg-warning')
                pillElement.classList.add('bg-success')
            }
        } else {
            document.querySelector("#headerRow h1").firstChild.nodeValue = window.kUser.timelines[window.currentTimeline].timelineName
            document.getElementById('timelineHeaderSharedBudge').style.display = 'none'
            timelineEvents = Object.keys(window.kUser.timelines[timelineId].events);
        }
        Array.prototype.forEach.call(timelineEvents, event => {
            if (event === 'placeholder') return;
            let latestVersion;
            let numeric;
            let timestamp;
            let foo;
            let timestamp1;
            let timestamp2;
            let fileCardinality;
            let title;
            let description;
            let eventShouldBeBlurred = ''
            let eventFileCountShouldBeBlurred = ''
            let dotShouldBeGreen = ''
            let isAProviderMadeEvent = false;

            if (window.showOnlyProviderEventsInTimeline) { // the user is a provider in a 3rth party timeline and must see only his own stuff and the rest blurred
                if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) { // provider has NO entries in that timeline
                    if (window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].isArchived) return // return is the same as continue when it comes to forEach loops
                    latestVersion = Object.keys(window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions).pop()
                    if (window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                        numeric = parseInt(window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    } else {
                        numeric = parseInt(window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp._seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    }
                    timestamp = new Date(numeric).toString()
                    foo = timestamp.split(" ")
                    const translationKey = `timelinePage.timestamps.months.${foo[1]}`
                    timestamp1 = `${foo[2]} ${I18n.translateString(translationKey)} ${foo[3]} `
                    timestamp2 = foo[4].split(":").slice(0, 2).join(":")
                    fileCardinality = window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].files.length -1
                    title = window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].title
                    description = window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].description
                    eventShouldBeBlurred = 'blurredEntry'
                    eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                    dotShouldBeGreen = 'blurredEntryIsShared'
                    isAProviderMadeEvent = window.providerData[window.orphanTimelinesToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].isCreatedFromProvider !== false
                } else { // provider has entries in that timeline
                    if (window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].isArchived) return // return is the same as continue when it comes to forEach loops
                    latestVersion = Object.keys(window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions).pop()
                    if (window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                        numeric = parseInt(window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    } else {
                        numeric = parseInt(window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp._seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    }
                    timestamp = new Date(numeric).toString()
                    foo = timestamp.split(" ")
                    const translationKey = `timelinePage.timestamps.months.${foo[1]}`
                    timestamp1 = `${foo[2]} ${I18n.translateString(translationKey)} ${foo[3]} `
                    timestamp2 = foo[4].split(":").slice(0, 2).join(":")
                    fileCardinality = window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].files.length -1
                    title = window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].title
                    description = window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].versions[latestVersion].description
                    if (window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].isCreatedFromProvider !== FirebaseAssistant.getUserId()) {
                        eventShouldBeBlurred = 'blurredEntry'
                        eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                        dotShouldBeGreen = 'blurredEntryIsShared'
                    } else {
                        dotShouldBeGreen = ''
                    }
                    isAProviderMadeEvent = window.providerData[window.timelineToCustomerProviderDictionary[timelineId]].timelines[timelineId].events[event].isCreatedFromProvider !== false
                }
            } else { // no "showOnlyProviderEventsInTimeline" restriction, show everything as expected
                if (timelineIsShared) {
                    if (window.sharedTimelines[timelineId].events[event].isArchived) return
                    // if (window.sharedTimelines[timelineId].events[event].isArchived && timelineIsInUserProviderData[0] === false) return
                    latestVersion = Object.keys(window.sharedTimelines[timelineId].events[event].versions).pop()
                    if (window.sharedTimelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                        numeric = parseInt(window.sharedTimelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    } else {
                        numeric = parseInt(window.sharedTimelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp._seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    }
                    timestamp = new Date(numeric).toString()
                    foo = timestamp.split(" ")
                    const translationKey = `timelinePage.timestamps.months.${foo[1]}`
                    timestamp1 = `${foo[2]} ${I18n.translateString(translationKey)} ${foo[3]} `
                    timestamp2 = foo[4].split(":").slice(0, 2).join(":")
                    fileCardinality = window.sharedTimelines[timelineId].events[event].versions[latestVersion].files.length -1
                    title = window.sharedTimelines[timelineId].events[event].versions[latestVersion].title
                    description = window.sharedTimelines[timelineId].events[event].versions[latestVersion].description
                    if (timelineIsInUserProviderData[0] || timelineIsInUserProviderDataButProviderHasNoEntries[0]) { //the provider should see as blue only his entries
                        if (window.sharedTimelines[timelineId].events[event].isCreatedFromProvider !== FirebaseAssistant.getUserId()) {
                            if (window.sharedTimelines[timelineId].events[event].isCreatedFromProvider === false)
                                dotShouldBeGreen = 'blurredEntryIsShared editable'
                            else
                                dotShouldBeGreen = 'blurredEntryIsShared'
                            if (!FirebaseAssistant.userIsPremium()) {
                                eventShouldBeBlurred = 'blurredEntry'
                                eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                            }
                        }
                    } else {
                        if (window.sharedTimelines[timelineId].events[event].isCreatedFromProvider !== false) {
                            dotShouldBeGreen = 'blurredEntryIsShared'
                            if (!FirebaseAssistant.userIsPremium()) {
                                eventShouldBeBlurred = 'blurredEntry'
                                eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                            }
                        } else if (window.sharedTimelines[timelineId].events[event].versions[latestVersion].versionCreator !== FirebaseAssistant.getUserId()) {
                            dotShouldBeGreen = 'blurredEntryIsShared editable'
                            if (!FirebaseAssistant.userIsPremium()) {
                                eventShouldBeBlurred = 'blurredEntry'
                                eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                            }
                        }
                    }
                    isAProviderMadeEvent = window.sharedTimelines[timelineId].events[event].isCreatedFromProvider !== false
                } else {
                    if (window.kUser.timelines[timelineId].events[event].isArchived) return // return is the same as continue when it comes to forEach loops
                    latestVersion = Object.keys(window.kUser.timelines[timelineId].events[event].versions).pop()
                    if (window.kUser.timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                        numeric = parseInt(window.kUser.timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp.seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    } else {
                        numeric = parseInt(window.kUser.timelines[timelineId].events[event].versions[latestVersion].userAssignedTimestamp._seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
                    }
                    timestamp = new Date(numeric).toString()
                    foo = timestamp.split(" ")
                    const translationKey = `timelinePage.timestamps.months.${foo[1]}`
                    timestamp1 = `${foo[2]} ${I18n.translateString(translationKey)} ${foo[3]} `
                    timestamp2 = foo[4].split(":").slice(0, 2).join(":")
                    fileCardinality = window.kUser.timelines[timelineId].events[event].versions[latestVersion].files.length -1
                    title = window.kUser.timelines[timelineId].events[event].versions[latestVersion].title
                    description = window.kUser.timelines[timelineId].events[event].versions[latestVersion].description
                    if (window.kUser.timelines[timelineId].events[event].isCreatedFromProvider !== false) {
                        dotShouldBeGreen = 'blurredEntryIsShared'
                        if (!FirebaseAssistant.userIsPremium()) {
                            eventShouldBeBlurred = 'blurredEntry'
                            eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                        }
                    } else if (window.kUser.timelines[timelineId].events[event].versions[latestVersion].versionCreator !== FirebaseAssistant.getUserId()) {
                        dotShouldBeGreen = 'blurredEntryIsShared editable'
                        if (!FirebaseAssistant.userIsPremium()) {
                            eventShouldBeBlurred = 'blurredEntry'
                            eventFileCountShouldBeBlurred = 'blurredEntryHideCount'
                        }
                    }
                    isAProviderMadeEvent = window.kUser.timelines[timelineId].events[event].isCreatedFromProvider !== false
                }
            }
            const svgToBeUsed = (isAProviderMadeEvent) ? providerSvg : userSvg;
            const additionalProviderCss = (isAProviderMadeEvent) ? 'transform: scale(1.05) translateY(-0.25rem)' : '';
            let files = ''
            if (fileCardinality !== 0) {
                files = `<button type="button" class="btn files position-relative custom-column mb-2 ${eventShouldBeBlurred}" style="margin-top: 15px; padding: 0; text-align: left; /* margin: 0; */"> <i class="bx bx-file-blank" style="margin-left: -6px; font-size: 3rem; padding: 0;"></i> <span class="position-absolute top-0 translate-middle badge rounded-pill bg-secondary ${eventFileCountShouldBeBlurred}" >${fileCardinality}</span></button>`
            }
            const eventElement = `
                <li data-eventId="${event}" data-version="${latestVersion}" class="clr-timeline-step">
                    <div class="hoverer" style="position: absolute; width: 100%; height: 100%;"></div>
                    <div class="clr-timeline-step-header" style="transform: scale(1);">${timestamp1}<small style="font-size: 0.6em;">${timestamp2}</small></div>
                    <clr-icon aria-current="true" aria-label="Current" data-isprovider="${isAProviderMadeEvent}" class="${dotShouldBeGreen}" style="${additionalProviderCss}">
                            ${svgToBeUsed}
                    </clr-icon>
                    <div class="clr-timeline-step-body">
                        <span class="clr-timeline-step-title ${eventShouldBeBlurred}">${title}</span>
                        <span class="clr-timeline-step-description ${eventShouldBeBlurred}">${description}</span>
                        ${files}
                    </div>
                </li>
            `
            if (sortedMap.length === 0) {
                horizontalContainer.insertAdjacentHTML("beforeend", eventElement)
                verticalContainer.insertAdjacentHTML("beforeend", eventElement)
                sortedMap.push([numeric, event])
            } else {
                let index = 0
                // console.debug(sortedMap)
                while (true) {
                    if ((sortedMap[index][0] < numeric)) {
                        if ((index + 1) === sortedMap.length) {
                            horizontalContainer.insertAdjacentHTML("beforeend", eventElement)
                            verticalContainer.insertAdjacentHTML("beforeend", eventElement)
                            sortedMap.push([numeric, event])
                            break
                        } else {
                            index += 1;
                        }
                    } else {
                        const RightHandSideNeighbourEvent = sortedMap[index][1]
                        document.querySelector(`#vertical [data-eventId="${RightHandSideNeighbourEvent}"]`).insertAdjacentHTML("beforebegin", eventElement)
                        document.querySelector(`#horizontal [data-eventId="${RightHandSideNeighbourEvent}"]`).insertAdjacentHTML("beforebegin", eventElement)
                        sortedMap.splice(index, 0, [numeric, event])
                        break
                    }
                }
            }
        })
        // TimelineContainer.scrollTimelineToEnd()
        TimelineContainer.fixVerticalSpacing()
        if (document.querySelectorAll('.clr-timeline-step').length === 0) {
            TimelineContainer.changeEmptyTimelineSplasherState(true)
        } else {
            TimelineContainer.changeEmptyTimelineSplasherState(false)
        }
    }

    static checkIfScrollNudgeShouldBeActivated() {
        try {
            if (document.getElementById('horizontal').scrollWidth > window.innerWidth
                && window.innerHeight < window.innerWidth
                && document.getElementById('scrollNudge').style.display === 'none') {
                document.getElementById('scrollNudge').style.opacity = '1'
                document.getElementById('scrollNudge').style.display = 'unset'
            }
        } catch (e) {
            //ignore
        }
    }

    static fireTimelinePopulationDoneEvent() {
        const event = new Event("timelinePopulationDone", {bubbles: true}); //  this is also used in the showcase assistant in addition to this component
        document.querySelector('timeline-container').dispatchEvent(event)
    }

    static deHighlightScrollNudge() {
        document.getElementById('scrollNudge').style.opacity = '0.2'
    }

    static deActivateScrollNudge() {
        document.getElementById('scrollNudge').style.display = 'none'
    }

    static fixVerticalSpacing() {
        try {
            document.querySelector('#vertical li').style.marginTop = '5.5rem'
        } catch (e) {
        }
    }

    static changeTimeline(timelineId, callback = () => {}) {
        window.currentTimeline = timelineId
        RouterAssistant.redirect('timeline-page', () => {
            document.body.addEventListener('timelinePopulationDone', callback, {once: true})
        })
    }

    static showSavingChangesBanner() {
        LoadingSplasher.activateSplasher(I18n.translateString('splashScreenMessages.savingChanges'))
    }

    static hideSavingChangesBanner() {
        LoadingSplasher.deactivateSplasher();
    }
}


TimelineContainer.template = /*html*/ `
    <div class="container-fluid h-100">
        <div id="headerRow" class="row fixed-top" style="background: var(--bs-body-bg);">
            <h1>
                <span id="timelineHeaderSharedBudge" class="position-absolute top-50 badge  rounded-pill bg-success border" style="display:none; transform: translate(0,-50%)!important;margin-left: 1rem;font-size: initial;">shared</span>
            </h1>
            <p id="savingChanges" style="display: none;" class="text-muted"> <img src="${loader}" style="filter: brightness(60%); width: 25px; margin-right: 1rem; margin-left: 2rem; ">Αποθήκευση αλλαγών </p>
        </div>
        <div class="row h-100">
            <div id="scrollContainer" class="col-12 my-auto d-flex align-items-center justify-content-center h-100 w-100" style="overflow: scroll;">
                <ul class="clr-timeline clr-timeline-horizontal w-100" id="horizontal" style="display: none;"></ul>
                <ul class="clr-timeline clr-timeline-vertical h-100" style="width: 400px; display: none;" id="vertical"></ul>
            </div>
            <div style="transition: opacity .5s ease-in-out ;text-align: center;transform: translate(-50%, -27%);left: 50%;top: 73%;position: absolute;display: none" id="scrollNudge">
                <img src="${scrollNudgeImage}" style="width: 8rem">
                <p id="scrollNudgeText" class="mt-3"></p>
            </div>
            <div id="emptyTimelineSplasher" style="display:none;top: 50%;width: min-content;left: 50%;height: 75dvh;position: absolute;transform: translate(-50%, -50%);">
                <img id="emptyTimelineSplasherImg" style="max-height: 100%">
            </div>
        </div>
    </div>
`;

TimelineContainer.reg('timeline-container');
