/*
* "kick-starter" as the name implies initiates all the required "stuff" of our application.
*/
import {I18n} from "../i18n/i18n.js";
import {FirebaseAssistant} from "../firebase-assistant/firebase-assistant.js";
import {BootstrapAssistant} from "../bootstrap-assistant/bootstrap-assistant.js";
import {RouterAssistant} from "../router-assistant/router-assistant.js";
import {PwaGuard} from "../../components/pwa-guard/pwa-guard.js";
import "../showcase-assistant/showcase-assistant.js";
import {SecurityAssistant} from "../security-assistant/security-assistant.js";
import {AnnouncementAssistant} from "../../components/announcement-assistant/announcement-assistant.js";
import {NetworkGuard} from "../../components/network-guard/network-guard.js";
import {GeofencingGuard} from "../geofencing-guard/geofencing-guard";
import {ShareButton} from "../../components/share-button/share-button";

class KickStarter {
    static initiate() {
        GeofencingGuard.initiate()
        I18n.initiate();
        PwaGuard.insertToPage();
        BootstrapAssistant.initiate();
        FirebaseAssistant.initiate();
        RouterAssistant.initiate();
        SecurityAssistant.initiate();
        AnnouncementAssistant.initiate();
        NetworkGuard.initiate();
        ShareButton.initiate()
    }
}

KickStarter.initiate();
