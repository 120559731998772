import {v4 as uuidv4} from "uuid";

export class MiscAssistant {
    static getUniqueIdentifier() {
        return 'a' + uuidv4().replaceAll("-", "")  // variables can't start with numbers
    }

    static alterInnerTextAnimated(element, newText, cssAttribute, newCssAttributeValue) {
        element.style.transition = 'opacity .5s ease-in-out'
        element.style.opacity = '0';
        setTimeout(function () {
            element.style[cssAttribute] = newCssAttributeValue
            element.innerText = newText;
            element.style.opacity = '1';
            element.classList.add('altered');
        }, 1000)
    }

    static arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    static postData(url = "", data = {}) {
        return new Promise(async (resolve, reject) => {
            fetch(url, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                // mode: "no-cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
            }).then(x => x.json())
                .then(data => {
                    resolve(data)
                })
        })
    }

    static toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    static debounce(callback, delay) {
        clearTimeout(window.debounceTimer)
        window.debounceTimer = setTimeout(() => {
            callback();
        }, delay)
    }

    static getDeviceIdentifier() {
        const nav = window.navigator
        return [nav.deviceMemory, nav.hardwareConcurrency, nav.language, nav.platform, nav.userAgent].join(' ')
    }

    static isElementXPercentInViewport(el, percentVisible) {
        let
            rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        )
    };
}

window.post = MiscAssistant.postData