import {BaseComponent} from "@symbiotejs/symbiote";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant.js";
import {NavBar} from "../nav-bar/nav-bar.js";
import {I18n} from "../../utilities/i18n/i18n.js";
import {LoadingSplasher} from "../loading-splasher/loading-splasher";
import {ClientsContainer} from "../clients-container/clients-container";
import {TimelineContainer} from "../timeline-container/timeline-container";

const avatar = require('../profile-container/assets/test.png')


const css = `
    <style>
    </style>
`

export class UserCreator extends BaseComponent {

    initCallback() {
        // document.head.insertAdjacentHTML("beforeend", css)
        window.userCreationModal = new window.bootstrap.Modal(document.getElementById('userCreationModal'))
        document.getElementById('userCreationModal').addEventListener("shown.bs.modal", (evt) => {
            const phoneInput = document.getElementById('userCreationInput')
            phoneInput.focus()
            phoneInput.selectionStart = phoneInput.selectionEnd = 10000;
        })
        document.getElementById('confirmUserCreationModal').addEventListener('click', UserCreator.addUserToClientList)
        document.getElementById('cancelUserCreationModal').addEventListener('click', () => {
            window.userCreationModal.hide()
            UserCreator.resetFormToOriginalForm()
        })
        document.getElementById('userCreationInput').addEventListener('input', UserCreator.checkIfInputIsValidPhone)
        document.getElementById('userCreationInput2').addEventListener('input', UserCreator.checkIfInputsAreValidNames)
        document.getElementById('userCreationInput3').addEventListener('input', UserCreator.checkIfInputsAreValidNames)
        UserCreator.translateShit()
    }

    static resetFormToOriginalForm() {
        // console.debug('resetFormToOriginalForm()')
        window.userToBeAddedToClientListId = undefined
        document.getElementById('userCreationInput').style.display = '';
        document.getElementById('userCreationInput').removeAttribute('disabled');
        document.getElementById('userCreationInputPlaceholder').style.display = '';
        document.getElementById('newUserToBeAddedCard').style.display = 'none'
        document.getElementById('newUserFullName').style.display = 'none'
        document.getElementById('newUserFirstTimelineName').style.display = 'none'
        document.getElementById('userCreationInput').value = '+3069'
        document.getElementById('userCreationInput2').value = ''
        document.getElementById('userCreationInput3').value = ''
        UserCreator.deactivateSaveButton()
    }

    static checkIfInputsAreValidNames() {
        let userDisplayNameCheck = false
        let userTimelineNameCheck = false
        const userDisplayNameInputString = document.getElementById('userCreationInput2').value;
        const userTimelineNameInputString = document.getElementById('userCreationInput3').value;
        const timelineNameInputIsVisible = document.getElementById('newUserFirstTimelineName').style.display !== 'none'
        if (userDisplayNameInputString.replace(/ /g, '').length > 3 && !/\d/.test(userDisplayNameInputString)) {
            userDisplayNameCheck = true
        }
        if (userTimelineNameInputString.replace(/ /g, '').length > 3 && !/\d/.test(userTimelineNameInputString)) {
            userTimelineNameCheck = true
        }

        if (userDisplayNameCheck) {
            if (!timelineNameInputIsVisible) {
                UserCreator.activateSaveButton()
            } else if (timelineNameInputIsVisible && userTimelineNameCheck) {
                UserCreator.activateSaveButton()
            } else {
                UserCreator.deactivateSaveButton()
            }
        } else {
            UserCreator.deactivateSaveButton()
        }
    }

    static checkIfInputIsValidPhone() {
        const inputString = document.getElementById('userCreationInput').value;
        if (I18n.mobileNumberIsValid(inputString) && FirebaseAssistant.getUserPhoneNumber() !== inputString) {
            document.getElementById('userCreationInput').setAttribute('disabled', '')
            console.debug('Phone valid. Asking BE')
            UserCreator.activateUserCheckLoader()
            FirebaseAssistant.userExistsFromProvider(inputString).then(result => {
                UserCreator.deactivateUserCheckLoader()
                console.debug(result)
                if (result.userId !== null && result.displayName !== null) {
                    window.userToBeAddedToClientListId = result.userId
                    document.getElementById('userCreationInput').style.display = 'none';
                    document.getElementById('userCreationInputPlaceholder').style.display = 'none';
                    document.getElementById('newToBeAddedUserName').innerText = result.displayName;
                    document.getElementById('newToBeAddedUserPhone').innerText = inputString;
                    document.getElementById('newUserToBeAddedCard').style.display = '';
                    UserCreator.activateSaveButton();
                } else if (result.userId !== null && result.displayName === null) {
                    window.userToBeAddedToClientListId = result.userId
                    document.getElementById('newUserFullName').style.display = ''
                    document.getElementById('userCreationInput2').style.display = ''
                    document.getElementById('userCreationInput2').focus()
                    document.getElementById('userCreationInputPlaceholder2').style.display = ''
                } else if (result.userId === null) {
                    document.getElementById('newUserFullName').style.display = ''
                    document.getElementById('userCreationInput2').style.display = ''
                    document.getElementById('userCreationInput2').focus()
                    document.getElementById('userCreationInputPlaceholder2').style.display = ''
                    document.getElementById('newUserFirstTimelineName').style.display = ''
                    document.getElementById('userCreationInput3').style.display = ''
                    document.getElementById('userCreationInputPlaceholder3').style.display = ''
                }
            })
        }
    }

    static activateUserCheckLoader() {
        document.getElementById('newUserFromProviderBackEndCheckLoader').style.display = ''
    }

    static deactivateUserCheckLoader() {
        document.getElementById('newUserFromProviderBackEndCheckLoader').style.display = 'none'
    }

    static activateSaveButton() {
        document.getElementById('confirmUserCreationModal').removeAttribute('disabled')
    }

    static deactivateSaveButton() {
        document.getElementById('confirmUserCreationModal').setAttribute('disabled', '')
    }

    static load(element) {
        document.body.insertAdjacentHTML('beforeend', '<user-creator></user-creator>')
        UserCreator.attachToElement(element)
    }

    static translateShit() {
        document.getElementById('userCreationModalTitle').innerText = I18n.translateString('userCreator.newClient')
        document.getElementById('cancelUserCreationModal').innerText = I18n.translateString('userCreator.cancel')
        document.getElementById('confirmUserCreationModal').innerText = I18n.translateString('userCreator.addClient')
        document.getElementById('userCreationInputPlaceholder').innerText = I18n.translateString('userCreator.phoneNumber')
        document.getElementById('userCreationInputPlaceholder2').innerText = I18n.translateString('userCreator.fullname')
        document.getElementById('userCreationInputPlaceholder3').innerText = I18n.translateString('userCreator.firstTimelineName')
    }

    static attachToElement(element) {
        element.addEventListener('click', function (evt) {
            if (FirebaseAssistant.userIsPremium()) {
                document.getElementById('userCreationInput').value = '+3069'
                window.userCreationModal.show()
            }
        })
    }

    static addUserToClientList() {
        const userExistsAndHasNameNotVisible = document.getElementById('newUserToBeAddedCard').style.display === 'none'
        const timelineInputNameNotVisible = document.getElementById('newUserFirstTimelineName').style.display === 'none'
        const phone = document.getElementById('userCreationInput').value
        const name = document.getElementById('userCreationInput2').value
        const userToBeAddedToClientListId = window.userToBeAddedToClientListId

        if (!userExistsAndHasNameNotVisible) { // user already exists and has name
            FirebaseAssistant.addOrRemoveUsersFromClientele([userToBeAddedToClientListId], [], function () {
                FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => {
                    ClientsContainer.populateTableFromData(function () {
                        UserCreator.doneAddingUserToClientList(userToBeAddedToClientListId, false)
                    })
                })
            })
        } else if (userExistsAndHasNameNotVisible && timelineInputNameNotVisible) { // user already exists but hasn't name
            FirebaseAssistant.updateUserDisplayNameFromProvider(phone, name, function () {
                FirebaseAssistant.addOrRemoveUsersFromClientele([userToBeAddedToClientListId], [],function () {
                    FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => {
                        ClientsContainer.populateTableFromData(function () {
                            UserCreator.doneAddingUserToClientList(userToBeAddedToClientListId, false)
                        })
                    })
                })
            })
        } else { // user does not exist
            const firstTimelineNameForUser = document.getElementById('userCreationInput3').value
            FirebaseAssistant.registerUserFromProvider(phone, name, firstTimelineNameForUser, function (newUserUid) {
                FirebaseAssistant.addOrRemoveUsersFromClientele([newUserUid], [], function () {
                    FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => {
                        ClientsContainer.populateTableFromData(function () {
                            UserCreator.doneAddingUserToClientList(newUserUid, true)
                        })
                    })
                })
            });
        }
        window.userCreationModal.hide()
        LoadingSplasher.activateSplasher('Adding new client to clientele')
        UserCreator.resetFormToOriginalForm()
    }

    static doneAddingUserToClientList(customerId, newUser) {
        if (newUser) {
            const timelineId = Object.keys(window.providerData[customerId].timelines).pop()
            TimelineContainer.changeTimeline(timelineId, () => {
                document.getElementById('newEventButton').click();
            })
        } else {
            NavBar.reloadCurrentPage()
            LoadingSplasher.deactivateSplasher();
            setTimeout(function () {
                ClientsContainer.focusClient(customerId)
            }, 2000)
        }
    }
}

UserCreator.template = /*html*/ `   
    <div class="modal fade" id="userCreationModal" style="z-index: 1059" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div  class="modal-header"  >
                    <h1 id="userCreationModalTitle" class="modal-title fs-5">New client</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="display: none"></button>
                </div>
                <div id="userCreationModalBody" class="modal-body">
                    <div id="newUserSpecifyPhone" class="form-floating mb-3 col-12">
                        <input id="userCreationInput" type="text" class="form-control" placeholder="foo">
                        <label id="userCreationInputPlaceholder" for="userCreationInput">Phone number</label>
                    </div>
                    <div id="newUserFullName" class="form-floating mb-3 col-12">
                        <input id="userCreationInput2" type="text" class="form-control" placeholder="foo" style="display: none">
                        <label id="userCreationInputPlaceholder2" for="userCreationInput2"  style="display: none">Full name</label>
                    </div>
                    <div id="newUserFirstTimelineName" class="form-floating mb-3 col-12" style="display: none">
                        <input id="userCreationInput3" type="text" class="form-control" placeholder="foo">
                        <label id="userCreationInputPlaceholder3" for="userCreationInput3"  style="display: none">Timeline name</label>
                    </div>
                    <div id="newUserToBeAddedCard" class="offcanvas-body small mb-3" style="display: none">
                        <div class="card mx-auto" style="width: 350px;">
                            <div class="row g-0">
                                <div class="col-3">
                                    <img src="${avatar}" class="img-fluid mx-auto" alt="..." style="position:relative;display: block;width: 4rem;border-radius: 15px;top: 50%;transform: translate(0, -50%);">
                                </div>
                                <div class="col-9">
                                    <div class="card-body">
                                        <h5 id="newToBeAddedUserName" class="card-title">BLE</h5>
                                        <p id="newToBeAddedUserPhone" class="card-text">https://ble-app.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="newUserFromProviderBackEndCheckLoader" class="wrapper" style="display: none; transform: scale(0.5) translate(65%, -115%); top: 50%; "> <div class="circle"></div> <div class="circle"></div> <div class="circle"></div> <div class="shadow"></div> <div class="shadow"></div> <div class="shadow"></div> </div>
                </div>
                <div class="modal-footer">
                    <button id="cancelUserCreationModal" type="button" class="btn btn-secondary" >Ακύρωση</button>
                    <button disabled id="confirmUserCreationModal" type="button" class="btn btn-primary">Προσθήκη πελάτη</button>
                </div>
            </div>
        </div>
    </div>
`;

UserCreator.reg('user-creator');
