import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import "./assets/main"
import './assets/styles.css'
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";
import '../event-editor/event-editor'
import {EventEditor} from "../event-editor/event-editor";
import {PwaGuard} from "../pwa-guard/pwa-guard.js";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {UserCreator} from "../user-creator/user-creator.js";
import {ShareButton} from "../share-button/share-button";

const css = `
 `

export class NavBar extends BaseComponent {
    init$ = {
        goToPage: (event) => {
            const li = event.target.closest("li")
            const page = li.getAttribute("value")
            RouterAssistant.redirect(page)
            document.getElementsByClassName("active-link")[0].classList.remove('active-link')
            li.querySelector('a').classList.add('active-link')
        },
        // activateNewEventModal: () => {
        //     alert("New event modal activated")
        // },
    };

    initCallback() {
        document.body.insertAdjacentHTML("beforeend", "<event-editor></event-editor>")
        EventEditor.registerActivationButton("newEventButton")
        if (PwaGuard.accessIsThroughIphone() !== 0) NavBar.activateIphoneMode();
        document.getElementById('navBarClientsDescription').innerText = I18n.translateString('navBar.clients')
        document.getElementById('navBarTimelineDescription').innerText = I18n.translateString('navBar.timeline')
        document.getElementById('navBarNewEntryDescription').innerText = I18n.translateString('navBar.newEntry')
        document.getElementById('navBarNewCustomerDescription').innerText = I18n.translateString('navBar.newClient')
        document.getElementById('navBarProfileDescription').innerText = I18n.translateString('navBar.profile')
        UserCreator.load(document.getElementById('newCustomerButton').parentElement)
    }

    static activateIphoneMode(){
        document.getElementById('nav-menu').classList.add('mb-4')
        document.getElementById('nav-menu').insertAdjacentHTML('afterend', '<div class="nav__menu bg-light-subtle" style="z-index: 1057"></div>')
        RouterAssistant.switchToIosContainerHeight()
    }

    // static observeUrlChange = () => {
    //     let oldHref = document.location.href;
    //     const body = document.querySelector("body");
    //     const observer = new MutationObserver(mutations => {
    //         if (oldHref !== document.location.href) {
    //             oldHref = document.location.href;
    //             NavBar.checkNavBarState();
    //         }
    //     });
    //     observer.observe(body, { childList: true, subtree: true });
    // };

    static reloadCurrentPage(){
        document.querySelector('.active-link').click()
        // console.debug('Suppose I am reloading the current page')
    }

    static checkNavBarState(){
        if (window.location.href.includes('calendar-page') ||
            window.location.href.includes('clients-page') ||
            window.location.href.includes('search-page') ||
            window.location.href.includes('timeline-page') ||
            window.location.href.includes('profiles-page')
        ) {
            FirebaseAssistant.userIsProvider(function (isProvider) {
                NavBar.activateNavBar(isProvider);
                NavBar.updateActiveNarBarLinkStatus();
                ShareButton.showButton()
            })

        } else {
            NavBar.deactivateNavBar(true);
            ShareButton.hideButton()
        }
    }

    static disableNavigation(){
        document.querySelectorAll('.nav__link').forEach(el => {
            el.style.pointerEvents = 'none'
        })
    }

    static enableNavigation(){
        document.querySelectorAll('.nav__link').forEach(el => {
            el.style.pointerEvents = 'auto'
        })
    }

    static updateActiveNarBarLinkStatus(){
        document.querySelectorAll('.nav__link').forEach(el => {
            el.classList.remove('active-link')
        })
        if (window.location.href.includes('calendar-page')) {
            document.querySelector('li[value="calendar-page"]>a').classList.add('active-link')
        } else if (window.location.href.includes('clients-page')) {
            document.querySelector('li[value="clients-page"]>a').classList.add('active-link')
        } else if (window.location.href.includes('search-page')) {
            document.querySelector('li[value="search-page"]>a').classList.add('active-link')
        } else if (window.location.href.includes('timeline-page')) {
            document.querySelector('li[value="timeline-page"]>a').classList.add('active-link')
        } else if (window.location.href.includes('profiles-page')) {
            document.querySelector('li[value="profiles-page"]>a').classList.add('active-link')
        }
    }

    static activateNavBar(userIsProvider){
        if (NavBar.navBarExists()){
            document.getElementsByTagName('nav-bar')[0].style.display = "";
            document.getElementById('nav-menu').style.transform = ''
        } else {
            document.body.insertAdjacentHTML("beforeend", "<nav-bar></nav-bar>")
        }
        NavBar.loadAppropriateVersion(userIsProvider)
    }

    static deactivateNavBar(remove=false){
        if (NavBar.navBarExists()) {
            document.getElementById('nav-menu').style.transform = 'translateY(70rem)'
            if (remove)
                document.getElementsByTagName('nav-bar')[0].style.display = "none";
        }
    }

    static navBarIsActive(){
        try {
            return document.getElementsByTagName('nav-bar')[0].style.display === "";
        } catch (e) {
            return false;
        }
    }

    static navBarExists(){
        return (document.getElementsByTagName("nav-bar").length > 0)
    }

    static loadAppropriateVersion(userIsProvider){
        const providerOptions = document.querySelectorAll('.providerNavigationType');
        const customerOptions = document.querySelectorAll('.customerNavigationType');
        if (userIsProvider) {
            providerOptions.forEach(el => { el.style.display = ''; });
            customerOptions.forEach(el => { el.style.display = 'none'; });
            if (window.location.href.includes('timeline-page')){
                document.querySelector('li[value="4"]').style.display = '';
                document.querySelector('li[value="5"]').style.display = 'none';
            } else {
                document.querySelector('li[value="4"]').style.display = 'none';
                document.querySelector('li[value="5"]').style.display = '';
            }
        } else {
            providerOptions.forEach(el => { el.style.display = 'none'; });
            customerOptions.forEach(el => { el.style.display = ''; });
            document.querySelector('li[value="4"]').style.display = '';
        }
    }
}

NavBar.template = /*html*/ `
                <div class="nav__menu bg-light-subtle" id="nav-menu">
                    <ul class="nav__list">
<!--                        <li class="nav__item providerNavigationType" value="calendar-page">-->
<!--                            <a  set="onclick: goToPage" class="nav__link active-link">-->
<!--                                <i class='bx bx-calendar nav__icon'></i>-->
<!--                                <span class="nav__name">Calendar</span>-->
<!--                            </a>-->
<!--                        </li>-->

                        <li class="nav__item providerNavigationType" value="clients-page">
                            <a  set="onclick: goToPage" class="nav__link ">
                                <i class='bx bxs-contact nav__icon'></i>
                                <span id="navBarClientsDescription" class="nav__name"></span>
                            </a>
                        </li>

<!--                        <li class="nav__item providerNavigationType" value="search-page">-->
<!--                            <a  set="onclick: goToPage" class="nav__link ">-->
<!--                                <i class='bx bx-search nav__icon'></i>-->
<!--                                <span class="nav__name">Search</span>-->
<!--                            </a>-->
<!--                        </li>-->

                        <li class="nav__item customerNavigationType" value="timeline-page">
                            <a  set="onclick: goToPage" class="nav__link ">
                                <i class='bx bx-objects-vertical-center nav__icon'></i>
                                <span id="navBarTimelineDescription" class="nav__name"></span>
                            </a>
                        </li>
                        
                         <li class="nav__item" value="4">
                            <a  id="newEventButton" class="nav__link ">
                                <i class='bx bx-plus-medical nav__icon'></i>
                                <span id="navBarNewEntryDescription" class="nav__name"></span>
                            </a>
                        </li>
                         
                         <li class="nav__item providerNavigationType" value="5">
                            <a  id="newCustomerButton" class="nav__link ">
                                <i class='bx bx-plus-medical nav__icon'></i>
                                <span id="navBarNewCustomerDescription" class="nav__name">New customer</span>
                            </a>
                        </li>

                        <li class="nav__item" value="profiles-page">
                            <a  set="onclick: goToPage" class="nav__link" id="profilesPageNav">
                                <i class='bx bxs-user nav__icon'></i>
                                <span id="navBarProfileDescription" class="nav__name"></span>
                            </a>
                        </li>
                    </ul>
                </div>
`;

NavBar.reg('nav-bar');
