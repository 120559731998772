import {initializeApp} from "firebase/app";
import {
    initializeFirestore, collection, addDoc, setDoc, doc, getDoc, updateDoc, Timestamp, onSnapshot
} from "firebase/firestore";
import {
    getAuth, RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserLocalPersistence, signOut, updateProfile
} from "firebase/auth";
import { getDatabase, set as set_rtdb, ref as ref_rtdb, push as push_rtdb, get as get_rtdb, child as child_rtdb, update as update_rtdb, onValue as onValue_rtdb } from "firebase/database";
import {getFunctions, httpsCallable} from 'firebase/functions'
import {getStorage, ref, uploadBytes, uploadString, listAll, getDownloadURL} from "firebase/storage";
import {RouterAssistant} from "../router-assistant/router-assistant.js";
import {I18n} from "../i18n/i18n.js";
import {getUserDemoData} from "./assets/timeline-demo-data.js";
// import {exampleVideoBase64} from "./assets/exampleVideoBase64.js";
// import {exampleImageBase64} from "./assets/exampleImageBase64.js";
import {MiscAssistant} from "../misc-assistant/misc-assistant.js";
import {PhoneInput} from "../../components/phone-input/phone-input.js";
import {TimelineContainer} from "../../components/timeline-container/timeline-container.js";
import {UserNotifier} from "../../components/user-notifier/user-notifier.js";
import {LoadingSplasher} from "../../components/loading-splasher/loading-splasher.js";
import {NavBar} from "../../components/nav-bar/nav-bar.js";
import {PwaGuard} from "../../components/pwa-guard/pwa-guard.js";
// import {ClientsContainer} from "../../components/clients-container/clients-container";

const css = `<style>body{overflow-y: initial!important;}.grecaptcha-badge { visibility: hidden; }</style>`

const URL_notifySharedUsersOfTimelineChanges = 'https://notifysharedusersoftimelinechanges-xlcztu2ada-uc.a.run.app'
// const URL_getSingleSharedTimelineData = 'https://getsinglesharedtimelinedata-xlcztu2ada-uc.a.run.app'
const URL_getProviderOverallData = 'https://getprovideroveralldata-xlcztu2ada-uc.a.run.app'
const URL_updateTimelineAccessBook = 'https://sharetimelinetousers-xlcztu2ada-uc.a.run.app'
const URL_getSharedTimelinesOverallData = 'https://getsharedtimelineoveralldata-xlcztu2ada-uc.a.run.app'
const URL_getSharedFileDownloadUrl = 'https://getsharedtimelineeventfiledownloadurl-xlcztu2ada-uc.a.run.app'
const URL_updateSharedTimeline = 'https://updatesharedtimeline-xlcztu2ada-uc.a.run.app'
const URL_uploadFilesToSharedEventBucket = 'https://uploadfiletosharedeventbucket-xlcztu2ada-uc.a.run.app'
const URL_getNameFromPhone = 'https://getnamefromphone-xlcztu2ada-uc.a.run.app'
const URL_getNameFromUid = 'https://getnamefromuid-xlcztu2ada-uc.a.run.app'
const URL_newUserFromProvider = 'https://newuserfromprovider-xlcztu2ada-uc.a.run.app'
const URL_userExists = 'https://userexists-xlcztu2ada-uc.a.run.app'
const URL_extendTrial = 'https://extendtrial-xlcztu2ada-uc.a.run.app'


export class FirebaseAssistant {
    static firebaseConfig = {
        apiKey: "AIzaSyBSdg2Z4hOWzIz-cbuUhf9UmiI50NS9LT8",
        authDomain: "kartela-678b4.firebaseapp.com",
        databaseURL: "https://kartela-678b4-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "kartela-678b4",
        storageBucket: "kartela-678b4.appspot.com",
        messagingSenderId: "52097107214",
        appId: "1:52097107214:web:1bdc1655c1d19f666a435f",
        measurementId: "G-KXM4P267ME"
    };

    static initiate() {
        window.app = initializeApp(FirebaseAssistant.firebaseConfig);
        // window.payments = getStripePayments(window.app, {
        //     productsCollection: "stripe-products",
        //     customersCollection: "stripe-customers",
        // });
        window.auth = getAuth(app);
        window.firestore = initializeFirestore(app, {PersistenceEnabled: false, experimentalAutoDetectLongPolling:true, useFetchStreams:false});
        window.auth.languageCode = I18n.getUsersPreferredLanguage()
        window.storage = getStorage(app)
        window.rtdb = getDatabase();
        window.functions = getFunctions(app)
        window.stripeFunctions = getFunctions(app, 'europe-west3')
        window.unsubscribeActions = []
        setPersistence(window.auth, browserLocalPersistence)
            .then(() => {
            })
            .catch((error) => {
                console.debug(error.code + " : " + error.message)
            });
        window.auth.onAuthStateChanged(user => {
            if (user) {
                FirebaseAssistant.userExists().then((userExists) => {
                    if (userExists === 1) {
                        FirebaseAssistant.userIsProviderInitialFetch().then((isProvider) => {
                            RouterAssistant.authStateChangedRerouting(true, true, isProvider);
                            FirebaseAssistant.listenOnUpdatesOnAnyOfTheTimelinesTheUserHasAccessTo()
                        })
                    } else if (userExists === 2) {
                        FirebaseAssistant.userIsProviderInitialFetch().then((isProviderRedundantVariable) => {
                            RouterAssistant.authStateChangedRerouting(true, false, null);
                            FirebaseAssistant.listenOnUpdatesOnAnyOfTheTimelinesTheUserHasAccessTo()
                        })
                    } else if (userExists === 0) {
                        RouterAssistant.authStateChangedRerouting(true, false, null);
                    } else {
                        console.debug('Something has gone terribly wrong here!')
                    }
                })
            } else {
                RouterAssistant.authStateChangedRerouting(false, null, null)
            }
        })
    }

    static getDelayedUserDataAndReload(timestamp) {
        window.kUser.lastUpdated = timestamp
        setTimeout(function () {
            get_rtdb(child_rtdb(ref_rtdb(window.rtdb), FirebaseAssistant.getUserId())).then(function (docSnap) {
                window.kUser = docSnap.val();
                // NavBar.reloadCurrentPage() -> removed this because in many clients scenario it would reload all the time!
            })
        }, 5000)
    }

    static getDelayedSharedDataAndReload() {
        setTimeout(function () {
            MiscAssistant.postData(URL_getSharedTimelinesOverallData, {userId: FirebaseAssistant.getUserId()})
                .then(response => {
                    window.sharedTimelines = response.timelines
                    window.userHasPremiumPaidForCurrentMonth = response.userHasPremiumPaidForCurrentMonth
                    // NavBar.reloadCurrentPage() -> removed this because in many clients scenario it would reload all the time!
                })
        }, 5000)
    }

    static listenOnUpdatesOnAnyOfTheTimelinesTheUserHasAccessTo() { // functions takes some time to save the new user data hence the delayed fetch
        const deviceIdentifier = ref_rtdb(window.rtdb, `${FirebaseAssistant.getUserId()}/lastAccessedMachine`);
        const unsub =  onValue_rtdb(deviceIdentifier, (snapshot) => {
            if (snapshot.val() !== MiscAssistant.getDeviceIdentifier()) {
                FirebaseAssistant.logOut()
            }
        });
        const lastUpdated = ref_rtdb(window.rtdb, `${FirebaseAssistant.getUserId()}/lastUpdated`);
        const unsub2 =  onValue_rtdb(lastUpdated, (snapshot) => {
            if (window.kUser.lastUpdated.seconds !== snapshot.val().seconds) {
                FirebaseAssistant.getDelayedUserDataAndReload(snapshot.val())
            }
        });

        const unsub3 = onSnapshot(doc(window.firestore, "external-timelines-users-have-access-to", window.auth.currentUser.uid), (doc) => {
            if (window.sharedDataSubFirstIterationBlocker === undefined) window.sharedDataSubFirstIterationBlocker = 'foo'
            else FirebaseAssistant.getDelayedSharedDataAndReload()
        });
        window.unsubscribeActions.push(unsub)
        window.unsubscribeActions.push(unsub2)
        window.unsubscribeActions.push(unsub3)
    }

    static requestDataDeletion(callback) {
        setDoc(doc(window.firestore, "requested-deletion", window.auth.currentUser.uid), {}).then(() => {
            callback()
        })
    }

    static updateLastAccessedDevice() {
        window.kUser.lastUpdated = FirebaseAssistant.getCurrentTimestamp()
        window.kUser.lastAccessedMachine = MiscAssistant.getDeviceIdentifier()

        update_rtdb(ref_rtdb(window.rtdb, window.auth.currentUser.uid), {
            lastAccessedMachine: window.kUser.lastAccessedMachine,
            lastUpdated: window.kUser.lastUpdated
        }).then(r => {
            console.debug('updated lastAccessedMachine on rtdb')
        })
    }

    static notifyPotentialSharedUsersOfTimelineUpdates(timelineId, timestamp, excludeUsers = []) {
        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(timelineId);
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(timelineId);

        let sharedUsers;

        if (timelineId in window.kUser.timelines) {
            sharedUsers = window.kUser.timelines[timelineId].hasAccessToTimeline
        } else if (timelineIsInUserProviderData[0]) {
            sharedUsers = window.providerData[timelineIsInUserProviderData[1]].timelines[timelineId].hasAccessToTimeline
        } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
            sharedUsers = window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[timelineId].hasAccessToTimeline
        } else if (FirebaseAssistant.timelineIsShared(timelineId)) {
            sharedUsers = window.sharedTimelines[timelineId].hasAccessToTimeline
        }
        const sharedUsersWithoutSelf = structuredClone(sharedUsers);
        console.debug(`initial shared users ${sharedUsers}`)

        const myself = FirebaseAssistant.getUserId()
        const indexOfMyself = sharedUsersWithoutSelf.indexOf(myself)
        indexOfMyself !== -1 ? sharedUsersWithoutSelf.splice(indexOfMyself, 1) : console.debug("self not included in shared users");

        excludeUsers.forEach(user => {
            const indexOfUser = sharedUsersWithoutSelf.indexOf(user)
            indexOfUser !== -1 ? sharedUsersWithoutSelf.splice(indexOfUser, 1) : console.debug("user not included in shared users");
        })

        if (sharedUsersWithoutSelf.length !== 1) {
            console.debug(`will attempt to notify for ${sharedUsersWithoutSelf}`)
            MiscAssistant.postData(URL_notifySharedUsersOfTimelineChanges, {
                sharedUsers: sharedUsersWithoutSelf, lastUpdated: timestamp.seconds, timelineId: timelineId
            })
                .then(response => {
                    console.debug(response)
                })
        }
    }

    static setUpLoginFunctionality(idOfPhoneInputContainer, idOfSmsInputContainer, idOfPhonePrefixInputField, idOfPhoneInputField, idOfPhoneSubmitButton, ifOfConfirmationSmsCodeInputField, ifOfConfirmationSmsCodeSubmitButton) {
        window.recaptchaVerifier = new RecaptchaVerifier(document.getElementById('recaptcha'), {
            // 'size': 'normal',
            'size': 'invisible', 'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // console.debug(response)
                // onSignInSubmit();
            }, 'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
                RouterAssistant.redirect('login-page');
            }
        }, window.auth);

        document.head.insertAdjacentHTML("beforeend", css)

        document.getElementById(idOfPhoneInputField).addEventListener("input", () => {
            const value = document.getElementById(idOfPhoneInputField).value
            const isNum = /^\d+$/.test(value);
            if (value.length === 10 && isNum && value.slice(0, 2) === '69') {
                FirebaseAssistant.initiateLogin(idOfPhoneInputContainer, idOfSmsInputContainer, idOfPhonePrefixInputField, idOfPhoneInputField)
            }
        })

        document.getElementById(ifOfConfirmationSmsCodeInputField).addEventListener("input", () => {
            if (document.getElementById(ifOfConfirmationSmsCodeInputField).value.length === 6) {
                document.activeElement.blur();
                FirebaseAssistant.validateSmsCode_1(ifOfConfirmationSmsCodeInputField)
            }
        })
    }

    static addressReCaptchaIosBug() { // issue where the iframe container has less height than the iframe
        const x = 50;
        const interval = 100;

        for (let i = 0; i < x; i++) {
            setTimeout(function () {
                document.querySelectorAll('iframe').forEach(el => {
                    el.parentNode.style.height = 'unset'
                })
            }, i * interval)
        }
    }

    static validateSmsCode_1(ifOfConfirmationSmsCodeInputField) {
        document.getElementById('exitLoginValidation').style.display = 'none'
        document.getElementById('validation').style.opacity = '0'
        const smsVerificationNumber = document.getElementById(ifOfConfirmationSmsCodeInputField).value;
        FirebaseAssistant.validateSmsCode_2(smsVerificationNumber)
    }

    static validateSmsCode_2(smsVerificationNumber) {
        if (window.confirmationResult === undefined) {
            setTimeout(function () {
                FirebaseAssistant.validateSmsCode_2(smsVerificationNumber)
            }, 50)
        } else {
            window.confirmationResult.confirm(smsVerificationNumber).then((result) => {
                window.user = result.user; // User signed in successfully.
                console.debug(window.auth.currentUser)
            }).catch((error) => {
                alert(I18n.translateString('registrationPage.wrongOtp'))
                document.getElementById('sms-confirmation-input').value = ''
                document.getElementById('validation').style.opacity = '1'
                document.getElementById('exitLoginValidation').style.display = ''
                setTimeout(()=>{document.getElementById('sms-confirmation-input').focus()}, 1000)
            });
        }
    }

    static initiateLogin(idOfPhoneInputContainer, idOfSmsInputContainer, idOfPhonePrefixInputField, idOfPhoneInputField) {

        let attribute = ''
        let value = ''
        if (window.innerHeight < window.innerWidth) {
            attribute = 'marginLeft'
            value = '5rem'
        }
        MiscAssistant.alterInnerTextAnimated(document.getElementById('loginH1'), I18n.translateString('loginValidationPage.mainHeader'))
        MiscAssistant.alterInnerTextAnimated(document.getElementById('loginH2'), I18n.translateString('loginValidationPage.secondaryHeader'), attribute, value)
        const phoneNumber = document.getElementById(idOfPhonePrefixInputField).value + document.getElementById(idOfPhoneInputField).value.replaceAll(' ', '');
        // console.debug(phoneNumber)
        PhoneInput.removePhoneThenShowSms()
        signInWithPhoneNumber(window.auth, phoneNumber, window.recaptchaVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                console.debug(confirmationResult)
            }).catch((error) => {
            console.debug(error)
            alert(I18n.translateString('registrationPage.otpTimeout'))
            RouterAssistant.redirect('login-page');
        });
        FirebaseAssistant.addressReCaptchaIosBug()
        setTimeout(function () {
            try {
                const resetter1 = document.getElementById('exitLoginValidation')
                resetter1.style.opacity = '1'
            } catch (e) {
                console.debug(e)
            }
        }, 5000)
    }

    static setupLogoutFunctionality(idOfLogoutButton) {
        document.getElementById(idOfLogoutButton).addEventListener('click', FirebaseAssistant.logOut)
    }

    static logOut() {
        console.debug('sign out function was called')
        window.unsubscribeActions.forEach(unsubscribeAction => unsubscribeAction())
        signOut(window.auth).then(() => {
            window.timelineToCustomerProviderDictionary = undefined
            window.orphanTimelinesToCustomerProviderDictionary = undefined
            window.currentTimeline = undefined
            window.kUser = undefined
            window.sharedTimelines = undefined
            window.providerData = undefined
            window.sharedDataSubFirstIterationBlocker = undefined
            // window.auth = undefined; should not be undefined or null because the login page recaptcha needs it. (firebase cleans it on login so don't worry)
            // window.firestore = undefined;
            // window.storage = undefined
            window.userHasPremiumPaidForCurrentMonth = undefined;
            // window.functions = undefined
            // window.stripeFunctions = undefined
            window.unsubscribeActions = []
            // window.sharedTimelineTracker = undefined
            console.debug('done signing out!')
        }).catch((error) => {
            console.debug(error)
        });
    }

    static removeDisclaimer() {
        try {
            document.getElementById('disclaimer').remove()
        } catch (e) {
            console.debug(e)
        }
    }

    static userExists() {
        // 0 -> does not exist
        // 1 -> exists
        // 2 -> exists but has been created by provider and has not registered yet
        LoadingSplasher.activateSplasher(I18n.translateString('splashScreenMessages.weArePreparingYourData'))
        return new Promise(async (resolve, reject) => {
            await get_rtdb(child_rtdb(ref_rtdb(window.rtdb), FirebaseAssistant.getUserId())).then(async function (docSnap) {
                if (docSnap.exists() && docSnap.val().isProvider === 'unregistered') // isProvider equal to null means created from provider and still needs to register
                    resolve(2)
                else if (docSnap.exists())
                    resolve(1)
                else
                    resolve(0)
            }).catch(function (error) {
                console.debug("Error getting document:", error);
            });
        })
    }

    static getUserPhoneNumber() {
        return window.auth.currentUser.phoneNumber
    }

    static userIsProviderInitialFetch(activateSplasher = true) {
        return new Promise(async (resolve, reject) => {
            if (activateSplasher)
                LoadingSplasher.activateSplasher(I18n.translateString('splashScreenMessages.weArePreparingYourData'))
            // const docRef = doc(window.firestore, 'users', window.auth.currentUser.uid);
            const promises = []
            promises.push(get_rtdb(child_rtdb(ref_rtdb(window.rtdb), FirebaseAssistant.getUserId())))
            promises.push(MiscAssistant.postData(URL_getSharedTimelinesOverallData, {userId: FirebaseAssistant.getUserId()}))
            promises.push(MiscAssistant.postData(URL_getProviderOverallData, {userId: FirebaseAssistant.getUserId()}))
            const results = await Promise.all(promises)
            window.kUser = results[0].val();
            FirebaseAssistant.updateLastAccessedDevice();
            window.sharedTimelines = results[1].timelines
            window.userHasPremiumPaidForCurrentMonth = results[1].userHasPremiumPaidForCurrentMonth
            if (results[0].val()['isProvider']) {
                window.providerData = results[2].customers
                FirebaseAssistant.createTimelineToCustomerProviderDictionary()
                resolve(results[0].val()['isProvider'])
            } else {
                resolve(results[0].val()['isProvider'])
            }
        })
    }

    static createTimelineToCustomerProviderDictionary() {
        // here we create 2 dictionaries.
        // One for the timelines the provider has entries in and one for timelines that the provider does not have entries in (hence orphans)
        // After done , the dictionary takes as entry a timelineId and returns the owner
        const dict = {}
        const clients = Object.keys(window.kUser.providerData);
        for (let i = 0; i < clients.length; i++) {
            if (clients[i] === 'placeholder') continue;
            for (let j = 0; j < window.kUser.providerData[clients[i]].length; j++) {
                if (window.kUser.providerData[clients[i]][j] === 'placeholder') continue;
                const foo = window.kUser.providerData[clients[i]][j].split('/')
                dict[foo[0]] = clients[i]
                // dict[foo[1]] = clients[i]; uncomment this to have the dict work for events too
            }
        }
        const dict2 = {}
        const clients2 = Object.keys(window.providerData);
        for (let i = 0; i < clients2.length; i++) {
            if (clients2[i] === 'placeholder') continue;
            try {
                const clientsTimelines = Object.keys(window.providerData[clients2[i]].timelines);
                for (let j = 0; j < clientsTimelines.length; j++) {
                    if (!(clientsTimelines[j] in dict))
                        dict2[clientsTimelines[j]] = clients2[i]
                }
            } catch (e) { // the requested user from provider data may not exist anymore ! need to remove him
                console.warn(e)
                delete window.kUser.providerData[clients2[i]]
                delete window.providerData[clients2[i]]
            }
        }
        window.timelineToCustomerProviderDictionary = dict
        window.orphanTimelinesToCustomerProviderDictionary = dict2
    }

    static addNewlyCreatedTimelineFromProviderToOrphansDictionary(timelineId, clientId){
        window.orphanTimelinesToCustomerProviderDictionary[timelineId] = clientId
    }

    static userIsProvider(callback) {
        if (window.kUser === undefined || window.kUser.isProvider === undefined) setTimeout(function () {
            FirebaseAssistant.userIsProvider(callback)
        }, 50)
        else callback(window.kUser.isProvider)
    }

    static registerNewUserToDatabase(userIsProvider) { // when this changes it needs to happen at functions as well
        return new Promise(async (resolve, reject) => {
            // console.debug("registering new user to database")
            try {
                const demoData = getUserDemoData(window.auth.currentUser.uid, userIsProvider, 'false', true)
                set_rtdb(ref_rtdb(window.rtdb, window.auth.currentUser.uid), demoData).then(r => {
                    window.kUser = demoData;
                    window.sharedTimelines = {};
                    window.userHasPremiumPaidForCurrentMonth = true;
                    window.providerData = {placeholder : ['placeholder']}
                    // FirebaseAssistant.uploadInitialUserDemoFiles(demoData.lastAccessedTimeline, Object.keys(demoData.timelines[demoData.initialTimeline].events).pop(), function () {
                        RouterAssistant.authStateChangedRerouting(true, true, userIsProvider)
                    // })
                });
                setDoc(doc(window.firestore, "external-timelines-users-have-access-to", window.auth.currentUser.uid), {}).then((r) => {
                });
            } catch (e) {
                console.debug(e)
            }
        })
    }

    static registerUserFromProvider(phone, name, firstTimelineName, callback) {
        const demoData = getUserDemoData('temp_toBeReplacedOnTheBackEnd', 'unregistered', window.auth.currentUser.uid, false)
        MiscAssistant.postData(URL_newUserFromProvider, {
            phone: phone,
            name: name,
            kUser: demoData,
            timelineName: firstTimelineName
        }).then(response => {
            console.debug(response)
            callback(response.user)
        })
    }

    static performNewUserActions(userIsProvider) {
        FirebaseAssistant.userExists().then((exists) => {
            console.debug("does user exist?: " + exists.toString())
            if (exists === 0) {
                FirebaseAssistant.registerNewUserToDatabase(userIsProvider).then(r => {
                    console.debug(r)
                })
            } else if (exists === 2) {
                window.kUser.isProvider = userIsProvider
                MiscAssistant.postData(URL_extendTrial, {uid: FirebaseAssistant.getUserId(),})
                    .then(response => {
                        if (response.result)
                            FirebaseAssistant.updateUserFirebaseData(() => {
                                RouterAssistant.authStateChangedRerouting(true, true, userIsProvider)
                            })
                        else
                            console.debug('User tried to extend trial but he already has an extension in place!')
                    })
            } else {
                console.debug('User tried to register but he already exists!')
            }
        })
    }

    static getUserId() {
        return window.auth.currentUser.uid
    }

    static getEventAttachmentsFileNames(timelineId, eventId, eventVersion, callback) {
        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(timelineId);
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(timelineId);

        if (timelineId in window.kUser.timelines)
            callback(window.kUser.timelines[timelineId].events[eventId].versions[eventVersion].files.filter(item => item !== 'placeholder'))
        else if (timelineIsInUserProviderData[0])
            callback(window.providerData[timelineIsInUserProviderData[1]].timelines[timelineId].events[eventId].versions[eventVersion].files.filter(item => item !== 'placeholder'))
        else if (timelineIsInUserProviderDataButProviderHasNoEntries[0])
            callback(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[timelineId].events[eventId].versions[eventVersion].files.filter(item => item !== 'placeholder'))
        else if (FirebaseAssistant.timelineIsShared(timelineId))
            callback(window.sharedTimelines[timelineId].events[eventId].versions[eventVersion].files.filter(item => item !== 'placeholder'))
    }

    static getFileDownloadUrl(owner, timelineId, eventId, eventVersion, fileName, callback) {
        getDownloadURL(ref(window.storage, `/timeline-events/${owner}/${timelineId}/${eventId}/${fileName}`)).then(r => callback(r)).catch(r => console.debug(r))
    }

    static getSharedFileDownloadUrl(owner, timelineId, eventId, eventVersion, fileName, callback) {
        MiscAssistant.postData(URL_getSharedFileDownloadUrl, {
            requester: FirebaseAssistant.getUserId(),
            owner: owner,
            timelineId: timelineId,
            eventId: eventId,
            eventVersion: eventVersion,
            fileName: fileName,
        }).then(response => {
            callback(response.downloadUrl)
        })
    }

    static timelineIsShared(timelineId) {
        return Object.keys(window.sharedTimelines).includes(timelineId)
    }

    static timelineIsArchived(timelineId) {
        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(timelineId);
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(timelineId);
        if (timelineId in window.kUser.timelines)
            return window.kUser.timelines[timelineId].isArchived
        else if (timelineIsInUserProviderData[0])
            return window.providerData[timelineIsInUserProviderData[1]].timelines[timelineId].isArchived
        else if (timelineIsInUserProviderDataButProviderHasNoEntries[0])
            return window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[timelineId].isArchived
        else if (FirebaseAssistant.timelineIsShared(timelineId))
            return window.sharedTimelines[timelineId].isArchived
    }

    static uploadCameraPhotosAndLocalFilesToEvent(timelineId, eventId, eventVersion, photoList, localFileList, cameraTransferredFiles, callbackWhenDone) {
        let uploads = []
        photoList.forEach(photo => {
            uploads.push(uploadString(ref(window.storage, `/timeline-events/${FirebaseAssistant.getUserId()}/${timelineId}/${eventId}/${photo[0]}`), photo[1], 'base64', {
                contentType: 'image/png', customMetadata: {session: eventId}
            }))
        })
        Array.prototype.forEach.call(localFileList, (file) => {
            uploads.push(uploadBytes(ref(window.storage, `/timeline-events/${FirebaseAssistant.getUserId()}/${timelineId}/${eventId}/${file.name.split(/(\\|\/)/g).pop()}`), file, {customMetadata: {session: eventId}}))
        })
        cameraTransferredFiles.forEach((cTransfer) => {
            uploads.push(uploadBytes(ref(window.storage, `/timeline-events/${FirebaseAssistant.getUserId()}/${timelineId}/${eventId}/${cTransfer[0]}`), cTransfer[2], {customMetadata: {session: eventId}}))
        })
        Promise.all(uploads).then((values) => {
            callbackWhenDone();
        })
    }

    static uploadCameraPhotosAndLocalFilesToSharedEvent(owner, timelineId, eventId, eventVersion, photoList, localFileList, cameraTransferredFiles, callbackWhenDone) {
        const promises = []
        localFileList.forEach(file => {
            const data = new FormData();
            data.append('requester', FirebaseAssistant.getUserId())
            data.append('owner', owner)
            data.append('timelineId', timelineId)
            data.append('eventId', eventId)
            data.append('type', 'local')
            data.append('file', file)

            promises.push(fetch(URL_uploadFilesToSharedEventBucket, {
                method: 'POST', mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                body: data
            }))
        })
        photoList.forEach(photo => {
            const data = new FormData();
            data.append('requester', FirebaseAssistant.getUserId())
            data.append('owner', owner)
            data.append('timelineId', timelineId)
            data.append('eventId', eventId)
            data.append('type', 'photo')
            data.append('photoData', photo[1])
            data.append('photoName', photo[0])

            promises.push(fetch(URL_uploadFilesToSharedEventBucket, {
                method: 'POST', mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                body: data
            }))
        })
        cameraTransferredFiles.forEach(cTransfer => {
            const data = new FormData();
            data.append('requester', FirebaseAssistant.getUserId())
            data.append('owner', owner)
            data.append('timelineId', timelineId)
            data.append('eventId', eventId)
            data.append('type', 'cTransfer')
            data.append('fileName', cTransfer[0])
            data.append('file', cTransfer[2])

            promises.push(fetch(URL_uploadFilesToSharedEventBucket, {
                method: 'POST', mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                body: data
            }))
        })

        Promise.all(promises).then(r => {
            callbackWhenDone()
        })
    }

    static initiateCameraPhotosTransferDirectory() {
        uploadString(ref(window.storage, `/mobile-transfers/${FirebaseAssistant.getUserId()}/${window.mobileTransferGuid}/start.txt`), "", 'base64', {customMetadata: {session: window.mobileTransferGuid}})
    }

    static uploadCameraPhotosToBeTransferredToEvent(guid, userId, photoList, callbackWhenDone) {
        let uploads = []
        photoList.forEach((photo) => {
            uploads.push(uploadString(ref(window.storage, `/mobile-transfers/${userId}/${guid}/${photo[0]}`), photo[1], 'base64', {
                contentType: 'image/png', customMetadata: {session: guid}
            }))
        })
        Promise.all(uploads).then((values) => {
            callbackWhenDone();
        })
    }

    static userIsPremium() {
        return window.userHasPremiumPaidForCurrentMonth
    }

    static createNewTimeline(timelineName) {
        const newTimelineId = MiscAssistant.getUniqueIdentifier()

        // if below changes then the event-editor saveEvent with timeline creation code part should also change.
        window.kUser.timelines[newTimelineId] = {
            timelineName: timelineName, isArchived: false, timelineOwnershipHistory: {
                0: {
                    owner: FirebaseAssistant.getUserId(), ownershipStartedOn: {
                        timestamp: FirebaseAssistant.getCurrentTimestamp(), location: I18n.getUsersCurrentLocation()
                    }
                }
            }, hasAccessToTimeline: [FirebaseAssistant.getUserId()], hasHadAccessToTimeline: [FirebaseAssistant.getUserId()], events: {placeholder: 'placeholder'}
        }
        FirebaseAssistant.updateUserFirebaseData(function () {
            TimelineContainer.changeTimeline(newTimelineId)
        })
    }

    static updateUserFirebaseData(callback) {
        window.kUser.lastUpdated = FirebaseAssistant.getCurrentTimestamp()

        set_rtdb(ref_rtdb(window.rtdb, window.auth.currentUser.uid), window.kUser).then(r => {
            callback()
        });
    }

    static updateLastAccessedTimeline(timelineId) {
        if (window.kUser.lastAccessedTimeline !== timelineId) {
            window.kUser.lastAccessedTimeline = timelineId
            window.kUser.lastUpdated = FirebaseAssistant.getCurrentTimestamp()
            update_rtdb(ref_rtdb(window.rtdb, window.auth.currentUser.uid), {
                lastAccessedTimeline: timelineId,
                lastUpdated: window.kUser.lastUpdated
            }).then(r => {
                console.debug('updated LastAccessedTimeline')
            })
        }
    }

    static ownerOfSharedTimelineIsNoLongerPro(sharedTimelineId) {
        return (window.sharedTimelines[sharedTimelineId] !== undefined && window.sharedTimelines[sharedTimelineId].events === undefined)
    }

    static timelineIsInUserProviderData(timelineId) {
        // if true returns the owner too
        if (window.timelineToCustomerProviderDictionary === undefined) return [false, false]
        else if (timelineId in window.timelineToCustomerProviderDictionary) return [true, window.timelineToCustomerProviderDictionary[timelineId]]
        else return [false, false]
    }

    static timelineIsInUserProviderDataButProviderHasNoEntries(timelineId) {
        // if true returns the owner too
        if (window.orphanTimelinesToCustomerProviderDictionary === undefined) return [false, false]
        else if (timelineId in window.orphanTimelinesToCustomerProviderDictionary) return [true, window.orphanTimelinesToCustomerProviderDictionary[timelineId]]
        else return [false, false]
    }

    static updateSharedTimelineData(timelineId, timestamp, callback) {
        const obj = {sharedTimeline: {}}
        obj['timestamp'] = timestamp
        obj['requester'] = FirebaseAssistant.getUserId()
        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(timelineId);
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(timelineId);

        if (timelineIsInUserProviderData[0]) { //if anything changes in the "functions" endpoint "getProviderOverallData" it needs to be reflected here
            const originalKuserObject = structuredClone(window.providerData[timelineIsInUserProviderData[1]].timelines[timelineId])
            delete originalKuserObject.phoneNumber
            delete originalKuserObject.name
            obj['sharedTimeline'][timelineId] = originalKuserObject
        } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) { //if anything changes in the "functions" endpoint "getProviderOverallData" it needs to be reflected here
            const originalKuserObject = structuredClone(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[timelineId])
            delete originalKuserObject.phoneNumber
            delete originalKuserObject.name
            obj['sharedTimeline'][timelineId] = originalKuserObject
        } else if (FirebaseAssistant.timelineIsShared(timelineId)) {
            obj['sharedTimeline'][timelineId] = window.sharedTimelines[timelineId];
        }

        MiscAssistant.postData(URL_updateSharedTimeline, obj)
            .then(response => {
                console.debug(response)
                callback()
            })
    }

    static updateTimelineAccessBook(timelineId, userListAdd, userListRemove, callback) {
        MiscAssistant.postData(URL_updateTimelineAccessBook, {
            timelineId: timelineId,
            owner: FirebaseAssistant.getUserId(),
            usersToShare: userListAdd,
            usersToRemove: userListRemove,
            lastUpdated: FirebaseAssistant.getCurrentTimestamp().seconds
        }).then(response => {
            // console.debug(response)
            if (response.fails.length !== 0) {
                UserNotifier.notifyUser(I18n.translateString('userNotifier.attention'), `${I18n.translateString('userNotifier.usersDoNotExist')}: ${response.fails}`)
            }
            callback(response)
        })
    }

    static downloadLocallyCameraPhotosToBeTransferredToEvent(guid, callback) {
        const promises = []
        const promises2 = []
        const promises3 = []
        let downloadedImages = [];

        listAll(ref(window.storage, `/mobile-transfers/${FirebaseAssistant.getUserId()}/${guid}`)).then((results) => {
            // console.debug(results.items)
            results.items.forEach(item => {
                promises.push(getDownloadURL(ref(window.storage, item._location.path_)))
            })
            Promise.all(promises).then(downloadURLs => {
                // console.debug(downloadURLs)
                downloadURLs.forEach(downloadURL => {
                    promises2.push(fetch(downloadURL))
                })
                Promise.all(promises2).then(fetchesResponses => {
                    // console.debug(fetchesResponses)
                    fetchesResponses.forEach(fetchedResponse => {
                        promises3.push(fetchedResponse.blob())
                    })
                    Promise.all(promises3).then(imagesBlobs => {
                        // console.debug(imagesBlobs)
                        imagesBlobs.forEach((imageBlob, imageBlobIndex) => {
                            const name = results.items[imageBlobIndex]._location.path_.split('/').pop()
                            // console.debug(typeof imageBlob)
                            const localUrl = URL.createObjectURL(imageBlob)
                            downloadedImages.push([name, localUrl, imageBlob]) // the file name, the created local download link, the image blob
                        })
                        window.cameraTransfers = [...window.cameraTransfers, ...downloadedImages];
                        callback(downloadedImages)
                    })
                })
            })
        })
    }

    // static uploadInitialUserDemoFiles(timelineId, eventId, callbackWhenDone) {
    //     let promises = []
    //     promises.push(uploadString(ref(window.storage, `/timeline-events/${FirebaseAssistant.getUserId()}/${timelineId}/${eventId}/example.jpg`), exampleImageBase64, 'base64', {
    //         contentType: 'image/jpg', customMetadata: {session: eventId}
    //     }))
    //     promises.push(uploadString(ref(window.storage, `/timeline-events/${FirebaseAssistant.getUserId()}/${timelineId}/${eventId}/example.mp4`), exampleVideoBase64, 'base64', {
    //         contentType: 'video/mp4', customMetadata: {session: eventId}
    //     }))
    //     Promise.all(promises).then((values) => {
    //         callbackWhenDone();
    //     })
    // }

    static finaliseCameraPhotosTransferDirectory(userId, guid) {
        uploadString(ref(window.storage, `/mobile-transfers/${userId}/${guid}/done.txt`), "", 'base64', {customMetadata: {session: guid}})
    }

    static mobileTransferUploadsAreFinished(guid, callback) {
        getDownloadURL(ref(window.storage, `/mobile-transfers/${FirebaseAssistant.getUserId()}/${guid}/done.txt`)).then(r => callback(true)).catch(r => callback(false))
    }

    static getSpecificTimestamp(date) {
        return Timestamp.fromDate(date);
    }

    static getCurrentTimestamp(plusMinusDays = 0, plusMinusSeconds = 0) {
        let date = new Date()
        date.setDate(date.getDate() + plusMinusDays);
        date.setSeconds(date.getSeconds() + plusMinusSeconds);
        return Timestamp.fromDate(date);
    }

    static async helloWorldPayments(callback) {
        const docRef = doc(window.firestore, "stripe-customers", FirebaseAssistant.getUserId());
        const colRef = collection(docRef, "checkout_sessions")
        const data = {
            // price: 'price_1NNaSgIvcW7CeqBds0CTGXaq', // this is the test one
            price: 'price_1NNaTtIvcW7CeqBdXpCc6jFj', // this is the production one
            success_url: `${window.location.origin}/profiles-page/returnedFromStripe`,
        }
        if (!PwaGuard.accessIsThroughMobile()) // mobile phones open stripe in webView and can close it with browser controls
            data.cancel_url = `${window.location.origin}/profiles-page`;
        let reference;
        while (true) {
            try {
                reference = await addDoc(colRef, data)
                break;
            } catch (e) {
                console.debug(e)
            }
        }
        console.debug(reference)
        const unsub = onSnapshot(doc(window.firestore, `stripe-customers/${FirebaseAssistant.getUserId()}`, `checkout_sessions/${reference.id}`), (doc) => {
            console.debug("Current data: ", doc.data());
            const {error, url} = doc.data();
            console.debug(doc.data());
            console.debug(doc.data().sessionId)
            if (error) {
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // console.debug(url)
                // window.location.assign(url);
                callback(url, doc.data().sessionId)
            }
        });

        window.unsubscribeActions.push(unsub)
    }

    static getUsersDisplayName() {
        return auth.currentUser.displayName;
    }

    static updateUserDisplayName(displayName, callback) {
        updateProfile(auth.currentUser, {displayName: displayName}).then(r => {
            callback();
        })
    }

    static updateUserDisplayNameFromProvider(userPhone, displayName, callback) {
        MiscAssistant.postData(URL_newUserFromProvider, {
            phone: userPhone,
            name: displayName,
        }).then(response => {
            console.debug(response)
            callback(response)
        })
    }

    static getUsernamesFromPhones(phonesArray, callback) {
        if (phonesArray.length !== 0) {
            MiscAssistant.postData(URL_getNameFromPhone, {users: phonesArray}).then(r => {
                callback(r)
            })
        }
    }

    static getUsernamesFromUids(uidsArray, callback) {
        if (uidsArray.length !== 0) {
            MiscAssistant.postData(URL_getNameFromUid, {users: uidsArray}).then(r => {
                callback(r)
            })
        }
    }

    static userExistsFromProvider(phone) {
        return new Promise(async (resolve, reject) => {
            MiscAssistant.postData(URL_userExists, {
                phone: phone,
            }).then(response => {
                resolve(response)
            })
        })
    }



    static userInClientele(userId) {
        return (userId in window.providerData)
    }

    static addOrRemoveUsersFromClientele(userIdsToBeAdded, userIdsToBeRemoved, callback = function () {}) {
        const uniqsAdded = [...new Set(userIdsToBeAdded)];
        const uniqsRemoved = [...new Set(userIdsToBeRemoved)];
        uniqsAdded.forEach(userId => {
            if (!FirebaseAssistant.userInClientele(userId)) {
                window.kUser.providerData[userId] = ['placeholder']
            } else {
                console.debug('user already in clientele')
            }
        })
        uniqsRemoved.forEach(userId => {
            if (FirebaseAssistant.userInClientele(userId)) {
                delete window.kUser.providerData[userId]
            } else {
                console.debug('user already NOT in clientele')
            }
        })
        uniqsRemoved.forEach(userId => {delete window.providerData[userId]})
        if (userIdsToBeAdded.length!== 0 || userIdsToBeRemoved.length!== 0)
            FirebaseAssistant.updateUserFirebaseData(callback)
    }

    static addEventEntryInClienteleUser(client, timeline, event, callback = ()=>{}) {
        window.kUser.providerData[client].push(`${timeline}/${event}`)
        FirebaseAssistant.updateUserFirebaseData(function (foo) {
            FirebaseAssistant.userIsProviderInitialFetch(false).then((bar) => {
                callback()
            })
        })
    }

    static getStripeCustomerPortalLink(callback) {
        const functionRef = httpsCallable(window.stripeFunctions, 'ext-firestore-stripe-payments-createPortalLink');
        let data = {}
        if (!PwaGuard.accessIsThroughMobile()) data = {returnUrl: `${window.location.origin}/profiles-page`}
        functionRef(data)
            .then((result) => {
                callback(result.data.url)
            });
    }
}
