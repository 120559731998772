import {BaseComponent} from "@symbiotejs/symbiote";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {TimelineContainer} from "../timeline-container/timeline-container";
import {UserNotifier} from "../user-notifier/user-notifier";
import {I18n} from "../../utilities/i18n/i18n";
import {EventEditor} from "../event-editor/event-editor";

const css = `
    <style>
    .list-group-item:hover{
        cursor:pointer;
    }
    </style>
`
export class TimelineShowcaser extends BaseComponent {
    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        window.timelineShowcaserModal = new window.bootstrap.Modal(document.getElementById('timelineShowcaserModal'))
        document.getElementById('newTimelineButton').addEventListener('click', TimelineShowcaser.createNewTimelineToClient)
        TimelineShowcaser.translateShit()
    }

    static activate(userId, selectToCreateEvent = false) {
        if (selectToCreateEvent) {
            document.getElementById('timelineShowcaserModalTitle').innerText = I18n.translateString('timelineShowcaser.whichTimelineAddEvent')
        } else {
            document.getElementById('timelineShowcaserModalTitle').innerText = `${I18n.translateString('timelineShowcaser.timeslinesOfUser')} "${window.providerData[userId].name}"`
        }
        const containerElement = document.getElementById('timelineSelector')
        containerElement.innerHTML = ''
        Object.keys(window.providerData[userId].timelines).forEach(timelineId => {
            let sharedPill ;
            let timelineIsShared ;
            if (FirebaseAssistant.timelineIsArchived(timelineId)) return
            if (FirebaseAssistant.timelineIsShared(timelineId)) {
                if (FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(timelineId)) { // owner is not premium
                    sharedPill = `<span class="clienteleSharedTimelinePill p-2 rounded-circle" style="opacity: 0 ;margin-right: 0.3rem; position: relative; display: inline-block; transform: translateY(20%) scale(0.5); "></span>`
                    timelineIsShared = -1;
                } else {
                    sharedPill = `<span class="clienteleSharedTimelinePill p-2 bg-success  rounded-circle" style="margin-right: 0.3rem; position: relative; display: inline-block; transform: translateY(20%) scale(0.5); "></span>`
                    timelineIsShared = 1;
                }
            }  else {
                sharedPill = `<span class="clienteleSharedTimelinePill p-2 bg-success  rounded-circle" style="opacity: 0 ; margin-right: 0.3rem; position: relative; display: inline-block; transform: translateY(20%) scale(0.5); "></span>`
                timelineIsShared = 0;
            }
            const events = window.providerData[userId].timelines[timelineId].events;
            const totalEventCardinality = Object.values(events).filter(event => !event.isArchived).length -1; // -1 because there is always the placeholder event
            let eventCardinality = 0
            window.kUser.providerData[userId].forEach(event => {if (event.includes(timelineId)){eventCardinality++}})
            const stringToBeUsed = (totalEventCardinality === 0)? `${totalEventCardinality} ${I18n.translateString('timelineShowcaser.entries')}` : `${totalEventCardinality} ${I18n.translateString('timelineShowcaser.entries')} ( ${eventCardinality} ${I18n.translateString('timelineShowcaser.fromYou')} )` ;
            containerElement.insertAdjacentHTML('beforeend', `<a class="list-group-item list-group-item-action" data-timelineid="${timelineId}">${sharedPill}<span style="overflow: hidden;">${window.providerData[userId].timelines[timelineId].timelineName}<br><small style=" float: right; ">${stringToBeUsed}</small></span></a>`)
            document.querySelector(`.list-group-item-action[data-timelineid="${timelineId}"]`).addEventListener('click', function () {
                TimelineShowcaser.timelineWasSelected(userId, timelineId, eventCardinality, timelineIsShared, selectToCreateEvent)
            })
        })
        window.timelineShowcaserModal.show()
    }
    static initiate(){
        document.body.insertAdjacentHTML('beforeend', '<timeline-showcaser></timeline-showcaser>')
    }

    static timelineWasSelected(user, timeline, eventCardinality, timelineIsShared, selectToCreateEvent){
        // timelineIsShared (1-> true, 0 -> false, -1 is shared but user no longer premium)
        // console.debug(`timeline ${timeline} was selected with user being ${user} and the state is ${timelineIsShared}`)
        document.querySelector('#timelineShowcaserModal button').click()
        if (selectToCreateEvent){
            TimelineContainer.changeTimeline(timeline, ()=>{document.getElementById('newEventButton').click()})
        } else {
            if (eventCardinality === 0) {
                TimelineContainer.changeTimeline(timeline)
            } else {
                switch (timelineIsShared) {
                    case 0:
                        TimelineContainer.changeTimeline(timeline)
                        break;
                    case 1:
                        TimelineContainer.changeTimeline(timeline)
                        break;
                    case -1:
                        setTimeout(function () {UserNotifier.notifyUser(I18n.translateString('userNotifier.ownerNoLongerProTitle'), I18n.translateString('userNotifier.ownerNoLongerProDescription'))}, 2000)
                        TimelineContainer.changeTimeline(timeline)
                        break;
                }
            }
        }
    }

    static createNewTimelineToClient() {
        const userId = document.querySelector('td.bg-primary').parentElement.getAttribute('data-userid')
        EventEditor.activateEditorCreatingAnewTimeline(userId)
    }

    static translateShit(){
        document.getElementById('newTimelineButton').innerText = I18n.translateString('timelineShowcaser.createNewTimeline')
    }
}

TimelineShowcaser.template = /*html*/ `
<div class="modal fade" id="timelineShowcaserModal" style="z-index: 1059" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div  class="modal-header">
                <h1 id="timelineShowcaserModalTitle" class="modal-title fs-5">Οι καρτέλες του Χ</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div id="timelineShowcaserModalBody" class="modal-body">
                <div id="timelineSelector" class="list-group mb-3" style="max-height: 40dvh;overflow: auto;">
                </div>
                <div id="timelineSelector2" class="list-group" style="max-height: 40dvh;overflow: auto;">
                    <a id="newTimelineButton" class="list-group-item list-group-item-action list-group-item-light" style="position: sticky;bottom: 0;text-align: center">Δημιουργία νέας καρτέλας</a>
                </div>
            </div
        </div>
    </div>
</div>
`;

TimelineShowcaser.reg('timeline-showcaser');
