import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {LoginContainer} from "../login-container/login-container";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";
const background1 = require('../login-container/assets/background (1).webp')
const background2 = require('../login-container/assets/background-mobile (1).webp')

const css = `
<style>
    @-webkit-keyframes pulse {
      0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      }
      70% {
          -webkit-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
      }
      100% {
          -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      }
    }
    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      }
      70% {
          -moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
          box-shadow: 0 0 0 15px rgba(204,169,44, 0);
      }
      100% {
          -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
          box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      }
    }

    registration-container {
        width: 100dvw;
        height: 100dvh;
        display: inline-block;
    }
    
    #disclaimer {
        position: fixed;
        opacity: 0.3;
        font-size: 0.7em;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
    }
    
    registration-container .card:hover {
        cursor: pointer;
    }
    
    .registationOptionSelected {
        border: 2px solid royalblue;
    }
    
    #eula input{
        animation: pulse 2s infinite;
            border-radius: 0.25em!important;
            margin-right: 5px!important;
            float: none!important;
    }
    
    #eula input:hover{cursor: pointer}
    
    #business,
    #individual,
    #eula,
    #registernH1,
    #registerH2 {
        transition: opacity 1s ease-in-out
    }
    
    @media (orientation: landscape) {
        registration-container {
            background: linear-gradient(to right, rgba(34, 37, 41, 1)40%, rgba(34, 37, 41, 0.8)55%, rgba(0, 0, 0, 0))65%, url('${background1}');
            background-size: cover;
            background-position: center right;
        }
    
        registration-container h1 {
            font-size: 70px;
        }
    
        #welcomeColumn2 {
            margin-left: 5dvw;
        }
    
        #eula {
            /*position: fixed;
             */
            margin-left: 5dvw;
            bottom: 5%;
        }
    
        registration-container .card {
            margin-left: 2rem;
            max-width: 26rem;
        }
    
        phone-input {
            margin-top: 6rem
        }
    
        #individual {
            margin-top: 3rem;
            margin-bottom: 2rem;
        } 
        
        #business {
            margin-bottom: 2rem;
        }  
    }
    
    @media (orientation: portrait) {
        registration-container {
            background: linear-gradient(to top, rgba(34, 37, 41, 1)60%, rgba(34, 37, 41, 0.8)75%, rgba(0, 0, 0, 0))85%, url('${background2}');
            background-size: cover;
            background-position: center right;
        }
    
        registration-container h1 {
            font-size: 50px;
        }
    
        #eula {
            display: inline-block;
            text-align: center;
            bottom: 2%;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
        }
    
        #disclaimerRow {
            position: fixed;
            bottom:0;
        }
    
        #disclaimerContainer {
            text-align: center
        }  
    }
    
    @media (orientation: portrait) and (max-width: 600px) {
        registration-container h2 {
            margin-bottom: 1rem !important;
        }
    
        #registernH1, #registerH2 {
            transform: scale(0.8)
        }
    
        #eula {
            font-size: small;
            width: 90vw;
            margin-top: 3dvh;
        }
    
        registration-container .card {
            transform: scale(0.8);
            max-width: 100vw;
        }
    
        #business {
            margin-bottom: 1rem
        }
        
        #individual {
            margin-top: 5dvh;
        }
    }
    
    @media (orientation: portrait) and (min-width: 600px) {
        registration-container h2 {
            margin-bottom: 3rem !important;
        }
    
        registration-container .card {
            max-width: 90vw;
            margin-bottom: 3rem
        }
    
        #business {
            margin-bottom: 3rem
        }
    }
</style>
 `

export class RegistrationContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        RegistrationContainer.translateShit()
        document.querySelector('registration-container').classList.add('login')
        document.querySelector('input').style.float = 'none!important'
        window.addEventListener('resize', RegistrationContainer.performOrientationRequiredActions, true);
        document.getElementById('flexCheckDefault').addEventListener('change', RegistrationContainer.registeredNewUser)
        document.getElementById('legalLink').addEventListener('click', function (evt) {
            evt.preventDefault();
            RouterAssistant.redirect('legal-page')
        })
        document.querySelectorAll('registration-container .card').forEach(elem => {elem.addEventListener('click', function (evt) {
            if(!document.querySelector('#eula input').disabled){
                document.querySelectorAll('registration-container .card').forEach(item => {
                    item.classList.remove('registationOptionSelected')
                })
                evt.target.closest('registration-container .card').classList.add('registationOptionSelected')
            }
        })})
        // document.getElementById('legalLink').href = `//${window.location.host}/legal`
        RegistrationContainer.performOrientationRequiredActions();
    }

    static translateShit(){
        document.getElementById('registernH1').innerText = I18n.translateString('registrationPage.aQuestion')
        document.getElementById('registerH2').innerText = I18n.translateString('registrationPage.whatDescribesYouBest')
        document.getElementById('personalHeader').innerText = I18n.translateString('registrationPage.client')
        document.getElementById('personalDescription').innerText = I18n.translateString('registrationPage.clientDescription')
        document.getElementById('professionalHeader').innerText = I18n.translateString('registrationPage.provider')
        document.getElementById('professionalDescription').innerText = I18n.translateString('registrationPage.providerDescription')
        document.getElementById('bleEULA').innerHTML = I18n.translateString('registrationPage.iAcceptTheTermsAndConditions')
        document.getElementById('freePill').innerHTML = I18n.translateString('registrationPage.free')
        document.getElementById('threeMonthsFreePill').innerHTML = I18n.translateString('registrationPage.threeMonthsFree')
    }

    static performOrientationRequiredActions(){
        if (window.location.href.includes('registration')) {
            try {
                const welcomeRow2 = document.getElementById('welcomeRow2')
                const welcomeColumn2 = document.getElementById('welcomeColumn2')
                const h2 = document.getElementById('registerH2')
                const individual = document.getElementById('individual')
                const business = document.getElementById('business')
                if (window.innerHeight > window.innerWidth) {
                    welcomeRow2.style.marginTop = '25dvh'
                    welcomeRow2.classList.add('text-center')
                    welcomeRow2.classList.add('justify-content-center')
                    welcomeColumn2.classList.remove('col-7')
                    welcomeColumn2.classList.add('col-12')
                    h2.style.marginLeft = '0'
                    individual.classList.add('mx-auto')
                    business.classList.add('mx-auto')
                    // disclaimer.innerHTML = `<small>Αυτή η εφαρμογή προστατεύεται από reCAPTCHA και ισχύουν<br>η <a href="https://policies.google.com/privacy">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms">Όροι Παροχής Υπηρεσιών</a> της Google.</small>`
                } else {
                    welcomeRow2.style.marginTop = '30dvh'
                    welcomeRow2.classList.remove('justify-content-center')
                    welcomeColumn2.classList.add('col-7')
                    welcomeColumn2.classList.remove('col-12')
                    welcomeRow2.classList.remove('text-center')
                    h2.style.marginLeft = '3rem'
                    individual.classList.remove('mx-auto')
                    business.classList.remove('mx-auto')

                    // disclaimer.innerHTML = `<small>Αυτός ο ιστότοπος προστατεύεται από reCAPTCHA και ισχύουν<br>η <a href="https://policies.google.com/privacy">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms">Όροι Παροχής Υπηρεσιών</a> της Google.</small>`
                }
            } catch (e) {
                console.debug(e)
            }
        }
    }

    static registeredNewUser(){
        document.querySelector('#eula input').disabled = true;
        let userIsProvider = false;
        if (document.getElementById('business').classList.contains('registationOptionSelected')) {
            userIsProvider = true
        }
        document.getElementById('registernH1').style.opacity = '0'
        setTimeout(function () { try { document.getElementById('registerH2').style.opacity = '0' } catch (e) {} }, 500)
        setTimeout(function () { try { document.getElementById('individual').style.opacity = '0' } catch (e) {} }, 1000)
        setTimeout(function () { try { document.getElementById('business').style.opacity = '0' } catch (e) {} }, 1500)
        setTimeout(function () { try { document.getElementById('eula').style.opacity = '0' } catch (e) {} }, 2000)
        FirebaseAssistant.performNewUserActions(userIsProvider)
    }
}


RegistrationContainer.template = /*html*/ `
    <div class="flex-container">
        <div id="welcomeRow2" class="" style="margin-top: 30dvh;">
            <div id="welcomeColumn2" class="">
                <h1 id="registernH1" style="font-weight: 900" class="mb-4">Μια ερώτηση</h1>
                <h2 id="registerH2" style="
                    margin-left: 3rem;
                    ">τι σε περιγράφει καλύτερα;</h2>
                <div id="individual" class="card text-bg-dark registationOptionSelected" >
                    <div  class="card-header"><span id="personalHeader">Ιδιώτης</span><span id="freePill" class=" badge border rounded-pill bg-primary" style="position: absolute;right: 1rem;">free</span></div>
                    <div class="card-body">
                        <p id="personalDescription" class="card-text">Θα ασχολούμαι με καταχωρήσεις στα δικά μου χρονολόγια.</p>
                    </div>
                </div>
<!--                <div id="business" class="card text-bg-dark text-muted" >-->
                <div id="business" class="card text-bg-dark" >
                    <div  class="card-header"><span id="professionalHeader">Επαγγελματίας</span> <span id="threeMonthsFreePill" class=" badge border rounded-pill bg-primary" style="position: absolute;right: 1rem;">3 months free</span></div>
                    <div class="card-body">
                        <p id="professionalDescription" class="card-text">Θα δημιουργήσω καταχωρήσεις σε χρονολόγια τρίτων.</p>
                    </div>
<!--                    <span class="position-absolute   translate-middle badge  rounded-pill bg-primary border" style="top: 16%;left: 85%!important;">coming soon</span>-->
                </div>
            </div>
            <div class="col-6">
            </div>
        </div>
<div class="form-check" id="eula">

  <label class="form-check-label" for="">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">

   <small id="bleEULA"></small>
  </label>
</div>  
  </div>
`;

RegistrationContainer.reg('registration-container');
