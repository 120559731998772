import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n.js";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant.js";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";
import {PwaGuard} from "../pwa-guard/pwa-guard.js";
import {NavBar} from "../nav-bar/nav-bar.js";
import {MiscAssistant} from "../../utilities/misc-assistant/misc-assistant";


const css = `
    <style>
      #upsell:hover {
        cursor:pointer;
      }
      
      .stage {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 32px 0;
        margin: 0 -5%;
        overflow: hidden;
         }
      
      .dot-elastic {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #2f6cf0;
          color: #2f6cf0;
          animation: dot-elastic 1s infinite linear;
        }
        .dot-elastic::before, .dot-elastic::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }
        .dot-elastic::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #2f6cf0;
          color: #2f6cf0;
          animation: dot-elastic-before 1s infinite linear;
        }
        .dot-elastic::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #2f6cf0;
          color: #2f6cf0;
          animation: dot-elastic-after 1s infinite linear;
        }
        
        @keyframes dot-elastic-before {
          0% {
            transform: scale(1, 1);
          }
          25% {
            transform: scale(1, 1.5);
          }
          50% {
            transform: scale(1, 0.67);
          }
          75% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
        @keyframes dot-elastic {
          0% {
            transform: scale(1, 1);
          }
          25% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(1, 1.5);
          }
          75% {
            transform: scale(1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
        @keyframes dot-elastic-after {
          0% {
            transform: scale(1, 1);
          }
          25% {
            transform: scale(1, 1);
          }
          50% {
            transform: scale(1, 0.67);
          }
          75% {
            transform: scale(1, 1.5);
          }
          100% {
            transform: scale(1, 1);
          }
        }
    </style>
`

export class UpgradeContainer extends BaseComponent {

    init$ = {
        randomVariable: "asdasd",
        foo: (event) => {
            // console.debug(event)
        }
    };

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)

        document.getElementById('price').firstChild.nodeValue = I18n.translateString('upgradePage.price');
        document.getElementById('billingPeriod').innerText = I18n.translateString('upgradePage.billingPeriod');
        FirebaseAssistant.userIsProvider((isProvider)=> {
            if (isProvider) {
                document.getElementById('offering1').innerText = I18n.translateString('upgradePage.perk1prov');
                document.getElementById('offering2').innerText = I18n.translateString('upgradePage.perk2prov');
                document.getElementById('offering3').innerText = I18n.translateString('upgradePage.perk3prov');
                document.getElementById('callToUpgradeAction').innerText = I18n.translateString('upgradePage.callToActionProv');
                document.getElementById('callToUpgradeAction2').innerText = I18n.translateString('upgradePage.callToActionProv2');
                document.getElementById('callToUpgradeAction2').style.display = ''
                if (window.location.href.includes('clients-page')) document.getElementById('exitUpgrade').style.display = 'none'
            } else {
                document.getElementById('offering1').innerText = I18n.translateString('upgradePage.unlimitedTimelines');
                document.getElementById('offering2').innerText = I18n.translateString('upgradePage.shareYourTimelines');
                document.getElementById('offering3').innerText = I18n.translateString('upgradePage.accessSharedTimelines');
                document.getElementById('callToUpgradeAction').innerText = I18n.translateString('upgradePage.callToAction');
            }
        })

        document.getElementById('upsell').innerText = I18n.translateString('upgradePage.continue');

        FirebaseAssistant.helloWorldPayments(async function (url, sessionId) {
            document.getElementById('upsell').href = url;
            document.getElementById('upsell').classList.remove('disabled')
            document.getElementById('upsell').style.display = 'block';
            document.getElementById('dotContainer').style.display = 'none';
        })
        document.getElementById('exitUpgrade').addEventListener('click', function () {
            // RouterAssistant.redirect('profiles-page')
            history.back()
        })
        document.getElementById('upsell').addEventListener('click', function () {
            if (PwaGuard.accessIsThroughIphone() !== 0) { // doing only for iphone because closing the webView triggers the app to reload in android
                RouterAssistant.redirect('profiles-page')
                document.addEventListener("visibilitychange", function stripeGuard() {
                    if (document.visibilityState === 'visible') {
                        this.removeEventListener('visibilitychange', stripeGuard);
                        FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant) => {
                            if (FirebaseAssistant.userIsPremium())
                                NavBar.reloadCurrentPage()
                        })
                    }
                });
            }
        })
        UpgradeContainer.performSmallScreenActions()
        window.addEventListener('resize', UpgradeContainer.performSmallScreenActions)
    }

    static performSmallScreenActions(){
        if (document.querySelectorAll('upgrade-container').length !== 0) {
            const isVisible = MiscAssistant.isElementXPercentInViewport(document.getElementById('callToUpgradeAction'), 100)
            if (!isVisible){
                document.getElementById('upgradeContainerInnerContainer').style.transform = ' translate(-50%, -52%) scale(0.75)'
                document.getElementById('upgradeContainerInnerContainer').style.top = '48%'
            } else {
                document.getElementById('upgradeContainerInnerContainer').style.transform = ' translate(-50%, -55%) '
                document.getElementById('upgradeContainerInnerContainer').style.top = '45%'
            }
        }
    }
}

UpgradeContainer.template = /*html*/ `
<div id="upgradeContainerInnerContainer" style="
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -55%);
">
   <h1 style="
    color: white;
    font-weight: bolder;
    font-size: xxx-large;
    text-align: center;
" id="callToUpgradeAction" class="mb-5">Γίνε Pro!</h1>
 <h3 style="
 display: none;
    color: white;
    font-weight: bolder;
    text-align: center;
" id="callToUpgradeAction2" class="mb-5">Γίνε Pro!</h3>
<div class="card text-bg-light mx-auto mb-3" style="width: 20rem;/* max-width: fit-content; */">
  
  <div class="card-body mt-3" style="">
    <h2 id="price" class="card-title mb-5" style="font-size: xxx-large;text-align: center">0.99<small id="billingPeriod" style="font-size: x-large!important;text-transform: lowercase;"> € / μήνα</small>
    </h2>
    <div class="row card-text">
            <div class="col-1">        </div>


        <div class="col-1" style="margin-right: 0.5rem">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="margin-right: 0.5rem;fill: #0d6efd;transform: ;msFilter: ;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>
        </div>
        <div class="col-9">
            <p id="offering1">Απεριόριστα χρονολόγια!</p>
        </div>
                    <div class="col-1">        </div>

        <div class="col-1" style="margin-right: 0.5rem">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="margin-right: 0.5rem;fill: #0d6efd;transform: ;msFilter: ;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>
        </div>
        <div class="col-9">
            <p id="offering2">Κοινοποίηση χρονολογίων!</p>
        </div>
                    <div class="col-1">        </div>

        <div class="col-1" style="margin-right: 0.5rem">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="margin-right: 0.5rem;fill: #0d6efd;transform: ;msFilter: ;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>
        </div>
        <div class="col-9">
            <p id="offering3">Πρόσβαση σε κοινοποιημένα χρονολόγια!</p>
        </div>
    </div>
    <div id="dotContainer" class="stage mt-3"><div class="dot-elastic"></div></div>
    <a id="upsell" class="btn btn-primary mt-5 disabled" style="transition: all 1s ease-in-out;display: none">Συνέχεια</a>
 </div>
</div>
</div>
<button id="exitUpgrade" type="button" class="btn" style=" font-size: xxx-large; position: fixed; z-index: 5000; left: 1rem; top: 1rem; color: lavenderblush; "><i class="bx bx-arrow-back"></i></button>
`;

UpgradeContainer.reg('upgrade-container');
