import {BaseComponent} from "@symbiotejs/symbiote";
import {MiscAssistant} from "../../utilities/misc-assistant/misc-assistant.js";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant.js";
import {TimelineContainer} from "../timeline-container/timeline-container.js";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";
import {UserNotifier} from "../user-notifier/user-notifier.js";
import {TimelineShowcaser} from "../timeline-showcaser/timeline-showcaser.js";
import {I18n} from "../../utilities/i18n/i18n";
import {NavBar} from "../nav-bar/nav-bar";
import {LoadingSplasher} from "../loading-splasher/loading-splasher";
import {tour4} from "../../utilities/showcase-assistant/showcase-assistant";
import '../event-editor/assets/long-press-event.min'


const emptyClienteleImages = {}
emptyClienteleImages['emptyClienteleEl'] = require(`./assets/empty_clientele_el.png`)
emptyClienteleImages['emptyClienteleEn'] = require(`./assets/empty_clientele_en.png`)

const css = `
<style>

@keyframes pulse-dark {
0% {
    transform: scale(.95);
}

70% {
    transform: scale(1);
}
100% {
    transform: scale(.95);
}
}
    
    .activeUserMobile{border-top-left-radius: 15px; border-bottom-left-radius: 15px}
    .activeUserName{border-top-left-radius: 15px;}
    .activeUserCardinalities{border-top-right-radius: 15px;}
    .activeUserEventDates{border-top-right-radius: 15px;}
    .activeEventName{ border-bottom-left-radius: 15px}
    .activeEventCardinalities{ border-bottom-right-radius: 15px}
    .activeEventDates{border-bottom-right-radius: 15px}

    .clickableRow {
            position: relative;
     }
     .clickableRow:hover {
            cursor: pointer;
     }
     
     .subRowBackground {
        background-color:#344473 !important;
     }
     
     .clickableSubRow:hover {
            cursor: pointer;
            /*colour:blue*/
     }
     
     .removeClickiness:hover {
            pointer-events:none;
     }
     
     .expanded{
        display: table-row;
     }
     
     .collapsed{
        display: none;
     }
     
     .overflowHidden{
        white-space:nowrap;
        overflow: hidden;
     }
     
     #clienteleInspectTimelines {
        max-width: unset;
     }
     
     #clienteleFloatingInput:focus{
        box-shadow: none!important;
        border-bottom: 2px solid white!important;
     }
     
     #searchPlaceholderClientele {
        transform-origin: unset!important;
        animation: 2s infinite pulse-dark;
     }
     
     .pl-0 {
        padding-left:0
     }
     
     @media screen and (orientation: portrait) {
     
       .desktop-col{
            display: none;
        }
         clients-container table {
            /*font-size: 0.7rem;*/
            } 
         #clienteleNewEventButton, #clienteleInspectTimelines, .clienteleTimelineButton {
            /*font-size: 0.5rem;*/
            /*padding-left: 7px;*/
            }
            
           .clienteleTimelineButton {  
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 17ch;
            }
            
            .clienteleSharedTimelinePill {
                padding: 0.3rem!important;
            }
            
            #eye{
                top: 0.08rem;
            }
     }
     
     td .btn {
        padding-bottom: 0;
        padding-top: 0;
        max-width: 100%;
        overflow: hidden;
     }
     
     @media screen and (orientation: landscape) {
      
        
          td .btn.clienteleTimelineButton::after {
        filter: invert(1);
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
        margin: 0 3px 0 5px;
    }
    
      td .btn.clienteleTimelineButton:hover::after{
        filter: invert(0);
      }
      
      
            #eye{
                top: 0.15rem ;
          
            }
     }
     
    
      
      #clienteleNewEventButton:hover{
        background: #05b905!important;
      }
      #clienteleInspectTimelines:hover{
        background: #f066f5!important;
      }
</style>
`

export class ClientsContainer extends BaseComponent {
    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        RouterAssistant.checkForSingleContainer('clients-container', ClientsContainer.initiator)
    }

    static initiator() {
        window.currentTimeline = undefined
        ClientsContainer.translateShit()
        if (!FirebaseAssistant.userIsPremium()) {
            ClientsContainer.payTheMoneyBitch()
            return
        }
        TimelineShowcaser.initiate()
        window.addEventListener('resize', ClientsContainer.makeResizeActions)
        const searchInputElement = document.getElementById('clienteleFloatingInput');
        searchInputElement.addEventListener('focus', ClientsContainer.searchFocused)
        searchInputElement.addEventListener('focusout', ClientsContainer.searchNotFocused)
        searchInputElement.addEventListener('input', function () {
            MiscAssistant.debounce(ClientsContainer.search, 100)
        })
        searchInputElement.addEventListener('input', ClientsContainer.checkSearchWipeButtonVisibilityStatus)
        document.getElementById('searchWipeButton').addEventListener('click', ClientsContainer.clearSearch)
        document.addEventListener('keyup', ClientsContainer.escKeyWasPressed)
        ClientsContainer.populateTableFromData()
        ClientsContainer.checkIfSharedTimelineOwnersExistInClienteleAndIfNotAddThem() // async in order to not block execution
        ClientsContainer.makeResizeActions()
    }

    static checkSearchWipeButtonVisibilityStatus() {
        if (document.getElementById('clienteleFloatingInput').value.length === 0)
            document.getElementById('searchWipeButton').style.opacity = '0'
        else
            document.getElementById('searchWipeButton').style.opacity = '1'
    }

    static escKeyWasPressed(evt) {
        if (evt.key === "Escape") {
            const input = document.getElementById('clienteleFloatingInput')
            input.value = ''
            ClientsContainer.showEverything()
            input.blur()
            ClientsContainer.checkSearchWipeButtonVisibilityStatus()
            ClientsContainer.searchNotFocused()
        }
    }

    static async checkIfSharedTimelineOwnersExistInClienteleAndIfNotAddThem() {
        // this function also removes users that stopped sharing to the provider and the provider has no events in them
        let sharedTimelineOwners = []
        const usersThatNeedToBeAddedInClientele = []
        const usersThatNeedToBeRemovedFromClientele = []
        Object.values(window.sharedTimelines).forEach(sharedTimeline => {
            const allOwners = Object.values(sharedTimeline.timelineOwnershipHistory)
            sharedTimelineOwners.push(allOwners.pop().owner)
        })
        sharedTimelineOwners.forEach(sharedTimelineOwner => {
            if (!FirebaseAssistant.userInClientele(sharedTimelineOwner)) {
                usersThatNeedToBeAddedInClientele.push(sharedTimelineOwner)
                console.debug(`is not in providerData -> ${sharedTimelineOwner}`)
            }
        })
        Object.keys(window.providerData).forEach(client => {
            if (client === 'placeholder') return
            let eventCardinality = 0;
            console.debug(client)
            Object.keys(window.providerData[client].timelines).forEach(timeline => {
                Object.keys(window.providerData[client].timelines[timeline].events).forEach(event => {
                    if (window.providerData[client].timelines[timeline].events[event].isCreatedFromProvider === FirebaseAssistant.getUserId()){
                        eventCardinality += 1
                    }
                })
            })
            if ((!(client in sharedTimelineOwners)) && eventCardinality === 0 && window.providerData[client].createdFromProvider !== FirebaseAssistant.getUserId()) {
                console.debug(`will be removing user ${client} from clientele`)
                usersThatNeedToBeRemovedFromClientele.push(client)
            }
        })
        sharedTimelineOwners = [...new Set(sharedTimelineOwners)];
        if (sharedTimelineOwners.length !== 0 || usersThatNeedToBeRemovedFromClientele.length !== 0) {
            FirebaseAssistant.addOrRemoveUsersFromClientele(usersThatNeedToBeAddedInClientele, usersThatNeedToBeRemovedFromClientele, function () {
                if (usersThatNeedToBeAddedInClientele.length !== 0 || usersThatNeedToBeRemovedFromClientele.length !== 0)
                    FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => {
                        ClientsContainer.populateTableFromData()
                    })
            })
        }
    }

    static focusClient(userId) {
        try {
            const element = document.querySelector(`[data-userid="${userId}"]`);
            element.click();
            element.scrollIntoView({
                block: "end",
                behavior: "smooth"
            });
        } catch (e) {
            setTimeout(function () {
                ClientsContainer.focusClient(userId)
            }, 200)
        }
    }

    static focusEvent(userId, timelineId, EventId) { //todo
        try {
            const element = document.querySelector(`[data-timelineid="${timelineId}"][data-eventid="${EventId}"]`);
            element.click();
            element.scrollIntoView({
                block: "end",
                behavior: "smooth"
            });
        } catch (e) {
            setTimeout(function () {
                ClientsContainer.focusEvent(userId, timelineId, EventId)
            }, 200)
        }
    }

    static doneAddingUserEventToClientEventsList(customerId, timelineId, eventId) {
        if (window.location.href.includes("clients-page")) {
            NavBar.reloadCurrentPage()
            LoadingSplasher.deactivateSplasher();
            setTimeout(function () {
                ClientsContainer.focusEvent(customerId, timelineId, eventId)
            }, 2000)
        }
    }

    static searchFocused() {
        document.getElementById('searchPlaceholderClientele').style.display = 'none'
    }

    static searchNotFocused() {
        if (document.getElementById('clienteleFloatingInput').value.length === 0)
            document.getElementById('searchPlaceholderClientele').style.display = ''
    }

    static insertAppropriateBorderRadiuses(){
        try {
            const classes = ['activeUserMobile','activeUserName','activeUserCardinalities','activeUserEventDates','activeEventName','activeEventCardinalities','activeEventDates']
            document.querySelectorAll(`.${classes.join(',.')}`).forEach(el => {
                classes.forEach(function(className) {
                    if (el.classList.contains(className)) {
                        el.classList.remove(className);
                    }
                });
            })
            const userCells = document.querySelectorAll('td.bg-primary')
            const eventRows = document.querySelectorAll('.clickableSubRow.expanded')
            const lastEventRowCells = eventRows[eventRows.length - 1].querySelectorAll('td')
            if (window.innerHeight > window.innerWidth) {
                userCells[1].classList.add('activeUserName')
                userCells[2].classList.add('activeUserCardinalities')
                lastEventRowCells[1].classList.add('activeEventName')
                lastEventRowCells[2].classList.add('activeEventCardinalities')
            } else {
                userCells[0].classList.add('activeUserMobile')
                userCells[3].classList.add('activeUserEventDates')
                lastEventRowCells[1].classList.add('activeEventName')
                lastEventRowCells[3].classList.add('activeEventDates')

            }
        } catch (e) { // error here happens because the user may reclick the same row and this will rerun. But because it is a closing event the query selectors won't work!
           console.debug(e)
        }

    }

    static enableRowClickiness(el) {
        el.addEventListener('click', function (evt) {
            ClientsContainer.clearSearch(false)
            if (evt.target.id === 'clienteleNewEventButton' || evt.target.id === 'clienteleInspectTimelines') return
            const trElement = evt.target.closest('tr')
            const clickedRowWasAlreadyActivated = trElement.querySelector('td').classList.contains('bg-primary');
            document.querySelectorAll('td').forEach(element => {
                element.classList.remove('bg-primary')
                element.style.color = ''
                element.style.overflow = 'hidden'
            })
            document.querySelectorAll('.subrow.expanded').forEach(element => {
                element.classList.remove('expanded')
                element.classList.add('collapsed')
            })
            try {
                document.getElementById('clienteleNewEventButton').remove()
            } catch (e) {
            }
            try {
                document.getElementById('clienteleInspectTimelines').remove()
            } catch (e) {
            }

            const allTds = trElement.querySelectorAll('td')
            if (!clickedRowWasAlreadyActivated) {
                allTds[2].style.color = 'transparent'
                allTds[2].style.overflow = 'visible'
                allTds[3].style.color = 'transparent'
                trElement.querySelectorAll('td').forEach(element => {
                    element.classList.add('bg-primary')
                })
                let currentElement = trElement;
                let subRowCounter = 0;
                try {
                    while (currentElement.nextElementSibling.classList.contains('subrow')) {
                        currentElement = currentElement.nextElementSibling;
                        currentElement.classList.add('expanded')
                        currentElement.classList.remove('collapsed')
                        subRowCounter++;
                    }
                } catch (e) {
                    // this is for when the last customer row does not contain sub items (events)
                }
                let buttonText = `+ ${I18n.translateString('clientele.newEvent')}`
                if (window.innerHeight > window.innerWidth) buttonText = `+ ${I18n.translateString('clientele.new')}`
                allTds[2].insertAdjacentHTML('afterbegin', `<button type="button" id="clienteleNewEventButton" class="btn" style=" border: #2ddd2a; color: #393b39; background: #2ddd2a; ">${buttonText}</button><button type="button" id="clienteleInspectTimelines" class=" btn " style="margin-left: 1rem;border: lightpink;color: #393b39;background: lightpink;"><i id="eye" class="bx bxs-show" style="position: relative;"></i> ${I18n.translateString('clientele.Timelines')}</button>`)
                document.getElementById('clienteleNewEventButton').addEventListener('click', ClientsContainer.createNewCustomerEvent)
                document.getElementById('clienteleInspectTimelines').addEventListener('click', ClientsContainer.initiateTimelineInspector)
            }
            ClientsContainer.insertAppropriateBorderRadiuses()
            trElement.scrollIntoView()
        })
        el.addEventListener('long-press', clickEvent => {
            // const eventListElement = clickEvent.target.closest('li');
            // if (eventListElement !== null && eventListElement !== undefined) {
            //     TimelineContainer.archiveEvent(eventListElement)
            // }
            clickEvent.preventDefault()
            if (window.innerHeight > window.innerWidth)
                window.open(`tel:${clickEvent.target.closest('tr').querySelector('.desktop-col').innerText}`);
            }, false)
    }

    static initiateTimelineInspector(clickEvent) {
        const userId = clickEvent.target.closest('tr').getAttribute('data-userid')
        // console.debug(userId)
        TimelineShowcaser.activate(userId)
    }

    static createNewCustomerEvent(clickEvent) {
        TimelineShowcaser.activate(clickEvent.target.closest('tr').getAttribute('data-userid'), true)
    }

    static openExistingCustomerEvent(timelineId, eventId) {
        // const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(timelineId);
        // const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(timelineId);
        //
        // if (timelineId in window.kUser.timelines)
        //     return window.kUser.timelines[timelineId].isArchived
        // else if (FirebaseAssistant.timelineIsShared(timelineId))
        //     return window.sharedTimelines[timelineId].isArchived
        // else if (timelineIsInUserProviderData[0])
        //     return window.providerData[timelineIsInUserProviderData[1]].timelines[timelineId].isArchived
        // else if (timelineIsInUserProviderDataButProviderHasNoEntries[0])
        //     return window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[timelineId].isArchived

        TimelineContainer.changeTimeline(timelineId, () => {
            setTimeout(()=> { // unless this timeout exists the navbar does not hide when an event is opened from within clientele
                if (window.innerWidth > window.innerHeight) document.querySelector(`#horizontal li[data-eventid="${eventId}"]`).click()
                else document.querySelector(`#vertical li[data-eventid="${eventId}"]`).click()
            }, 100)
        })
    }

    static enableSubrowClickiness(el) {
        el.addEventListener('click', function (evt) {
            ClientsContainer.clearSearch(false)
            if (evt.target.classList.contains('clienteleTimelineButton')) return
            ClientsContainer.openExistingCustomerEvent(evt.target.closest('tr').getAttribute('data-timelineid'), evt.target.closest('tr').getAttribute('data-eventid'))
        })
    }

    static makeResizeActions() {
        if (window.location.href.includes('clients-page')) {
            const containerHeight = document.getElementsByTagName('clients-container')[0].clientHeight;
            const headerHeight = document.getElementById('clienteleHeader').clientHeight;
            const searchHeight = document.getElementById('clienteleSearch').clientHeight;
            document.getElementById('clienteleTable').style.height = `${containerHeight - headerHeight - searchHeight - 25}px`;
            document.querySelector('tbody').style.width = `${document.querySelector('thead').clientWidth}px`
            // document.querySelector('thead').style.width = `${document.querySelector('thead').clientWidth}px` // this is correct do not remove
            if (window.innerHeight > window.innerWidth) {
                document.getElementById('clienteleEventsTimelinesColumnHeader').classList.add('w-50')
                document.getElementById('clienteleEventsTimelinesColumnHeader').classList.remove('w-25')
                document.getElementById('clienteleNameColumnHeader').classList.add('w-50')
                document.getElementById('clienteleNameColumnHeader').classList.remove('w-25')
                document.getElementById('clienteleEventDateColumnHeader').style.borderTopLeftRadius = '0px'
                document.getElementById('clienteleEventsTimelinesColumnHeader').style.borderTopRightRadius = '15px'
                document.getElementById('clienteleNameColumnHeader').style.borderTopLeftRadius = '15px'
                document.getElementById('clientelePhoneColumnHeader').style.borderTopRightRadius = '0px'
            } else {
                document.getElementById('clienteleEventsTimelinesColumnHeader').classList.add('w-25')
                document.getElementById('clienteleEventsTimelinesColumnHeader').classList.remove('w-50')
                document.getElementById('clienteleNameColumnHeader').classList.add('w-25')
                document.getElementById('clienteleNameColumnHeader').classList.remove('w-50')
                document.getElementById('clienteleEventDateColumnHeader').style.borderTopRightRadius = '15px'
                document.getElementById('clienteleEventsTimelinesColumnHeader').style.borderTopRightRadius = '0px'
                document.getElementById('clienteleNameColumnHeader').style.borderTopLeftRadius = '0px'
                document.getElementById('clientelePhoneColumnHeader').style.borderTopLeftRadius = '15px'
            }
        }
    }

    static populationOfSearchMap() {
        let newClienteleSearchMap = {} // do not break the existing one, assign to window when done
        let counter = 0;
        document.querySelectorAll('tbody tr').forEach(element => {
            let parentRow;
            if (element.classList.contains('subrow')) {
                let currentElement = element.previousElementSibling;
                while (currentElement.classList.contains('subrow')) {
                    currentElement = currentElement.previousElementSibling;
                }
                parentRow = currentElement;
            } else {
                parentRow = element
            }
            const textOfAllChildren = Array.from(element.children, ({textContent}) => textContent.trim()).filter(Boolean).join('').replace(/ /g, '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            console.debug(textOfAllChildren)
            newClienteleSearchMap[counter] = {
                "selfRow": element,
                "parentRow": parentRow,
                "string": textOfAllChildren
            }
            counter++;
        })
        window.clienteleSearchMap = newClienteleSearchMap;
    }

    static search() {
        const userText = document.getElementById('clienteleFloatingInput').value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        if (userText === '')
            ClientsContainer.showEverything()
        else
            ClientsContainer.showSearchResults(userText)
    }

    static showEverything() {
        document.querySelectorAll('tbody tr').forEach(element => {
            element.style.display = ''
        })
    }

    static showSearchResults(string) {
        // console.debug(`activated for ${string}`)
        const stringToSearch = string.replace(/ /g, '').toLowerCase()
        for (const [key, value] of Object.entries(window.clienteleSearchMap)) {
            if (value['string'].includes(stringToSearch)) {
                value['selfRow'].style.display = ''
                value['parentRow'].style.display = ''
            } else {
                value['selfRow'].style.display = 'none'
            }
        }
    }

    static clearSearch(focus = true) {
        const input = document.getElementById('clienteleFloatingInput')
        input.value = ''
        ClientsContainer.showEverything()
        if (focus)
            input.focus()
        else
            ClientsContainer.searchNotFocused()
        ClientsContainer.checkSearchWipeButtonVisibilityStatus()
    }

    static timelineButtonWasPressed(evt) {
        const timelineId = evt.target.closest('tr').getAttribute('data-timelineid')
        if (FirebaseAssistant.timelineIsShared(timelineId)) {
            if (FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(timelineId)) setTimeout(function () {
                UserNotifier.notifyUser(I18n.translateString('userNotifier.ownerNoLongerProTitle'), I18n.translateString('userNotifier.ownerNoLongerProDescription'))
            }, 2000)
            TimelineContainer.changeTimeline(timelineId)
        } else { // timeline container auto-detects the cases
            TimelineContainer.changeTimeline(timelineId)
        }
    }

    static populateTableFromData(callback = function () {}) { // future to do:  because we do not always append stuff we need to properly do it (using timestamps!) for partially appended stuff
        if (!window.location.href.includes('clients-page')) return
        const tableBody = document.querySelector('tbody')
        let additionsWereMade = false
        for (const [index, [customerUid, customerData]] of Object.entries(Object.entries(window.providerData))) {
            if (customerUid === 'placeholder') continue;
            console.debug(`Populating table for clientUser ${customerUid}`)
            let eventCardinality = 0;
            let timelineCardinality = {}
            window.kUser.providerData[customerUid].forEach(event => { //this is like this because there are multiple occurrences of the same timeline
                if (event === 'placeholder') return
                if (!window.providerData[customerUid].timelines[event.split('/')[0]].events[event.split('/')[1]].isArchived) {
                    timelineCardinality[event.split('/')[0]] = '';
                    eventCardinality += 1;
                }
            })
            timelineCardinality = Object.keys(timelineCardinality).length
            let userWasAdded = false
            if (document.querySelectorAll(`[data-userid="${customerUid}"]`).length === 0) {
                const existingRows = document.querySelectorAll('.clickableRow')
                const customerName = (customerData.name !== undefined) ? customerData.name : I18n.translateString('clientele.noNameAvailable')
                const timelineStringToBeUsed = (timelineCardinality === 1) ? I18n.translateString('clientele.timeline') : I18n.translateString('clientele.timelines');
                const eventStringToBeUsed  = (eventCardinality === 1) ? I18n.translateString('clientele.event') : I18n.translateString('clientele.events');
                const overallStringToBeUsed = (eventCardinality === 0) ? `${eventCardinality} ${eventStringToBeUsed}` : `${eventCardinality} ${eventStringToBeUsed} ${I18n.translateString('clientele.in')} ${timelineCardinality} ${timelineStringToBeUsed}` ;
                if (existingRows.length === 0) {
                    tableBody.insertAdjacentHTML('beforeend', `<tr data-userid="${customerUid}" class="clickableRow"> <td class="w-25 overflowHidden desktop-col">${customerData.phoneNumber}</td> <td class="w-25 cusName overflowHidden">${customerName}</td> <td class="w-25 overflowHidden">${overallStringToBeUsed}</td> <td class="w-25 overflowHidden mostRecentEventDate desktop-col"></td> </tr>`)
                } else {
                    const array = document.querySelectorAll('.clickableRow');
                    const index = ClientsContainer.getSortedIndexForNames(array, customerData.name)
                    if (index === array.length) { // goes at the end
                        tableBody.insertAdjacentHTML('beforeend', `<tr data-userid="${customerUid}" class="clickableRow"> <td class="w-25 overflowHidden desktop-col">${customerData.phoneNumber}</td> <td class="w-25 cusName overflowHidden">${customerName}</td> <td class="w-25 overflowHidden">${overallStringToBeUsed}</td> <td class="w-25 overflowHidden mostRecentEventDate desktop-col"></td> </tr>`)
                    } else { // goes before the item or the reported index
                        array[index].insertAdjacentHTML('beforebegin', `<tr data-userid="${customerUid}" class="clickableRow"> <td class="w-25 overflowHidden desktop-col">${customerData.phoneNumber}</td> <td class="w-25 cusName overflowHidden">${customerName}</td> <td class="w-25 overflowHidden">${overallStringToBeUsed}</td> <td class="w-25 overflowHidden mostRecentEventDate desktop-col"></td> </tr>`)
                    }
                }
                userWasAdded = true
                additionsWereMade = true
            }
            const insertedUserElement = document.querySelector(`[data-userid="${customerUid}"]`)
            let mostRecentDate = new Date(1)
            let insertedTimestamps = []
            window.kUser.providerData[customerUid].forEach(eventReference => {
                if (eventReference === 'placeholder') return;
                const actualEvent = customerData.timelines[eventReference.split('/')[0]].events[eventReference.split('/')[1]]
                if (actualEvent !== undefined) {
                    if (actualEvent.isArchived) return
                    const lastVersionNumber = Object.keys(actualEvent.versions).pop()
                    const lastVersion = actualEvent.versions[lastVersionNumber]
                    // console.debug(lastVersion)
                    const timelineName = customerData.timelines[eventReference.split('/')[0]].timelineName
                    let eventCreationTimestamp
                    if (lastVersion.userAssignedTimestamp.seconds !== undefined)
                        eventCreationTimestamp = parseInt(lastVersion.userAssignedTimestamp.seconds) * 1000
                    else
                        eventCreationTimestamp = parseInt(lastVersion.userAssignedTimestamp._seconds) * 1000
                    let timestamp = window.flatpickr.formatDate(new Date(eventCreationTimestamp), "d/m/Y")
                    if (mostRecentDate < new Date(eventCreationTimestamp)) mostRecentDate = new Date(eventCreationTimestamp)
                    // let timestamp = window.flatpickr.formatDate(new Date(eventCreationTimestamp), "d/m/Y H:i")
                    let timelineButton;
                    if (FirebaseAssistant.timelineIsShared(eventReference.split('/')[0])) {
                        if (FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(eventReference.split('/')[0])) { // owner is not premium
                            // timelineButton = `<button type="button" class="clienteleTimelineButton pl-0 btn btn-outline-light"><span class="clienteleSharedTimelinePill p-2 bg-warning  rounded-circle" style="margin-right: 0.3rem;margin-left: 0.3rem; position: relative; display: inline-block; transform: translateY(20%) scale(0.5); "></span>${timelineName}</button>`
                            timelineButton = `<button type="button" class="clienteleTimelineButton btn btn-outline-light">${timelineName}</button>`
                        } else {
                            timelineButton = `<button type="button" class="clienteleTimelineButton pl-0 btn btn-outline-light"><span class="clienteleSharedTimelinePill p-2 bg-success  rounded-circle" style="margin-right: 0.3rem;margin-left: 0.3rem; position: relative; display: inline-block; transform: translateY(20%) scale(0.5); "></span>${timelineName}</button>`
                        }
                    } else {
                        timelineButton = `<button type="button" class="clienteleTimelineButton btn btn-outline-light">${timelineName}</button>`
                    }
                    if (document.querySelectorAll(`tr[data-eventid="${eventReference.split('/')[1]}"]`).length === 0) {
                        let elementToInsertAfter;
                        if (insertedTimestamps.length !== 0) {
                            const indexThatTheElementShouldGoTo = ClientsContainer.getSortedIndex(insertedTimestamps, eventCreationTimestamp)
                            if (indexThatTheElementShouldGoTo === 0) {
                                elementToInsertAfter = insertedUserElement;
                            } else { // careful in the line below there is a -1 in the index because we insert AFTER, meaning we need the previous element
                                elementToInsertAfter = document.querySelector(`[data-eventuserid="${customerUid}"][data-userassignedtimestamp="${insertedTimestamps[indexThatTheElementShouldGoTo - 1]}"]`)
                            }
                            insertedTimestamps.splice(indexThatTheElementShouldGoTo, 0, eventCreationTimestamp);
                        } else { // equals zero , no elements inserted yet so just insert it
                            elementToInsertAfter = insertedUserElement;
                            insertedTimestamps.push(eventCreationTimestamp)
                        }
                        const fileCardinalityElement = ((lastVersion.files.length -1) !== 0) ? `<i class="bx bx-file-blank" style="clip-path: polygon(-20% 0%, 100% 0%, 100% 100%, -20% 100%); box-shadow: -10px 0 25px 14px #344473; background: #344473; top: 50%; position: absolute; transform: translateY(-50%) scale(0.5); font-size: 2.5rem; right: 0;">${lastVersion.files.length - 1}</i>` : '' ;
                        elementToInsertAfter.insertAdjacentHTML('afterend', `<tr data-eventuserid="${customerUid}" data-timelineid="${eventReference.split('/')[0]}" data-eventid="${eventReference.split('/')[1]}" data-userassignedtimestamp="${eventCreationTimestamp}" class="subrow clickableSubRow collapsed"> <td class="w-25 overflowHidden removeClickiness desktop-col" style="border: none; overflow: hidden;"></td> <td class="w-25 overflowHidden subRowBackground" style="overflow: hidden;position: relative">${lastVersion.title}${fileCardinalityElement}</td> <td class="w-25 overflowHidden subRowBackground" style="overflow: hidden;">${timelineButton}</td> <td class="w-25 overflowHidden subRowBackground desktop-col" style="overflow: hidden;">${timestamp}</td> </tr>`)
                        document.querySelector(`[data-timelineid="${eventReference.split('/')[0]}"][data-eventid="${eventReference.split('/')[1]}"] button`).addEventListener('click', ClientsContainer.timelineButtonWasPressed)
                        ClientsContainer.enableSubrowClickiness(document.querySelector(`[data-timelineid="${eventReference.split('/')[0]}"][data-eventid="${eventReference.split('/')[1]}"]`))
                        additionsWereMade = true
                    } else {
                        console.debug(`event ${eventReference.split('/')[1]} already exists, ignoring...`)
                    }
                } else {
                    console.debug(`event ${eventReference.split('/')[1]} is undefined`)
                }
            })
            if (userWasAdded) {
                ClientsContainer.enableRowClickiness(document.querySelector(`[data-userid="${customerUid}"]`))
                if (mostRecentDate > new Date(1)) document.querySelector(`[data-userid="${customerUid}"] .mostRecentEventDate`).innerText = window.flatpickr.formatDate(mostRecentDate, "d/m/Y")
            }
        }
        if (additionsWereMade) ClientsContainer.populationOfSearchMap()
        // console.debug(`done with data population. additionas were made -> ${additionsWereMade}`)
        if (document.querySelectorAll('.clickableRow').length === 0) {
            ClientsContainer.changeEmptyClienteleSplasherState(true)
        } else {
            ClientsContainer.changeEmptyClienteleSplasherState(false)
        }
        setTimeout(tour4, 1000)
        callback()
    }

    static payTheMoneyBitch() {
        // if (window.innerHeight > window.innerWidth)
            document.getElementById('clienteleHeader').remove()
        document.getElementById('clienteleSearch').remove()
        document.getElementById('clienteleTable').remove()
        document.getElementById('emptyClienteleSplasher').remove()
        document.querySelector('clients-container').insertAdjacentHTML('beforeend', '<upgrade-container></upgrade-container>')
    }

    static getSortedIndexForNames(array, value) { //binary search insertion for strings
        let low = 0,
            high = array.length;

        while (low < high) {
            const mid = (low + high) >>> 1;
            if (array[mid].querySelector('.cusName').innerText.localeCompare(value) === -1) low = mid + 1;
            else high = mid;
        }
        return low;
    }

    static getSortedIndex(array, value) { //binary search insertion
        let low = 0,
            high = array.length;

        while (low < high) {
            const mid = (low + high) >>> 1;
            if (array[mid] > value) low = mid + 1;
            else high = mid;
        }
        return low;
    }

    static translateShit() {
        document.getElementById('clienteleHeader').innerText = I18n.translateString('clientele.clienteleHeader')
        document.getElementById('clienteleEventDateColumnHeader').innerText = I18n.translateString('clientele.clienteleEventDateColumnHeader')
        document.getElementById('clienteleEventsTimelinesColumnHeader').innerText = I18n.translateString('clientele.clienteleEventsTimelinesColumnHeader')
        document.getElementById('clienteleNameColumnHeader').innerText = I18n.translateString('clientele.clienteleNameColumnHeader')
        document.getElementById('clientelePhoneColumnHeader').innerText = I18n.translateString('clientele.clientelePhoneColumnHeader')
        document.getElementById('searchPlaceholderClientele').innerHTML = `<i class='bx bx-search nav__icon' style="position: relative;top: 5%;"></i> ${I18n.translateString('clientele.searchPlaceholderClientele')}`
        document.getElementById('emptyClienteleSplasherImg').src = emptyClienteleImages[I18n.translateString('clientele.emptyClienteleImage')]
    }

    static changeEmptyClienteleSplasherState(splasherOn) {
        if (splasherOn) {
            document.getElementById('emptyClienteleSplasher').style.display = ''
        } else {
            document.getElementById('emptyClienteleSplasher').style.display = 'none'
        }
    }
}

ClientsContainer.template = /*html*/ `                   
    <h1 id="clienteleHeader" style="
        position: relative;
        top: 1rem;
        left: 1rem;
        margin-bottom: 1rem;
        ">Clientele</h1>
    <div id="clienteleSearch" class="form-floating mb-3">
        <input class="form-control" id="clienteleFloatingInput" placeholder="name@example.com" style="
            margin-bottom: 3rem;
            margin: auto;
            width: 80%;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: none;
            text-align: center;
            " type="email">
        <button id="searchWipeButton" type="button" class="btn bg-transparent" style="color:white;opacity: 0; transition:opacity .5s ease-in-out; right: 10%;top: 35%;position: absolute"> <i class="bx bx-x "></i></button>
        <label id="searchPlaceholderClientele" for="clienteleFloatingInput" style="
            overflow: hidden;
            text-align: center;
            width: 100%;
            ">
            <i class='bx bx-search nav__icon' style="position: relative;top: 5%;"></i>
            Search
        </label>
    </div>
    <table id="clienteleTable" class="table table-dark " style="overflow-y: auto;overflow-x:hidden;display: block;position: relative; width: 98%;left: 50%;transform: translateX(-50%)">
        <thead class="sticky-top" style="display: inline-table;min-width: 100%;table-layout: fixed">
            <tr class="">
                <th id="clientelePhoneColumnHeader" scope="col" class="w-25 overflowHidden bg-light-subtle desktop-col">PHONE</th>
                <th id="clienteleNameColumnHeader" scope="col" class="w-25 overflowHidden bg-light-subtle">NAME</th>
                <th id="clienteleEventsTimelinesColumnHeader" scope="col" class="w-25 overflowHidden bg-light-subtle">EVENTS (TIMELINES)</th>
                <th id="clienteleEventDateColumnHeader" scope="col" class="w-25 overflowHidden bg-light-subtle desktop-col">EVENT DATE</th>
            </tr>
        </thead>
        <tbody style="display: inline-table;min-width: 100%;table-layout: fixed">
        </tbody>
    </table>
    <div id="emptyClienteleSplasher" style="display:none;top: 50%;width: min-content;left: 50%;height: 75dvh;position: absolute;transform: translate(-50%, -50%);">
        <img id="emptyClienteleSplasherImg" style="max-height: 100%">
    </div>
`;

ClientsContainer.reg('clients-container');
