import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import gsap from "gsap";
import '../../components/nav-bar/nav-bar.js'
import "../../components/user-notifier/user-notifier.js";
import '../../components/login-container/login-container.js'
import '../../components/success-container/success-container.js'
import '../../components/registration-container/registration-container.js'
import '../../components/calendar-container/calendar-container.js'
import '../../components/timeline-container/timeline-container.js'
import '../../components/clients-container/clients-container.js'
import '../../components/profile-container/profile-container.js'
import '../../components/legal-container/legal-container.js'
import '../../components/deletion-container/deletion-container.js'
import '../../components/upgrade-container/upgrade-container.js'
import '../../components/loading-splasher/loading-splasher.js'
import '../../components/action-verifier/action-verifier.js'
import {NavBar} from "../../components/nav-bar/nav-bar.js";
import {CameraOverlay} from "../../components/camera-overlay/camera-overlay.js";
import {UserNotifier} from "../../components/user-notifier/user-notifier.js";
import {FirebaseAssistant} from "../firebase-assistant/firebase-assistant.js";
import {LoadingSplasher} from "../../components/loading-splasher/loading-splasher.js";

const loginPage = require('../../pages/login-page.html')
const registrationPage = require('../../pages/registration-page.html')
const calendarPage = require('../../pages/calendar-page.html')
const timelinePage = require('../../pages/timeline-page.html')
const clientsPage = require('../../pages/clients-page.html')
const successPage = require('../../pages/success-page.html')
// const searchPage = require('../../pages/search-page.html')
const legalPage = require('../../pages/legal.html')
const deletionPage = require('../../pages/deletion-page.html')
const upgradePage = require('../../pages/upgrade-page.html')
const profilesPage = require('../../pages/profiles-page.html')

// this crap (foo) is to handle safari bs
const foo = `<div id="curtain" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: #212529; opacity: 0;display: none"></div>`;
const css = `<style id="container_height"> [data-barba="container"]:not(.login):not(.legal):not(.deletion){height:91dvh;display: block} </style>`
const cssIos = `<style id="container_height"> [data-barba="container"]:not(.login):not(.legal):not(.deletion){height:88dvh;display: block} </style>`
const css2 = `<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css">`

export class RouterAssistant {
    static initiate() {
        this.startBarba();
        UserNotifier.initiate()
    }

    static startBarba() {
        document.body.setAttribute("data-barba", "wrapper")
        document.head.insertAdjacentHTML("beforeend", css)
        document.head.insertAdjacentHTML("beforeend", css2)
        document.body.insertAdjacentHTML("afterbegin", `<main data-barba="container" data-barba-namespace="splash-page"></main>`)
        document.body.insertAdjacentHTML("beforeend", foo)
        document.body.insertAdjacentHTML("beforeend", '<loading-splasher></loading-splasher>')
        document.body.insertAdjacentHTML("beforeend", '<action-verifier></action-verifier>')
        barba.use(barbaPrefetch);
        barba.init({
            prevent: ({event, href}) => {
                if (href.includes('#')) {
                    event.preventDefault()
                    document.getElementById(href.split('#').pop()).scrollIntoView()
                }
                return true
            } , // is a same page anchor link -> mostly for the legal container
            prefetchIgnore: true,
            preventRunning: true,
            transitions: [{
                name: 'opacity-transition',
                before(data) {
                    NavBar.disableNavigation()
                },
                leave(data) {

                    RouterAssistant.activateCurtain(1) // this crap is to handle safari bs
                    return gsap.to(data.current.container, {
                        opacity: 0,
                        display: 'none'
                    });
                },
                enter(data) {
                    RouterAssistant.deactivateCurtain() // this crap is to handle safari bs
                    NavBar.checkNavBarState();
                    return gsap.from(data.next.container, {
                        opacity: 0
                    });
                },
                after(data) {
                    NavBar.enableNavigation()
                    document.title = 'BLE'
                }
            },
            {
                name: 'self',
                before(data) {
                    NavBar.disableNavigation()
                },
                leave(data) {
                    RouterAssistant.activateCurtain(1) // this crap is to handle safari bs
                    return gsap.to(data.current.container, {
                        opacity: 0,
                        display: 'none'
                    });
                },
                enter(data) {
                    RouterAssistant.deactivateCurtain() // this crap is to handle safari bs
                    NavBar.checkNavBarState();
                    return gsap.from(data.next.container, {
                        opacity: 0
                    });
                },
                after(data) {
                    NavBar.enableNavigation()
                    document.title = 'BLE'
                }
            }]
        });
    }

    static authStateChangedRerouting(loggedInState, userIsRegistered, userIsProvider) {

        // if you add or remove sth from here remember to also remove it from index.html script
        if (window.location.href.includes('uploader')) {
            return CameraOverlay.initiate();
        }
        if (window.location.href.includes('returnedFromStripe')) { //should be here but not in index.html
            return barba.go(successPage);
        }
        if (window.location.href.includes('profiles-page') && loggedInState) {
            return barba.go(profilesPage);
        }
        if (window.location.href.includes('legal')) {
            return barba.go(legalPage);
        }
        if (window.location.href.includes('deletion')) {
            return barba.go(deletionPage);
        }
        if (loggedInState) {
            if (userIsRegistered) {
                if (userIsProvider) {
                    barba.go(clientsPage).then(r => {
                    })
                } else {
                    barba.go(timelinePage).then(r => {
                    })
                }
            } else {
                barba.go(registrationPage).then(r => {
                })
            }
        } else {
            barba.go(loginPage).then(r => {
            })
        }
    }

    static redirect(page, callback = ()=> {}) {
        try {
            switch (page) {
                case "login-page":
                    barba.go(loginPage).then(callback())
                    break;
                case "calendar-page":
                    barba.go(calendarPage).then(callback())
                    break;
                case "clients-page":
                    barba.go(clientsPage).then(callback())
                    break;
                // case "search-page":
                //     barba.go(searchPage).then(r => {
                //     })
                //     break;
                case "timeline-page":
                    barba.go(timelinePage).then(callback())
                    break;
                case "profiles-page":
                    barba.go(profilesPage).then(callback())
                    break;
                case "legal-page":
                    barba.go(legalPage).then(callback())
                    break;
                case "upgrade-page":
                    barba.go(upgradePage).then(callback())
                    break;
                case "deletion-page":
                    barba.go(deletionPage).then(callback())
                    break;
                case "success-page":
                    barba.go(successPage).then(callback())
                    break;
                default:
                    throw "page requested does not exist in router-assistant!"
            }
        } catch (e) {
            console.debug(e)
        }
    }

    static activateCurtain(activationOpacity, zIndexOverride = 1054) {
        document.getElementById('curtain').style.zIndex = `${zIndexOverride}`;
        FirebaseAssistant.removeDisclaimer();
        gsap.to(document.getElementById('curtain'), {
            opacity: activationOpacity,
            display: '',
            background: '#212529'
        });
    }

    static activateBlueCurtain(activationOpacity, zIndexOverride = 1054) {
        document.getElementById('curtain').style.zIndex = `${zIndexOverride}`;
        FirebaseAssistant.removeDisclaimer();
        gsap.to(document.getElementById('curtain'), {
            opacity: activationOpacity,
            display: '',
            background: '#0171e7'
        });
    }

    static deactivateCurtain() {
        LoadingSplasher.deactivateSplasher();
        gsap.to(document.getElementById('curtain'), {
            opacity: 0,
            display: 'none',
            background: '#212529'
        });
    }

    static transitionToWhiteForStripeRedirect() {
        document.getElementById('curtain').style.background = 'white'
        RouterAssistant.activateCurtain(1)
    }

    static checkForSingleContainer(element, callback) {
        if (document.querySelectorAll(element).length === 2) {
            setTimeout(function () {
                RouterAssistant.checkForSingleContainer(element, callback)
            }, 10)
        } else {
            callback()
        }
    }

    static switchToIosContainerHeight() {
        document.getElementById('container_height').remove()
        document.head.insertAdjacentHTML("beforeend", cssIos)
        // TimelineContainer.scrollTimelineToEnd()
    }
}
