export class GeofencingGuard {
    static initiate() {
        try {
            fetch('https://api.country.is/', {
                method: "GET",
                cache: "no-cache",
                headers: { "Content-Type": "application/json"},
                redirect: "follow",
                referrerPolicy: "no-referrer",
            }).then(x => x.json())
                .then(data => {
                    window.geoFencingGuard = data.country
                })
        } catch (e) {
            window.geoFencingGuard = "GR"
        }
    }

    static getUserCountry(callback){
        if (window.geoFencingGuard === undefined)
            setTimeout(()=> {GeofencingGuard.getUserCountry(callback)}, 50)
        else
            callback(window.geoFencingGuard)
    }
}
