import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";
import {TimelineContainer} from "../timeline-container/timeline-container";
import {MiscAssistant} from "../../utilities/misc-assistant/misc-assistant";
import {LoadingSplasher} from "../loading-splasher/loading-splasher";
import {ActionVerifier} from "../action-verifier/action-verifier";
import {NavBar} from "../nav-bar/nav-bar";
import {PwaGuard} from "../pwa-guard/pwa-guard";
import {UserRenamer} from "../self-rename/self-renamer";
import {ShareButton} from "../share-button/share-button";

const avatar = require('./assets/test.png')

const css = `
    <style>
    
    .timelineOptionName{
    text-align: left;
    }
    
        profile-container{
            overflow: hidden;
        }
    
        .ratio-1x1, #userFullName:hover{
            cursor: pointer;
        }
        
        #timelineSelector a:hover{
        cursor: pointer;
        }
        
        #userPortalLink {
            color: rgb(173, 181, 189);
        }
    
        .list-group-item i:hover{
            color: #0a58ca;
            transform:scale(1.2);
        }
        
        .bxs-trash-alt:hover{
            cursor: pointer;
            color: #0d6efd;
            transform:scale(1.2);
        }
        
        .bxs-credit-card:hover{
            cursor: pointer;
            color: #0d6efd;
            transform:scale(1.2);
        }
        
        #shareTimelineToUserInput:hover:not(:focus) {
         background: #198754;
         color: #fff!important;
         cursor: pointer;
        }
         
        #labelOfShareTimelineToUserInput:hover:not(:focus) {
         background: #198754;
        color: #fff!important;
         cursor: pointer;
        }
        
        #shareTimelineToUserInputContainer:hover:not(:focus) {
        color: #fff!important;
        }
        
        .valid{
            border-color: var(--bs-form-valid-border-color);
            padding-right: calc(1.5em + 0.75rem);
            background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e);
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
        
        .sharedProfilePill{
            right: -1.2rem!important;
            }
        /*@media (orientation: landscape) {*/
        /*    .sharedProfilePill{*/
        /*        right: 0!important*/
        /*    }*/
        /*}*/
        /*@media (orientation: portrait) {*/
        /*    .sharedProfilePill{*/
        /*        right: -8%!important*/
        /*    }*/
        /*}*/

    </style>
`

export class ProfileContainer extends BaseComponent {
    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        RouterAssistant.checkForSingleContainer('profile-container', ProfileContainer.initiator)
    }

    static initiator() {
        // if (ProfileContainer.userIsComingBackFromStripeWithinNative() && window.wentToStripe !== undefined) history.back();
        FirebaseAssistant.setupLogoutFunctionality('logout')
        // document.getElementById('userFullName').innerText = window.kUser.name
        document.getElementById('userFullName').innerText = FirebaseAssistant.getUsersDisplayName() !== null ? FirebaseAssistant.getUsersDisplayName() : window.auth.currentUser.phoneNumber;
        // document.getElementById('phoneNumber').innerText = window.auth.currentUser.phoneNumber
        window.addEventListener('resize', ProfileContainer.performOrientationRequiredActions, true);

        FirebaseAssistant.userIsProvider(function (isProvider) {
            ProfileContainer.showcaseAvailableTimelines(isProvider);
            ProfileContainer.showcaseAvailableSharedTimelines(isProvider);
            document.getElementById('newTimelineButton').addEventListener('click', ProfileContainer.newTimelinePopup)
            document.getElementById('create').addEventListener('click', ProfileContainer.saveButtonWasPressed)
            document.getElementById('timelineName').addEventListener('input', ProfileContainer.checkIfSaveButtonShouldBeEnabled)
            document.getElementById('creationModal').addEventListener('shown.bs.modal', () => {
                document.querySelector('#creationModal input').focus();
            })
            document.getElementById('creationModal').addEventListener('hidden.bs.modal', () => {
                document.querySelector('#creationModal input').value = '';
                ProfileContainer.checkIfSaveButtonShouldBeEnabled()
            })
            document.getElementById('editorModal').addEventListener('hidden.bs.modal', () => {
                document.querySelectorAll('#editorModal .userWithAccess').forEach(el => {
                    el.remove()
                })
                window.initialTimelineEditorId = undefined
                // ProfileContainer.checkIfEditConfirmButtonShouldChangeState()
            })
            document.getElementById('editorModal').addEventListener('shown.bs.modal', ProfileContainer.saveInitialTimelineEditorState)
            window.timelineCreationModal = new window.bootstrap.Modal(document.getElementById('creationModal'))
            window.timelineEditorModal = new window.bootstrap.Modal(document.getElementById('editorModal'))
            document.getElementById('editorModal').addEventListener('hidden.bs.modal', () => {
                FirebaseAssistant.userIsProvider(isProv => {
                    NavBar.activateNavBar(isProv)
                    ShareButton.showButton()
                })
            })
            document.getElementById('timelineSelector').addEventListener('click', ProfileContainer.timelineWasSelected)
            document.getElementById('editedTimelineName').addEventListener('input', ProfileContainer.checkIfEditConfirmButtonShouldChangeState)
            document.getElementById('usersWithAccessContainer').addEventListener('click', ProfileContainer.checkIfUserHavingAccessShouldBeRemoved)
            document.getElementById('shareTimelineButton').addEventListener('click', ProfileContainer.showTimelineSharerModal)
            document.getElementById('confirmTimelineSharerModal').addEventListener('click', ProfileContainer.newSharedUserHasBeenConfirmed)
            document.getElementById('timelineSharerInput').addEventListener('input', ProfileContainer.checkNewSharedUserInput)
            document.getElementById('cancelTimelineSharerModal').addEventListener('click', ProfileContainer.closeTimelineSharerModal)
            document.getElementById('confirmEdit').addEventListener('click', ProfileContainer.confirmTimelineEditWasPressed)
            if (FirebaseAssistant.userIsPremium()) {
                FirebaseAssistant.userIsProvider((isProvider)=>{
                    if (isProvider){
                        // document.getElementById('proBadge').style.display = ''
                    } else if (localStorage.getItem("guidedTours_v1.3") === 'hasBeenShown'){
                        document.getElementById('proBadge').style.display = ''
                    }
                })
            } else
                document.getElementById('newTimelineButton').classList.add('text-muted')

            // document.getElementById('userPortalLink').addEventListener('click', ProfileContainer.showStripeRedirectSplasher);
            document.getElementById('userPortalLink').addEventListener('click', function () {
                // window.wentToStripe = true
            });
            ProfileContainer.getStripeUserPortalLink(function (url) {
                try {
                    document.getElementById('userPortalLink').setAttribute('href', url)
                    document.getElementById('userPortalLink').style.display = '';
                } catch (e) {
                    console.debug(e)
                }
            })

            document.getElementById('userDeletionLink').addEventListener('click', function () {
                RouterAssistant.redirect('deletion-page')
            });
            document.getElementById('contactus').href = `mailto:pastamatakis@gmail.com?Subject=BLE: Επικοινωνία χρήστη +30 ${FirebaseAssistant.getUserPhoneNumber()}`

            document.getElementById('renameTimelineLabel').innerText = I18n.translateString('profilePage.editTimeline')
            document.getElementById('confirmEdit').innerText = I18n.translateString('profilePage.save')
            document.getElementById('cancelEdit').innerText = I18n.translateString('profilePage.cancel')
            document.getElementById('timelineSharerInputPlaceholder').innerText = I18n.translateString('profilePage.shareToNewUser')
            document.getElementById('sharedListTitle').innerText = I18n.translateString('profilePage.usersWithAccess')
            document.getElementById('editedTimelineNamePlaceholder').innerText = I18n.translateString('profilePage.timelineName')
            document.getElementById('renameTimelineLabel').innerText = I18n.translateString('profilePage.editTimeline')
            document.getElementById('create').innerText = I18n.translateString('profilePage.create')
            document.getElementById('cancelCreation').innerText = I18n.translateString('profilePage.cancel')
            document.getElementById('newTimelineName').innerText = I18n.translateString('profilePage.newTimelineName')
            document.getElementById('exampleModalLabel').innerText = I18n.translateString('profilePage.create')
            document.getElementById('contactus').innerText = I18n.translateString('profilePage.didYouFaceAnyIssues')
            document.getElementById('logout').innerText = I18n.translateString('profilePage.logout')
            document.getElementById('newTimelineButton').innerText = I18n.translateString('profilePage.createNewTimeline')
            document.getElementById('closeEdit').innerText = I18n.translateString('profilePage.close')
            document.getElementById('confirmTimelineSharerModal').innerText = I18n.translateString('profilePage.continue')
            document.getElementById('cancelTimelineSharerModal').innerText = I18n.translateString('profilePage.cancel')
            document.getElementById('archiveTimelineButtonText').innerText = I18n.translateString('profilePage.archiveTimelineQuestion')
            document.getElementById('shareTimelineButtonText').innerText = I18n.translateString('profilePage.shareTimeline')

            document.getElementById('archiveTimelineButton').addEventListener('click', function () {
                ActionVerifier.enableActionVerifier(`${I18n.translateString('profilePage.archiveTimelineQuestion')} "${window.initialTimelineEditorName}"?`, I18n.translateString('profilePage.archive'), function () {
                    TimelineContainer.showSavingChangesBanner()
                    window.kUser.timelines[window.initialTimelineEditorId].isArchived = true
                    FirebaseAssistant.updateUserFirebaseData(function () {
                        FirebaseAssistant.notifyPotentialSharedUsersOfTimelineUpdates(window.initialTimelineEditorId, FirebaseAssistant.getCurrentTimestamp())
                        window.timelineEditorModal.hide()
                        NavBar.reloadCurrentPage()
                        TimelineContainer.hideSavingChangesBanner()
                    })
                })
            })
            ProfileContainer.performOrientationRequiredActions()
            UserRenamer.load()
            document.querySelectorAll('.ratio-1x1, #userFullName').forEach(el => {
                UserRenamer.attachToElement(el)
            })
            UserRenamer.promptUserToSpecifyNameIfUndefined()
        });
        window.timelineSharerModal = new window.bootstrap.Modal(document.getElementById('timelineSharerModal'))
    }

    static showTimelineSharerModal() {
        if (FirebaseAssistant.userIsPremium()) {
            document.getElementById('timelineSharerInput').value = '+3069'
            RouterAssistant.activateCurtain(0.8, 1058)
            window.timelineSharerModal.show()
            setTimeout(()=>{document.getElementById('timelineSharerInput').focus()}, 500)
        } else {
            window.timelineEditorModal.hide()
            RouterAssistant.redirect('upgrade-page')
        }
    }

    static closeTimelineSharerModal() {
        document.getElementById('timelineSharerInput').value = '+3069'
        RouterAssistant.deactivateCurtain()
        window.timelineSharerModal.hide()
    }
    static performOrientationRequiredActions() {
        setTimeout(()=> { // because the navbar may not have activates yet -> returning from the upgrade page for ex
            if (document.querySelectorAll('profile-container').length > 0) {
                const remToPixels = 10 * parseFloat(getComputedStyle(document.documentElement).fontSize)
                const maxHeight = window.innerHeight - remToPixels - document.getElementById('nav-menu').offsetHeight -document.getElementById('logoutButtonsRow').offsetHeight-document.getElementById('userDepictorRow').offsetHeight
                document.getElementById('timelineSelector').style.maxHeight = `${maxHeight}px`
            }
        }, 50)

    }

    static userIsComingBackFromStripeWithinNative() {
        return !!PwaGuard.accessIsThroughMobile();
    }

    static checkIfUserHavingAccessShouldBeRemoved(e) {
        if (e.target.tagName === 'I') {
            e.target.closest('a').remove()
            ProfileContainer.checkIfEditConfirmButtonShouldChangeState()
        }
    }

    static showStripeRedirectSplasher() {
        LoadingSplasher.activateSplasher(I18n.translateString('splashScreenMessages.stripePending'))
        // FirebaseAssistant.getStripeCustomerPortalLink(function (result) {
        //     if (result !== undefined){
        //         window.location.assign(result)
        //     }
        // })
    }

    static getStripeUserPortalLink(callback) {
        FirebaseAssistant.getStripeCustomerPortalLink(function (result) {
            if (result !== undefined) {
                callback(result)
            }
        })
    }

    static saveInitialTimelineEditorState() {
        window.initialTimelineEditorName = document.getElementById('editedTimelineName').value
        window.initialTimelineEditorAccessBook = []
        document.querySelectorAll(".userWithAccess").forEach(el => {
            window.initialTimelineEditorAccessBook.push(el.getAttribute('data-uid'))
        })
        ProfileContainer.checkIfEditConfirmButtonShouldChangeState()
    }

    static scrollActiveTimelineIntoView(){
        try{
            document.querySelector('.list-group-item.active').scrollIntoView({
                block: "start",
                behavior: "smooth"
            });
        } catch(e){}
    }

    static timelineWasSelected(e) {
        const aTag = e.target.closest('a')
        if (aTag.id !== 'newTimelineButton') {
            if (aTag.getAttribute('data-timelineId') !== window.kUser.initialTimeline && !FirebaseAssistant.userIsPremium()) {
                RouterAssistant.redirect('upgrade-page')
            } else {
                if (e.target.classList.contains('callButton')){
                    ProfileContainer.performCallActions(e.target.getAttribute('data-numbertocall'))
                } else if (e.target.tagName === 'I') {
                    ProfileContainer.activateEditorModal(aTag.getAttribute('data-timelineId'))
                } else if (FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(aTag.getAttribute('data-timelineId'))) {
                    alert(I18n.translateString('profilePage.userNoLongerPro')) //todo remove this entry from the translations?
                    // RouterAssistant.redirect('upgrade-page') // todo , take him for a payment!
                } else if (aTag.id !== 'newTimelineButton') { // && aTag.getAttribute('data-timelineId') !== window.currentTimeline
                    TimelineContainer.changeTimeline(aTag.getAttribute('data-timelineId'))
                }
            }
        }
    }

    static performCallActions(number) {
        if (window.innerHeight > window.innerWidth)
            window.open(`tel:${number}`);
        else
            alert(number)
    }

    static activateEditorModal(timelineId) {
        document.getElementById('editedTimelineName').value = document.querySelector(`[data-timelineid="${timelineId}"] .timelineOptionName`).innerText
        window.initialTimelineEditorId = timelineId
        window.kUser.timelines[window.initialTimelineEditorId].hasAccessToTimeline.forEach(sharedUser => {
            if (sharedUser !== FirebaseAssistant.getUserId())
                document.getElementById('usersWithAccessContainer').insertAdjacentHTML('beforeend', `<a  class="list-group-item userWithAccess" data-uid="${sharedUser}">${I18n.translateString('profilePage.loading')}<i class="bx bx-x" style="font-size:x-large;position: absolute;top: 20%;right: 5%;"></i></a>`)
        })
        FirebaseAssistant.getUsernamesFromUids(window.kUser.timelines[window.initialTimelineEditorId].hasAccessToTimeline, function (r) {
            document.querySelectorAll('.userWithAccess').forEach(user => {
                if (r.users[user.getAttribute('data-uid')] !== '') {
                    user.firstChild.nodeValue = r.users[user.getAttribute('data-uid')]
                }
            })
        })
        if (timelineId === window.kUser.initialTimeline) document.getElementById('archiveTimelineButton').style.display = 'none'
        else document.getElementById('archiveTimelineButton').style.display = ''
        ProfileContainer.checkIfSharedTitleShouldBeShown()
        window.timelineEditorModal.show()
        NavBar.deactivateNavBar()
        ShareButton.hideButton()
    }

    static checkIfSharedTitleShouldBeShown() {
        const people = document.querySelectorAll('.userWithAccess')
        if (people.length !== 0) {
            document.getElementById('sharedListTitle').style.display = ''
        } else {
            document.getElementById('sharedListTitle').style.display = 'none'
        }
    }

    static checkNewSharedUserInput() {
        const selfNumber = FirebaseAssistant.getUserPhoneNumber()
        const textWithoutSpaces = document.getElementById('timelineSharerInput').value.replace(/\s+/g, '');
        document.getElementById('timelineSharerInput').value = textWithoutSpaces
        const text = textWithoutSpaces
        const beginning = text.substring(0, 5)
        const isNum = /^\d+$/.test(text.substring(1, 99));
        if (text.length === 13 && beginning === '+3069' && isNum && text !== selfNumber) { //todo change to be able to be made easy for i18n later on
            document.getElementById('confirmTimelineSharerModal').removeAttribute('disabled')
        } else {
            document.getElementById('confirmTimelineSharerModal').setAttribute('disabled', '')
        }
    }

    static newSharedUserHasBeenConfirmed() {
        const text = document.getElementById('timelineSharerInput').value
        ProfileContainer.closeTimelineSharerModal()
        ProfileContainer.displaySharedUser(text, true)
    }

    static showcaseAvailableTimelines(userIsProvider) {
        const sortedMap = []
        const timelineSelector = document.getElementById('timelineSelector')
        const newTimelineButton = document.getElementById('newTimelineButton')
        Object.keys(window.kUser.timelines).forEach(timeline => {
            if (window.kUser.timelines[timeline].isArchived === true) return; // this is how to 'continue' a forEach loop
            const lastOwnership = Object.keys(window.kUser.timelines[timeline].timelineOwnershipHistory).pop()
            let numeric;
            if (window.kUser.timelines[timeline].timelineOwnershipHistory[lastOwnership].ownershipStartedOn.timestamp.seconds !== undefined) {
                numeric = parseInt(window.kUser.timelines[timeline].timelineOwnershipHistory[lastOwnership].ownershipStartedOn.timestamp.seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
            } else {
                numeric = parseInt(window.kUser.timelines[timeline].timelineOwnershipHistory[lastOwnership].ownershipStartedOn.timestamp._seconds) * 1000 //we sort and show based on the creation timestamp (and not the last edited)
            }
            // console.debug(`numeric: ${numeric}`)

            if (sortedMap.length === 0) {
                ProfileContainer.insertTimelineOption(userIsProvider, timeline, 'afterbegin', timelineSelector, false, null, null)
                sortedMap.push([numeric, timeline])
            } else {
                let index = 0
                while (true) {
                    if ((sortedMap[index][0] < numeric)) {
                        if ((index + 1) === sortedMap.length) {
                            ProfileContainer.insertTimelineOption(userIsProvider, timeline, 'beforebegin', newTimelineButton, false, null, null)
                            sortedMap.push([numeric, timeline])
                            break
                        } else {
                            index += 1;
                        }
                    } else {
                        const RightHandSideNeighbourEvent = sortedMap[index][1]
                        const element = document.querySelector(`[data-timelineId="${RightHandSideNeighbourEvent}"]`)
                        ProfileContainer.insertTimelineOption(userIsProvider, timeline, 'beforebegin', element, false, null, null)
                        sortedMap.splice(index, 0, [numeric, timeline])
                        break
                    }
                }
            }
        })
    }

    static showcaseAvailableSharedTimelines(isProvider) {
            if (!isProvider) {
                const tempContainer = document.createElement('div')
                const newTimelineButton = document.getElementById('newTimelineButton')
                const uidsToGetNamesFor= [];

                Object.keys(window.sharedTimelines).forEach(timeline => {
                    const ownershipEvents = Object.keys(sharedTimelines[timeline].timelineOwnershipHistory)
                    const uidOfOwner = sharedTimelines[timeline].timelineOwnershipHistory[ownershipEvents.length-1].owner
                    if (!uidsToGetNamesFor.includes(uidOfOwner)) uidsToGetNamesFor.push(uidOfOwner);
                })

                FirebaseAssistant.getUsernamesFromUids(uidsToGetNamesFor, (response)=> {
                    Object.keys(window.sharedTimelines).forEach(timeline => {
                        if (window.sharedTimelines[timeline].isArchived === true) return; // this is how to 'continue' a forEach loop
                        const lastOwnership = Object.keys(window.sharedTimelines[timeline].timelineOwnershipHistory).pop()
                        const ownerName = response.users[window.sharedTimelines[timeline].timelineOwnershipHistory[lastOwnership].owner]
                        const ownerPhone = response.numbers[window.sharedTimelines[timeline].timelineOwnershipHistory[lastOwnership].owner]

                        const array = tempContainer.querySelectorAll('.sharedTimelineListItem');
                        const index = ProfileContainer.getSortedIndexForNames(array, ownerName)
                        if (index === array.length) { // goes at the end
                            ProfileContainer.insertTimelineOption(isProvider, timeline, 'beforeend', tempContainer, true, ownerName, ownerPhone)
                        } else { // goes before the item or the reported index
                            ProfileContainer.insertTimelineOption(isProvider, timeline, 'beforebegin', array[index], true, ownerName, ownerPhone)
                        }
                    })
                    newTimelineButton.insertAdjacentHTML('beforebegin', tempContainer.innerHTML)
                    ProfileContainer.scrollActiveTimelineIntoView()
                })

            }
    }

    static checkIfEditConfirmButtonShouldChangeState() {
        //todo need another check here on whether all the new shared users have data id for their uid here (meaing the firebase call has returned)
        const currentTimelineName = document.getElementById('editedTimelineName').value
        let currentTimelineAccessBook = []
        document.querySelectorAll(".userWithAccess").forEach(el => {
            currentTimelineAccessBook.push(el.getAttribute('data-uid'))
        })
        window.accessBookEqual = MiscAssistant.arraysEqual(window.initialTimelineEditorAccessBook, currentTimelineAccessBook)
        window.namesEqual = (currentTimelineName === window.initialTimelineEditorName)

        console.debug(window.accessBookEqual)
        console.debug(window.namesEqual)
        if (window.accessBookEqual && window.namesEqual) {
            ProfileContainer.disableEditConfirmButton()
        } else {
            ProfileContainer.enableEditConfirmButton()
        }
        ProfileContainer.checkIfSharedTitleShouldBeShown()
    }

    static enableEditConfirmButton(){
        document.getElementById("confirmEdit").removeAttribute('disabled')
        document.getElementById('confirmEdit').style.display = ''
        document.getElementById('cancelEdit').style.display = ''
        document.getElementById('closeEdit').style.display = 'none'
    }

    static disableEditConfirmButton(){
        document.getElementById("confirmEdit").setAttribute('disabled', "")
        document.getElementById('confirmEdit').style.display = 'none'
        document.getElementById('cancelEdit').style.display = 'none'
        document.getElementById('closeEdit').style.display = ''
    }

    static confirmTimelineEditWasPressed() {

        let newUsers = []
        let existingUsersCurrentlyShown = []
        let existingUsersRemoved = []

        if (!window.namesEqual) {
            window.kUser.timelines[window.initialTimelineEditorId].timelineName = document.getElementById('editedTimelineName').value;
            document.querySelector(`[data-timelineid="${window.initialTimelineEditorId}"]`).childNodes[0].textContent = document.getElementById('editedTimelineName').value
        }
        if (!window.accessBookEqual) {
            document.querySelectorAll('.new-shared-user').forEach(element => {
                newUsers.push(element.getAttribute('data-uid'))
            })
            document.querySelectorAll('.userWithAccess:not(.new-shared-user)').forEach(element => {
                // console.debug(element.childNodes[0])
                existingUsersCurrentlyShown.push(element.getAttribute('data-uid'))
            })
            window.initialTimelineEditorAccessBook.forEach(existingUser => {
                if (!existingUsersCurrentlyShown.includes(existingUser)) {
                    existingUsersRemoved.push(existingUser)
                }
            })
        }
        const savedTimeline = window.initialTimelineEditorId;
        window.timelineEditorModal.hide()
        TimelineContainer.showSavingChangesBanner()

        FirebaseAssistant.updateTimelineAccessBook(window.initialTimelineEditorId, newUsers, existingUsersRemoved, function (response) {
            response.fails.forEach(failedNewSharedUser => {
                const index = newUsers.indexOf(failedNewSharedUser);
                if (index > -1) { // only splice array when item is found
                    newUsers.splice(index, 1); // 2nd parameter means remove one item only
                }
            })
            newUsers.forEach(newUser => {
                window.kUser.timelines[savedTimeline].hasAccessToTimeline.indexOf(newUser) === -1 ? window.kUser.timelines[savedTimeline].hasAccessToTimeline.push(newUser) : console.debug("This item already exists");
                window.kUser.timelines[savedTimeline].hasHadAccessToTimeline.indexOf(newUser) === -1 ? window.kUser.timelines[savedTimeline].hasHadAccessToTimeline.push(newUser) : console.debug("This item already exists");
            })
            existingUsersRemoved.forEach(existingUserRemoved => {
                const index = window.kUser.timelines[savedTimeline].hasAccessToTimeline.indexOf(existingUserRemoved);
                if (index > -1) { // only splice array when item is found
                    window.kUser.timelines[savedTimeline].hasAccessToTimeline.splice(index, 1); // 2nd parameter means remove one item only
                }
            })

            FirebaseAssistant.updateUserFirebaseData(function () {
                NavBar.reloadCurrentPage()
                // TimelineContainer.hideSavingChangesBanner();
                if (!window.namesEqual) {
                    console.debug('names not equal need to notify')
                    console.debug(`excluding ${newUsers.concat(existingUsersRemoved)}`)
                    // newly  added or removed users are already notified  (bc the shareList firebase document was changed, and they pulled the new data). Unchanged shared users need to be notified if there was a name change
                    FirebaseAssistant.notifyPotentialSharedUsersOfTimelineUpdates(savedTimeline, FirebaseAssistant.getCurrentTimestamp(), (newUsers.concat(existingUsersRemoved)))
                }
            })
        })
    }

    static insertTimelineOption(isProvider, timeline, position, element, isShared, ownerName, phone) {
        let shouldBeMuted = '';
        if (!FirebaseAssistant.userIsPremium() && timeline !== window.kUser.initialTimeline)
            shouldBeMuted = 'text-muted'
        let timelineName;
        let accessCardinality = '';
        if (isShared) {
            timelineName = window.sharedTimelines[timeline].timelineName
            if (FirebaseAssistant.ownerOfSharedTimelineIsNoLongerPro(timeline)) // owner is not premium
                shouldBeMuted = 'text-muted'
        } else {
            timelineName = window.kUser.timelines[timeline].timelineName
            if (window.kUser.timelines[timeline].hasAccessToTimeline.length === 1) { // self is always included
                accessCardinality = I18n.translateString('profilePage.sharedToNoone')
            } else if (window.kUser.timelines[timeline].hasAccessToTimeline.length === 2) {
                accessCardinality = `${I18n.translateString('profilePage.sharedTo')} ${window.kUser.timelines[timeline].hasAccessToTimeline.length - 1} ${I18n.translateString('profilePage.user')}`
            } else {
                accessCardinality = `${I18n.translateString('profilePage.sharedTo')} ${window.kUser.timelines[timeline].hasAccessToTimeline.length - 1} ${I18n.translateString('profilePage.users')}`
            }
        }
        let selected = ''

        let toHighlight;
        if (window.currentTimeline !== undefined)
            toHighlight = window.currentTimeline;
        else if (!isProvider) {
            if (FirebaseAssistant.userIsPremium()) {
                toHighlight = window.kUser.lastAccessedTimeline;
            } else {
                toHighlight = window.kUser.initialTimeline;
            }
        }
        if (timeline === toHighlight) {
            selected = 'active'
        } else {
            selected = ''
        }
        if (isShared) {
            element.insertAdjacentHTML(position, `<a  class="sharedTimelineListItem list-group-item ${selected} ${shouldBeMuted}" data-timelineId="${timeline}"><span class="sharedTimelineOwnerName rounded-pill bg-success border" data-sharedtimelineowenername="${ownerName}" style="color:white;padding-right: 0.5rem;padding-left: 0.5rem;width: fit-content;font-size:x-small;display: block;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${I18n.translateString('profilePage.sharedBy')} '${ownerName}'</span><span class="timelineOptionName" style="display: block; max-width: 23ch; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">${timelineName}</span><span class=" position-absolute top-50 translate-middle ${shouldBeMuted}" style=" font-size: x-large; position: absolute!important; color: white; right: 0.3rem !important; "><i class="callButton bx bx-phone-outgoing"  data-numbertocall="${phone}"></i></span></a>`)
        } else {
            element.insertAdjacentHTML(position, `<a  class="list-group-item ${selected} ${shouldBeMuted}" data-timelineId="${timeline}"><span class=" rounded-pill bg-primary border"  style="color:white;padding-right: 0.5rem;padding-left: 0.5rem;width: fit-content;font-size:x-small;display: block;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">${accessCardinality}</span><span class="timelineOptionName" style="display: block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;max-width: 25ch">${timelineName}</span><i class="bx bxs-edit" style="position: absolute;top: 30%;right: 1rem;font-size: x-large"></i></a>`)
        }
    }

    static getSortedIndexForNames(array, value) { //binary search insertion for strings
        let low = 0,
            high = array.length;

        while (low < high) {
            const mid = (low + high) >>> 1;
            if (array[mid].querySelector('.sharedTimelineOwnerName').getAttribute('data-sharedtimelineowenername').localeCompare(value) === -1) low = mid + 1;
            else high = mid;
        }
        return low;
    }

    static newTimelinePopup() { //todo change that so that it can be used from the provider!
        if (FirebaseAssistant.userIsPremium()) {
            window.timelineCreationModal.show()
        } else {
            RouterAssistant.redirect('upgrade-page')
        }
    }

    static displaySharedUser(phoneNumber, isNew) {
        let newUsr = ''
        let newUsrClass = ''
        if (isNew) {
            newUsr = `<span class="position-absolute top-50  translate-middle badge  rounded-pill bg-success border" style=" left: 80%!important; ">new</span>`
            newUsrClass = 'new-shared-user'
        }
        const html = `<a  class="list-group-item userWithAccess ${newUsrClass}" data-phone="${phoneNumber}">${phoneNumber}<i class="bx bx-x" style="font-size:x-large;position: absolute;top: 20%;right: 5%;"></i>${newUsr}</a>`
        document.getElementById('usersWithAccessContainer').insertAdjacentHTML('beforeend', html)
        document.querySelector(`[data-phone="${phoneNumber}"]`).scrollIntoView()
        FirebaseAssistant.getUsernamesFromPhones([phoneNumber], function (r) {
            document.querySelectorAll('.new-shared-user').forEach(user => {
                if (phoneNumber === user.getAttribute('data-phone')) {
                    if (user.firstChild.nodeValue in r.users && r.users[user.firstChild.nodeValue] !== '') { // user may not have a display name or may not exist
                        user.setAttribute('data-uid', r.uids[user.firstChild.nodeValue])
                        user.firstChild.nodeValue = r.users[user.firstChild.nodeValue]
                    }
                }
            })
            ProfileContainer.checkIfEditConfirmButtonShouldChangeState()
        })
    }

    static checkIfSaveButtonShouldBeEnabled() {
        const text = document.getElementById('timelineName').value
        document.getElementById('create').disabled = text.replaceAll(' ', '').length === 0;
    }

    static saveButtonWasPressed() {
        window.timelineCreationModal.hide()
        FirebaseAssistant.createNewTimeline(document.getElementById('timelineName').value)
    }
}

ProfileContainer.template = /*html*/ `   
    <div class="container-fluid h-100 d-flex flex-column justify-content-center align-items-center">
<!--      <div class="row mt-4 mb-3">-->
<!--        <div class="col-2 offset-5 col-sm-2 offset-sm-5 col-md-2 offset-md-5 col-lg-2 offset-lg-5 col-xl-1 offset-lg-6 mx-auto mt-4 mb-3">-->
<!--          <div class="ratio ratio-1x1 rounded-circle overflow-hidden">-->
<!--            <img src="${avatar}" class="card-img-top" style="object-fit:cover">-->
<!--            <span id="proBadge" class="position-absolute top-50  translate-middle badge  rounded-pill bg-primary border" style="display: none; width: unset;height: unset; left: 80%!important; ">PRO</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-2">-->
        <div class="row mb-2 w-100 text-center ">
<div  id="userDepictorRow" class="mt-2 col-12 p-0 mb-3">

                <div class="ratio ratio-1x1 rounded-circle overflow-hidden" style="display: inline-block;max-width: 7rem;">
                    <img src="${avatar}" class="card-img-top" style="object-fit:cover">
                    <span id="proBadge" class="position-absolute top-50  translate-middle badge  rounded-pill bg-primary border" style="display: none; width: unset;height: unset; left: 80%!important; ">PRO</span>
                </div>
<span id="userFullName" class=" p-0" style="max-width: 20ch;white-space: normal;top: -50%;transform: translateY(50%);display: inline-block;position: relative;font-weight: 500;font-size: x-large;">Pantel asdas da sdeimon Stamatakis</span>    </div>
<!--      </div>-->
<!--      <div class="row mb-4">-->
        <div class="mb-4 col-10 offset-1 col-sm-8 offset-sm-2 col-md-4 offset-md-4 col-lg-4 offset-lg-4 col-xl-4 offset-lg-4 mx-auto  ">
          <div id="timelineSelector" class="list-group" style="overflow: auto;">
              <a id="newTimelineButton"  class="list-group-item list-group-item-light" style="box-shadow: 0px 10px #212529;z-index:3;position: sticky;bottom: 0;text-align: center">Δημιουργία νέου χρονολογίου</a>
          </div>
        </div>
<!--      </div>-->
<!--      <div id="userPortalRow" class="row" style=""> -->
                  <div id="userPortalRow" class="col-12 text-center"> 
                  <a id="userPortalLink" style="display:none;margin-right: 2rem"><i id="userPortal" class="bx bxs-credit-card" style=" font-size: x-large; "></i></a> 
                  <a id="userDeletionLink"><i id="userDeletion" class="bx bxs-trash-alt" style=" font-size: x-large; "></i></a> 
                  </div> 
<!--      </div>-->
<!--      <div class="row mt-4">-->
        <div id="logoutButtonsRow" class="col-12 text-center mt-4">
          <a id="contactus" type="button" class="btn btn-outline-secondary" style="margin-right: 3rem">Αναφορά προβλήματος</a>
          <button id="logout" type="button" class="btn btn-info">Αποσύνδεση</button>
        </div>
    </div>
    
    
    <div class="modal fade" id="creationModal"  data-bs-backdrop="static" data-bs-keyboard="false"   aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel" >Δημιουργία</h1>
<!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div id="creationModalBody" class="modal-body">
                    <div class="form-floating mb-3">
                        <input id="timelineName" type="text" class="form-control" placeholder="name@example.com">
                        <label id="newTimelineName" for="timelineName">Όνομα νέου χρονολογίου</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="cancelCreation" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ακύρωση</button>
                    <button id="create" type="button" class="btn btn-primary" disabled>Δημιουργία</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="modal fade" id="editorModal"  data-bs-backdrop="static" data-bs-keyboard="false"   aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="renameTimelineLabel">Επεξεργασία χρονολόγιου</h1>
<!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div id="editorModalBody" class="modal-body">
                    <label id="editedTimelineNamePlaceholder">Όνομα χρονολόγιου</label>
                    <div class=" mb-3 col-10 mx-auto mt-2">
                        <input id="editedTimelineName" type="text" class="form-control" placeholder="">
                    </div>
                    <label id="sharedListTitle" for="message-text" class="col-form-label mb-2" style="display: none">Χρήστες με πρόσβαση:</label>
                    <div id="usersWithAccessContainer" class="list-group col-10 mx-auto" style="max-height: 20dvh;overflow: auto;">
                    </div>
                    <div id="shareTimelineButton" class="list-group col-10 mt-3 mx-auto">
                        <a id="shareTimelineButtonText" class=" btn btn-success">Share timeline</a>
                    </div>
                    <div id="archiveTimelineButton" class="list-group col-10 mt-3 mx-auto">
                        <a id="archiveTimelineButtonText" class=" btn btn-danger">Archive timeline</a>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeEdit" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Κλείσιμο</button>
                    <button id="cancelEdit" type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="display: none">Ακύρωση</button>
                    <button id="confirmEdit" type="button" class="btn btn-primary" disabled  style="display: none">Αποθήκευση</button>
                </div>
            </div>
        </div>
    </div>
    
    
    
    
      <div class="modal fade" id="timelineSharerModal" style="z-index: 1059" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="timelineSharerModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div id="timelineSharerModalBody" class="modal-body">
                    <div class="form-floating mb-3 col-12">
                        <input id="timelineSharerInput" type="text" class="form-control" placeholder="foo">
                        <label id="timelineSharerInputPlaceholder" for="timelineSharerInput">Phone number of user to share to</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="cancelTimelineSharerModal" type="button" class="btn btn-secondary">Ακύρωση</button>
                    <button disabled id="confirmTimelineSharerModal" type="button" class="btn btn-primary">Συνέχεια</button>
                </div>
            </div>
        </div>
    </div>
    
`;

ProfileContainer.reg('profile-container');
