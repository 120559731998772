import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";

// const video = require('./assets/rotating_logo.webm')
const image = require('./assets/icons/any/icon-512x512.png')
const safariShare = require('./assets/safari-share.png')
const addHomeScreen = require('./assets/add-ios.png')


const css = `
<style>
</style>
 `

export class PwaGuard extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        document.getElementById('offcanvasBottomLabel').innerText = I18n.translateString('pwaGuard.text')
        window.bsOffcanvas = new window.bootstrap.Offcanvas('#offcanvasBottom')
        let deferredPrompt;
        window.addEventListener('beforeinstallprompt', (e) => { // for android
            e.preventDefault();
            deferredPrompt = e;
        });
        const currentUrl = window.location.href;
        if (!currentUrl.includes('legal') && !currentUrl.includes('deletion') && !currentUrl.includes('returnedFromStripe') && !currentUrl.includes('uploader')) {
            if (PwaGuard.accessIsThroughMobile() && PwaGuard.accessIsThroughIphone() === 0 && !PwaGuard.accessIsThroughPwa()) {
                PwaGuard.androidNudgeInstall()
            } else if (PwaGuard.accessIsThroughIphone() === 1 && (!PwaGuard.accessIsThroughPwa())) {
                PwaGuard.iphoneNudgeInstall()
            } else if (PwaGuard.accessIsThroughIphone() === -1) {
                PwaGuard.iphoneNudgeInstall()
            }
        }
    }

    static iphoneNudgeInstall(){
        // document.querySelectorAll('.instruction').forEach(el => {
        //     el.style.display = 'none'
        // })
        document.getElementById('androidLinkage').href = 'https://apps.apple.com/us/app/ble/id6465175871'
        document.getElementById('offcanvasBottom').style.height = '30dvh'
        document.getElementById('androidLinkage').style.color = 'unset'
        document.getElementById('androidLinkage').style.textDecoration = 'none'
        window.bsOffcanvas.show();
    }

    static androidNudgeInstall() {
        // document.querySelectorAll('.instruction').forEach(el => {
        //     el.style.display = 'none'
        // })
        document.getElementById('androidLinkage').href = 'market://details?id=app.web.kartela_678b4.twa'
        document.getElementById('offcanvasBottom').style.height = '30dvh'
        document.getElementById('androidLinkage').style.color = 'unset'
        document.getElementById('androidLinkage').style.textDecoration = 'none'
        window.bsOffcanvas.show();
        // window.location.replace('https://play.google.com/store/apps/details?id=app.web.kartela_678b4.twa')
    }

    static insertToPage() {
        document.body.insertAdjacentHTML('beforeend', '<pwa-guard></pwa-guard>')
    }

    static accessIsThroughPwa() {
        const nonUsableSpaceInPixels = window.screen.height - window.innerHeight
        const samsungPwa = (nonUsableSpaceInPixels < 100) && navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i);
        // alert(`outer: ${window.outerHeight}, inner: ${window.innerHeight}, screenAvailHeight: ${window.screen.availHeight}, screenHeight: ${window.screen.height}`)
        return (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://') || samsungPwa);
    }

    static accessIsThroughIphone() {
        // alert(navigator.userAgent)
        //
        // return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && window.outerHeight > window.outerWidth;
        const standalone = window.navigator.standalone,
            userAgent = window.navigator.userAgent.toLowerCase(),
            safari = /safari/.test(userAgent),
            ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
            if (!standalone && safari) {
                return 1
            } else if (standalone && !safari) {
                return 1
            } else if (!standalone && !safari) {
                return -1
            } else if (standalone && safari) {
                return 2
            }
        } else {
            return 0
        }
    }

    static accessIsThroughMobile() {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }
}


PwaGuard.template = /*html*/ `
<div class="offcanvas offcanvas-bottom"  id="offcanvasBottom" data-bs-backdrop="static" aria-labelledby="offcanvasBottomLabel" style="height: 55dvh; background: white; border-radius: 3rem 3rem 0 0; z-index: 5000; box-shadow: 0 -5px 20px darkslategray;">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title mx-auto mt-3" id="offcanvasBottomLabel" style="color: darkslategray">Εγκατάσταση εφαρμογής</h5>
    </div>
    <div class="offcanvas-body small">
        <div class="card mb-3 mx-auto" style="width: 350px;    color: darkslategray; background: #f2f2f2;">
            <a id="androidLinkage">
                <div class="row g-0">
                    <div class="col-4">
                        <img src="${image}" class="img-fluid mx-auto" alt="..." style="position:relative;display: block;width: 4rem;border-radius: 15px;top: 50%;transform: translate(0, -50%);">
                    </div>
                    <div class="col-8">
                        <div class="card-body">
                            <h5 class="card-title">BLE</h5>
                            <p class="card-text">https://ble-app.com</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
`;

PwaGuard.reg('pwa-guard');
