export const greekTranslation =
    {
        "timelinePage": {
            "timestamps": {
                "months": {
                    "Jan": "Ιαν",
                    "Feb": "Φεβ",
                    "Mar": "Μαρ",
                    "Apr": "Απρ",
                    "May": "Μάι",
                    "Jun": "Ιούν",
                    "Jul": "Ιούλ",
                    "Aug": "Αύγ",
                    "Sep": "Σεπ",
                    "Oct": "Οκτ",
                    "Nov": "Νοέ",
                    "Dec": "Δεκ"
                }
            },
            "emptyTimelineImage": "emptyTimelineEl",
            "scrollNudgeText": "Κάντε σκρολ με το ποντίκι ή το touchpad"
        },
        "loginPage": {
            "mainHeader": "Καλώς ήρθες!",
            "secondaryHeader": "Μαζί, θα πάμε μακριά.",
            "inputPlaceholder": "Αριθμός κινητού τηλεφώνου",
            "reCaptchaDisclaimerDesktop": `Αυτός ο ιστότοπος προστατεύεται από reCAPTCHA και ισχύουν<br>η <a href="https://policies.google.com/privacy" target="=_blank">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms">Όροι Παροχής Υπηρεσιών</a> της Google.`,
            "reCaptchaDisclaimerMobile": `Αυτή η εφαρμογή προστατεύεται από reCAPTCHA και ισχύουν<br>η <a href="https://policies.google.com/privacy" target="=_blank">Πολιτική Απορρήτου</a> και οι <a href="https://policies.google.com/terms">Όροι Παροχής Υπηρεσιών</a> της Google.`
        },
        "loginValidationPage": {
            "mainHeader": "Μόλις",
            "secondaryHeader": "σου στείλαμε SMS!",
            "smsPrompt": "Γράψε τον 6ψήφιο αριθμό που θα βρεις στο SMS"
        },
        "splashScreenMessages": {
            "weArePreparingYourData": "Ετοιμάζουμε τα δεδομένα σου",
            "stripePending": "Ετοιμάζουμε ένα ασφαλές περιβάλλον πληρωμών για εσένα",
            "savingChanges": "Γίνεται αποθήκευση. Μην κλείσετε την εφαρμογή!",
        },
        "navBar": {
            "timeline": "Καρτέλα",
            "clients": "Πελατολόγιο",
            "newEntry": "Νέα καταχώρηση",
            "newClient": "Νέος πελάτης",
            "profile": "Προφίλ",
        },
        "profilePage": {
            "logout": "Αποσύνδεση",
            "create": "Δημιουργία",
            "save": "Αποθήκευση",
            "cancel": "Ακύρωση",
            "close": "Κλείσιμο",
            "continue": "Συνέχεια",
            "shareToNewUser": "Κοινοποίηση σε νέο χρήστη",
            "usersWithAccess": "Χρήστες με πρόσβαση:",
            "timelineName": "Όνομα καρτέλας",
            "editTimeline": "Επεξεργασία καρτέλας",
            "newTimelineName": "Όνομα νέας καρτέλας",
            "createNewTimeline": "Δημιουργία νέας καρτέλας",
            "didYouFaceAnyIssues": "Αναφορά προβλήματος",
            "enterNewUserMobilePhone": "Εισάγετε το τηλέφωνο του χρήστη",
            "archiveTimelineQuestion": "Διαγραφή καρτέλας",
            "shareTimeline": "Κοινοποίηση καρτέλας",
            "archive": "Διαγραφή",
            "userNoLongerPro": "Ο χρήστης δεν είναι πλέον Pro. Προκειμένου να γίνει χρήση μίας κοινοποιημένης καρτέλας θα πρέπει και ο κάτοχος να είναι Pro",
            "sharedBy": "Κοινοποιήθηκε από",
            "sharedToNoone": "Δεν έχει κοινοποιηθεί",
            "sharedTo": "Έχει κοινοποιηθεί σε",
            "user": "χρήστη",
            "users": "χρήστες",
            "loading": "(φόρτωση...)"
        },
        "actionVerifier": {
            "archiveEntry": "Διαγραφή καταχώρησης;",
            "archive": "Διαγραφή",
            "removeFile": "Αφαίρεση αρχείου",
            "questionMark": ";",
            "remove": "Αφαίρεση",
            "cancel": "Ακύρωση",
        },
        "cameraOverlay": {
            "uploadDone": "Οι φωτογραφίες έχουν μεταφερθεί.<br>Μπορείτε να συνεχίσετε απο τον υπολογιστή σας.",
            "upload": "Αποστολή",
            "photo": "φωτογρ.",
            "photos": "φωτογρ.",
            "resetPhotos": "Επανεκκίνηση<br>φωτογραφιών",
            "photosUploadingSplasher": "Παρακαλούμε περιμένετε, οι φωτογραφίες σας αποστέλλονται!",
            "accessPrompt": "Πρέπει να δώσετε πρόσβαση στην κάμερα.<br>Ελέγξτε τις ρυθμίσεις της συσκευής σας."
        },
        "deletionContainer": {
            "notLoggedIn": "Δεν είστε συνδεδεμένοι",
            "mustBeLoggedIn": "Πρέπει αν είστε συνδεδεμένοι προκειμένου να αιτηθείτε διαγραφή δεδομένων",
            "loginHere": `Μπορείτε να πραγματοποιήσετε είσοδο πατώντας <a href="." id="redirectLogin">εδώ</a>`,
            "deletionToHappenAfter7days": "Τα δεδομένα σας θα διαγραφούν μετά το πέρας 7 ημερών.",
            "contactUs": "Επικοινωνήστε",
            "detailsToCancelDeletion": "έγκαιρα μαζί μας εντός αυτού του διαστήματος σε περίπτωση που θέλετε να ακυρώσετε τη διαγραφή.",
            "deleteData": "Διαγραφή δεδομένων",
            "allDataWillBeDeleted": "Όλα τα δεδομένα σας θα διαγραφούν απο το BLE",
            "sendingDeletionRequest": "Πραγματοποιείται αίτηση διαγραφής δεδομένων...",
            "notYourNumber": "Αυτός δεν είναι ο αριθμός σας!",
            "user": "Χρήστης",
            "emailSubject": "Αίτηση διαγραφής δεδομένων",
            "phoneInputPlaceholder": "Αριθμός κινητού τηλεφώνου",
        },
        "pwaGuard": {
            "text": "Εγκατάσταση εφαρμογής"
        },
        "upgradePage": {
            "continue": "Συνέχεια",
            "accessSharedTimelines": "Πρόσβαση σε κοινοποιημένες καρτέλες!",
            "shareYourTimelines": "Κοινοποίηση καρτελών!",
            "unlimitedTimelines": "Πολλαπλές καρτέλες!",
            "perk1prov": "Πελατολόγιο με αρχείο!",
            "perk2prov": "Κοινοποίηση καρτελών!",
            "perk3prov": "Πολλαπλές καρτέλες!",
            "price": "0.99",
            "billingPeriod": " € / μήνα",
            "callToAction": "Γίνε Pro!",
            "callToActionProv": "Η συνδρομή σου έληξε :(",
            "callToActionProv2": "Αν σου αρέσαμε, μείνε!",
        },
        "eventEditor": {
            "close": "Κλείσιμο",
            "back": "Πίσω",
            "cancel": "Κλείσιμο",
            "save": "Αποθήκευση",
            "mobilePhotoTransferCancelationInstruction": "<br>Εάν θέλετε να ακυρώσετε τη μεταφόρτωση μέσω κινητού, πατήστε στο κουμπί 'πίσω'",
            "mobileTransferStep4": "4. Oι φωτογραφίες θα εμφανιστούν εδω αυτόματα",
            "mobileTransferStep3": `3. Πατήστε στο κουμπί "Αποστολή Χ φωτογραφιών"`,
            "mobileTransferStep2": "2. Τραβήξτε τις φωτογραφίες που θέλετε",
            "mobileTransferStep1": "1. Σκανάρετε τον QR code που φαίνεται παρακάτω",
            "userSmartphoneInstruction": "Χρησιμοποιήστε ενα οποιοδήποτε smartphone για να ανεβάσετε αρχεία στην καταχώρηση:",
            "uploadThroughCamera": "Κάμερα κινητού",
            "uploadFiles": "Αρχεία",
            "newTimelineNamePlaceholderText": "Όνομα νέας καρτέλας",
            "calendarPlaceholderLabelText": "Ημερομηνία καταχώρησης",
            "eventDescriptionPlaceholderText": "Σχόλια καταχώρησης",
            "eventTitlePlaceholderText": "Τίτλος καταχώρησης",
            "editorModalHeaderNewEvent": "Νέα καταχώρηση",
            "editorModalHeaderEditEvent": "Επεξεργασία καταχώρισης",
            "theFile": "Το αρχείο",
            "isNotSupported": "δεν υποστηρίζεται. Τα αρχεία που υποστηρίζονται είναι",
            "isTooBig": "είναι πολύ μεγάλο! Το μέγιστο μέγεθος ανα αρχείο είναι τα 15 megabytes.",
            "createdFrom": "Τελευταία ενημέρωση από",
            "youNeedFullAccessTitle": "Χρειάζεστε πλήρη πρόσβαση στην καρτέλα!",
            "youNeedFullAccessDescription": "Προκειμένου να δείτε καταχωρήσεις τρίτων σε μία καρτέλα, ο ιδιοκτήτης πρέπει να είναι Pro και να σας κάνει κοινοποίηση της καρτέλας.",
            "timelineIsArchivedTitle": "Αυτή η καρτέλα έχει διαγραφεί!",
            "timelineIsArchivedDescription": "Μπορείτε να δείτε μόνο τις δικές σας καταχωρίσεις, καθώς ο κάτοχος αυτής της καρτέλας την έχει διαγράψει.",
        },
        "registrationPage": {
            "aQuestion": "Μια ερώτηση",
            "whatDescribesYouBest": "τι σε περιγράφει καλύτερα;",
            "client": "Προσωπική χρήση",
            "clientDescription": "Θα ασχολούμαι κυρίως με δικές μου καταχωρήσεις",
            "provider": "Επαγγελματική χρήση",
            "providerDescription": "Θα δημιουργώ καταχωρήσεις στο πελατολόγιο μου",
            "iAcceptTheTermsAndConditions": `Αποδέχομαι τους <a id="legalLink" class="legal" href="">Όρους και Προϋποθέσεις</a>`,
            "free": "δωρεάν",
            "threeMonthsFree": "3 μήνες δωρεάν",
            "wrongOtp": "Λάθος κωδικός. Δοκιμάστε ξανά.",
            "otpTimeout": "Ο χρόνος εισαγωγής του κωδικού έληξε."
        },
        "legal": {
            "translation1": `<b>Όροι Παροχής Υπηρεσιών του BLE</b>`,
            "translation2": `Ημερομηνία έναρξης ισχύος: 1 Ιανουαρίου 2023`,
            "translation3": `<b>1. Εισαγωγή</b>`,
            "translation4": `Σας ευχαριστούμε που χρησιμοποιείτε το BLE. Το BLE είναι μια υπηρεσία που παρέχεται από την Protevon (εφεξής “<strong>Protevon</strong>”, “<strong>εμείς</strong>” ή “<strong>μας</strong>”), με έδρα στη διεύθυνση Ζωοδόχου Πηγής 8, 56224, Θεσσαλονίκη, Ελλάδα. Οι παρόντες Όροι Παροχής Υπηρεσιών του BLE (οι οποίοι αναφέρονται ως “<strong>Όροι</strong>”) καλύπτουν την από μέρους σας χρήση και πρόσβαση στο BLE και το περιεχόμενό σας στο BLE. Η <a href="#privacy">Πολιτική απορρήτου</a> εξηγεί με ποιον τρόπο συλλέγουμε και χρησιμοποιούμε τις πληροφορίες σας, ενώ οι <a href="#AbusePolicy">Πολιτικές προγράμματος</a> περιγράφουν τις υποχρεώσεις σας όταν χρησιμοποιείτε τις υπηρεσίες μας.`,
            "translation5": `Η από μέρους σας χρήση του BLE προϋποθέτει ότι αποδέχεστε τους Όρους. Διαβάστε τους προσεκτικά. Εάν δεν κατανοείτε τους Όρους ή δεν αποδέχεστε οποιοδήποτε μέρος τους, τότε δεν πρέπει να χρησιμοποιείτε το BLE.`,
        },
        "showcaseAssistant": {
            "guidedTours_v1_2": `Πατήστε πάνω σε μία καταχώρηση για να την δείτε αναλυτικότερα ή να την τροποποιήσετε!`,
            "guidedTours_v1_1": `Δημιουργήστε μια νέα καταχώρηση πατώντας στο +`,
            "guidedTours_v1_3": `Μπορείτε να έχετε πολλαπλές προσωπικές καρτέλες και να τις μοιράζεστε με τρίτους μέσω του προφίλ σας`,
            "guidedTours_v1_4": `Μπορειτε να τηλεφωνήσετε έναν πελάτη πατώντας τον παρατεταμένα!`,
            "guidedTours_v1_4_2": `ΤΟ'ΧΩ!`,
        },
        "demoData": {
            "timelineName": "Η προσωπική μου καρτέλα",
            "event1title": "Παράδειγμα καταχώρησης 1",
            "event1description": " ",
            "event2title": "Παράδειγμα καταχώρησης 2",
            "event2description": "Η δεύτερη καταχώρηση έχει και περιγραφή! Μπορούμε να εισάγουμε όσες πληροφορίες θέλουμε στην περιγραφή της κάθε καταχώρησης.",
            "event3title": "Παράδειγμα καταχώρησης 3",
            "event3description": "Στην τρίτη καταχώρηση έχουμε και αρχεία! Μπορούμε να μεταφορτώσουμε υπάρχοντα αρχεία ή να χρησιμοποιήσουμε την κάμερα του κινητού μας για την δημιουργία νέων.",
        },
        "announcementAssistant": {
            "trialAwarenessHeader": "Ένα δώρο από εμάς!",
            "trialAwarenessDescription": "Με την εγγραφή σας, έχετε 3 μήνες PRO δωρεάν! Με το PRO μπορείτε να:",
            "trialAwarenessDescriptionProv": "Με την εγγραφή σας, έχετε 3 μήνες επαγγελματικού πακέτου δωρεάν! Με αυτό μπορείτε να διατηρείτε:",
            "perk1": "δημιουργείτε πολλαπλές καρτέλες",
            "perk2": "κοινοποιείτε τις καρτέλες σας",
            "perk3": "βλέπετε κοινοποιημένες καρτέλες",
            "perk1prov": "πελατολόγιο με ιστορικό (καρτέλες) γεγονότων",
            "perk2prov": "αρχείο (εικόνες, βίντεο, έγγραφα) ανά γεγονός",
            "perk3prov": "πολλαπλές καρτέλες για κάθε πελάτη αλλά και για δική σας προσωπική χρήση",
            "confirmationButton": "Συνέχεια",
        },
        "userNotifier": {
            "attention": "Προσοχή!",
            "usersDoNotExist": "Η καρτέλα δεν ήταν εφικτό να κοινοποιηθεί στους παρακάτω χρήστες καθώς αυτοί δεν υπάρχουν",
            "ownerNoLongerProTitle": "O χρήστης δεν είναι πλέον Pro!",
            "ownerNoLongerProDescription": "Ο χρήστης έχει κοινοποιήσει την καρτέλα του μαζί σας αλλά δεν είναι πλέον Pro. Προκειμένου μια καρτέλα να κοινοποιηθεί ο χρήστης πρέπει να είναι Pro.",
        },
        "networkGuard": {
            "title": "Ώπα!",
            "description": "Αργή ή καθόλου σύνδεση στο ίντερνετ.",
        },
        "successPage": {
            "title": "Συγχαρητήρια!",
            "description": "Πλέον είσαι Pro",
            "button": "Συνέχεια",
        },
        "selfRenamer": {
            "username": "Το όνομα σου;",
            "cancel": "Ακύρωση",
            "save": "Αποθήκευση",
        },
        "userCreator": {
            "newClient": "Νέος πελάτης",
            "phoneNumber": "Αριθμός κινητού τηλεφώνου",
            "cancel": "Ακύρωση",
            "addClient": "Προσθήκη πελάτη",
            "fullname": "Ονοματεπώνυμο",
            "firstTimelineName": "Όνομα πρώτης καρτέλας",
        },
        "clientele": {
            "clienteleHeader": "Πελατολόγιο",
            "clienteleEventDateColumnHeader": "ΗΜΕΡΟΜΗΝΙΑ",
            "clienteleEventsTimelinesColumnHeader": "ΚΑΤΑΧΩΡΗΣΕΙΣ (ΚΑΡΤΕΛΕΣ)",
            "clienteleNameColumnHeader": "ΟΝΟΜΑ",
            "clientelePhoneColumnHeader": "ΤΗΛΕΦΩΝΟ",
            "searchPlaceholderClientele": "Αναζήτηση",
            "events": "καταχωρήσεις",
            "event": "καταχώρηση",
            "timelines": "καρτέλες",
            "timeline": "καρτέλα",
            "newEvent": "Νέα καταχώρηση",
            "new": "Νέο",
            "Timelines": "Καρτέλες",
            "noNameAvailable": "(Όνομα μη διαθέσιμο)",
            "emptyClienteleImage": "emptyClienteleEl",
            "in": "σε"
        },
        "timelineShowcaser": {
            "whichTimelineAddEvent": "Σε ποια καρτέλα θέλετε να προσθέσετε την καταχώρηση;",
            "timeslinesOfUser": "Οι καρτέλες του",
            "createNewTimeline": "Δημιουργία νέας καρτέλας",
            "entries": "καταχωρήσεις",
            "fromYou": "από εσάς",
        },
        "shareMobileDownload": {
            "imgSrc": "splashEl",
        },
    }
