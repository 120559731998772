
import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import gsap from "gsap";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";

const css = `
<style>
 loading-splasher {
        position: fixed; top: 0; left: 0; z-index: 2000; right: 0; bottom: 0; opacity: 0;display: none;
        background: rgb(33, 37, 41);
    }
    
.wrapper{
    width:200px;
    height:60px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}
.circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    left:15%;
    transform-origin: 50%;
    background-color: #fff;
    animation: circle .5s alternate infinite ease;
}

/*.circle_white{*/
/*    background-color: #fff;*/
/*}*/

/*.circle_blue{*/
/*    background-color: #66a8f8;*/
/*}*/

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
}
.circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.wrapper span{
   left: 50%;
    width: 20rem;
    position: absolute;
    top: 75px;
    text-align: center;
    transform: translate(-50%, 0);
}
</style>
   
 `

export class LoadingSplasher extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
    }

    static activateSplasher(message, colour_in_hex = '#fff'){
        if (window.splasherActivated === undefined || window.splasherActivated === 0){
            // document.querySelectorAll('.wrapper .circle').forEach(dot => {
            //     dot.style.backgroundColor = colour_in_hex;
            // })
            // const text = document.getElementById('splasher-text')
            // text.style.color = colour_in_hex;
            document.getElementById('splasher-text').innerText = message;
            gsap.to(document.querySelector('loading-splasher'), {
                opacity: 1,
                display: 'unset'
            });
            window.splasherActivated = 1
        }
    }

    static deactivateSplasher(){
        gsap.to(document.querySelector('loading-splasher'), {
            opacity: 0,
            display: 'none'
        });
        window.splasherActivated = 0
        setTimeout(function () {
            gsap.to(document.querySelector('loading-splasher'), {
                opacity: 0,
                display: 'none'
            });
            window.splasherActivated = 0
        }, 1000); // this is to avoid race conditions
    }
}

LoadingSplasher.template = /*html*/ `
    <div class="wrapper">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span id="splasher-text">Loading</span>
    </div>
`;

LoadingSplasher.reg('loading-splasher');
