import {MiscAssistant} from "../../misc-assistant/misc-assistant";
import {I18n} from "../../i18n/i18n";
import {FirebaseAssistant} from "../firebase-assistant";

//if this changes the function newUerFromProvider should probably also change
export function getUserDemoData(userUid, isProvider, createdFromProvider, hasEvents = true) {

    const initialTimeline = MiscAssistant.getUniqueIdentifier();
    const secondaryTimeline = MiscAssistant.getUniqueIdentifier();
    const userLocation = I18n.getUsersCurrentLocation();
    const guidOfEvent1 = MiscAssistant.getUniqueIdentifier();
    const timestampOfEvent1 = FirebaseAssistant.getCurrentTimestamp(-5, 0);
    const guidOfEvent2 = MiscAssistant.getUniqueIdentifier();
    const timestampOfEvent2 = FirebaseAssistant.getCurrentTimestamp(-2, 0);
    const guidOfEvent3 = MiscAssistant.getUniqueIdentifier();
    const timestampOfEvent3 = FirebaseAssistant.getCurrentTimestamp();

    const timestampOfEvent4 = FirebaseAssistant.getCurrentTimestamp(-2, 0);
    const timestampOfEvent5 = FirebaseAssistant.getCurrentTimestamp(-1, 0);
    const timestampOfEvent6 = FirebaseAssistant.getCurrentTimestamp(0, 10);
    const guidOfEvent4 = MiscAssistant.getUniqueIdentifier();
    const guidOfEvent5 = MiscAssistant.getUniqueIdentifier();
    const guidOfEvent6 = MiscAssistant.getUniqueIdentifier();

    let newUserDemoData = {
        isProvider: isProvider,
        initialTimeline: initialTimeline,
        lastAccessedTimeline: initialTimeline,
        createdFromProvider: createdFromProvider,
        lastAccessedMachine: ' ',
        lastUpdated: timestampOfEvent3,
        timelines: {},
        providerData: {placeholder: ['placeholder']},
    }

    newUserDemoData.timelines[initialTimeline] = {
        timelineName: I18n.translateString('demoData.timelineName'),
        isArchived: false,
        timelineOwnershipHistory: {
            0: {
                owner: userUid,
                ownershipStartedOn: {
                    timestamp: timestampOfEvent3,
                    location: userLocation
                }
            }
        },
        hasAccessToTimeline: [userUid],
        hasHadAccessToTimeline: [userUid],
        events: {placeholder: 'placeholder'}
    }

    // if (hasEvents) {
    //     newUserDemoData.timelines[initialTimeline].events[guidOfEvent1] = {
    //         isArchived: false,
    //         isDemo: true,
    //         isCreatedFromProvider: false,
    //         eventCreationTimestamp: {
    //             timestamp: timestampOfEvent1,
    //             location: userLocation
    //         },
    //         versions: {
    //             0: {
    //                 versionCreator: userUid,
    //                 versionCreationTimestamp: {
    //                     timestamp: timestampOfEvent1,
    //                     location: userLocation
    //                 },
    //                 userAssignedTimestamp: timestampOfEvent1,
    //                 title: I18n.translateString('demoData.event1title'),
    //                 description: I18n.translateString('demoData.event1description'),
    //                 files: []
    //             }
    //         },
    //         timelineTransferHistory: {
    //             0: {
    //                 user: userUid,
    //                 timelineId: initialTimeline
    //             }
    //         }
    //     }
    //
    //     newUserDemoData.timelines[initialTimeline].events[guidOfEvent2] = {
    //         isArchived: false,
    //         isDemo: true,
    //         isCreatedFromProvider: false,
    //         eventCreationTimestamp: {
    //             timestamp: timestampOfEvent2,
    //             location: userLocation
    //         },
    //         versions: {
    //             0: {
    //                 versionCreator: userUid,
    //                 versionCreationTimestamp: {
    //                     timestamp: timestampOfEvent2,
    //                     location: userLocation
    //                 },
    //                 userAssignedTimestamp: timestampOfEvent2,
    //                 title: I18n.translateString('demoData.event2title'),
    //                 description: I18n.translateString('demoData.event2description'),
    //                 files: []
    //             }
    //         },
    //         timelineTransferHistory: {
    //             0: {
    //                 user: userUid,
    //                 timelineId: initialTimeline
    //             }
    //         }
    //     }
    //
    //     newUserDemoData.timelines[initialTimeline].events[guidOfEvent3] = {
    //         isArchived: false,
    //         isDemo: true,
    //         isCreatedFromProvider: false,
    //         eventCreationTimestamp: {
    //             timestamp: timestampOfEvent3,
    //             location: userLocation
    //         },
    //         versions: {
    //             0: {
    //                 versionCreator: userUid,
    //                 versionCreationTimestamp: {
    //                     timestamp: timestampOfEvent3,
    //                     location: userLocation
    //                 },
    //                 userAssignedTimestamp: timestampOfEvent3,
    //                 title: I18n.translateString('demoData.event3title'),
    //                 description: I18n.translateString('demoData.event3description'),
    //                 files: ["example.jpg", "example.mp4"]
    //             }
    //         },
    //         timelineTransferHistory: {
    //             0: {
    //                 user: userUid,
    //                 timelineId: initialTimeline
    //             }
    //         }
    //     }
    // }

    // newUserDemoData.timelines[secondaryTimeline] = {
    //     timelineName: "Ένα 2o χρονολόγιο (PRO)",
    //     isArchived: false,
    //     timelineOwnershipHistory: {
    //         0: {
    //             owner: userUid,
    //             ownershipStartedOn: {
    //                 timestamp: timestampOfEvent6,
    //                 location: userLocation
    //             }
    //         }
    //     },
    //     hasAccessToTimeline: [],
    //     hasHadAccessToTimeline: [],
    //     events: {}
    // }
    //
    // newUserDemoData.timelines[secondaryTimeline].events[guidOfEvent4] = {
    //     isArchived: false,
    //     isDemo: true,
    //     eventCreationTimestamp: {
    //         timestamp: timestampOfEvent4,
    //         location: userLocation
    //     },
    //     versions: {
    //         0: {
    //             versionCreator: userUid,
    //             versionCreationTimestamp: {
    //                 timestamp: timestampOfEvent4,
    //                 location: userLocation
    //             },
    //             userAssignedTimestamp: timestampOfEvent4,
    //             title: "Ειστε Pro χρηστης για τους επομενους 3 μηνες!",
    //             description: "Ενα δώρο απο εμάς στην BLE",
    //             files: []
    //         }
    //     },
    //     timelineTransferHistory: {
    //         0: {
    //             user: userUid,
    //             timelineId: secondaryTimeline
    //         }
    //     }
    // }
    //
    // newUserDemoData.timelines[secondaryTimeline].events[guidOfEvent5] = {
    //     isArchived: false,
    //     isDemo: true,
    //     eventCreationTimestamp: {
    //         timestamp: timestampOfEvent5,
    //         location: userLocation
    //     },
    //     versions: {
    //         0: {
    //             versionCreator: userUid,
    //             versionCreationTimestamp: {
    //                 timestamp: timestampOfEvent5,
    //                 location: userLocation
    //             },
    //             userAssignedTimestamp: timestampOfEvent5,
    //             title: "Τι πλεονεκτηματα εχει ενας χρηστης Pro;",
    //             description: "Οι Pro χρήστες μπορουν να εχουν απεριοριστα χρονολογια, να κοινοποιησουν χρονολογια σε αλλους αλλα και να βλεπουν και να τροποποιουν χρονολογια που αλλοι εχουν κοινοποιησει σε αυτους",
    //             files: []
    //         }
    //     },
    //     timelineTransferHistory: {
    //         0: {
    //             user: userUid,
    //             timelineId: secondaryTimeline
    //         }
    //     }
    // }
    //
    // newUserDemoData.timelines[secondaryTimeline].events[guidOfEvent6] = {
    //     isArchived: false,
    //     isDemo: true,
    //     eventCreationTimestamp: {
    //         timestamp: timestampOfEvent6,
    //         location: userLocation
    //     },
    //     versions: {
    //         0: {
    //             versionCreator: userUid,
    //             versionCreationTimestamp: {
    //                 timestamp: timestampOfEvent6,
    //                 location: userLocation
    //             },
    //             userAssignedTimestamp: timestampOfEvent6,
    //             title: "Γιατί κάποιος να θέλει να είναι Pro;",
    //             description: "Ενα απλο παραδειγμα ειναι η χρηση της BLE για το κατοικιδιο φιλο μας  αλλα και ταυτοχρονα για το ιστορικο service του αυτοκινητου μας. Μεσω της χρησης του Pro εχουμε τα 2 χρονολογια που χρειαζομαστε και επιπροσθετα κοινοποιωντας τα χρονολογια αυτα στον κτηνιατρο και μηχανικο αντιστοιχειχα,  αυτοι μπορουν να ενημερωνουν τα προφιλ απομακρυσμενα",
    //             files: []
    //         }
    //     },
    //     timelineTransferHistory: {
    //         0: {
    //             user: userUid,
    //             timelineId: secondaryTimeline
    //         }
    //     }
    // }

    return newUserDemoData;
}
