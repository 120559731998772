import {BaseComponent} from "@symbiotejs/symbiote";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant.js";
import {NavBar} from "../nav-bar/nav-bar.js";
import {I18n} from "../../utilities/i18n/i18n.js";

const css = `
    <style>
    </style>
`
export class UserRenamer extends BaseComponent {
    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        window.selfRenamerModal = new window.bootstrap.Modal(document.getElementById('selfRenamerModal'))
        document.getElementById('selfRenamerModal').addEventListener("shown.bs.modal", (evt)=>{
            document.getElementById('selfEditorInput').focus()
        })
        document.getElementById('confirmSelfRenamerModal').addEventListener('click', UserRenamer.saveNewUsername)
        document.getElementById('cancelSelfRenamerModal').addEventListener('click', () => {
            window.selfRenamerModal.hide()
            RouterAssistant.deactivateCurtain()
        })
        document.getElementById('selfEditorInput').addEventListener('input', UserRenamer.checkIfSaveButtonShouldBeEnabled)
        UserRenamer.translateShit()
    }

    static checkIfSaveButtonShouldBeEnabled(evt) {
        const inputString = document.getElementById('selfEditorInput').value;
        const originalName = FirebaseAssistant.getUsersDisplayName();
        const stringHasNumber = /\d/.test(inputString);
        const stringLength = inputString.replace(/ /g,'').length;

        if (!stringHasNumber && stringLength>2 && inputString!==originalName){
            document.getElementById('confirmSelfRenamerModal').removeAttribute('disabled')
        } else {
            document.getElementById('confirmSelfRenamerModal').setAttribute('disabled', '')
        }
    }

    static load(){
        document.body.insertAdjacentHTML('beforeend', '<self-renamer></self-renamer>')
    }

    static translateShit(){
        document.getElementById('selfEditorInputPlaceholder').innerText = I18n.translateString('selfRenamer.username')
        document.getElementById('cancelSelfRenamerModal').innerText = I18n.translateString('selfRenamer.cancel')
        document.getElementById('confirmSelfRenamerModal').innerText = I18n.translateString('selfRenamer.save')
    }

    static attachToElement(element){
        element.addEventListener('click', function (evt) {
            RouterAssistant.activateCurtain(0.8)
            document.getElementById('selfEditorInput').value = FirebaseAssistant.getUsersDisplayName()
            window.selfRenamerModal.show()
        })
    }

    static saveNewUsername(){
        FirebaseAssistant.updateUserDisplayName(document.getElementById('selfEditorInput').value, function () {
            window.selfRenamerModal.hide()
            NavBar.reloadCurrentPage()
        })
    }

    static promptUserToSpecifyNameIfUndefined(){
        if (FirebaseAssistant.getUsersDisplayName() === null && window.trialAnnouncementHasBeenMade !== undefined) {
            RouterAssistant.activateCurtain(0.8)
            document.getElementById('selfEditorInput').value = FirebaseAssistant.getUsersDisplayName()
            window.selfRenamerModal.show()
        }
    }
}

UserRenamer.template = /*html*/ `   
    <div class="modal fade" id="selfRenamerModal" style="z-index: 1059" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div id="selfRenamerModalBody" class="modal-body">
                    <div class="form-floating mb-3 col-12">
                        <input id="selfEditorInput" type="text" class="form-control" placeholder="foo">
                        <label id="selfEditorInputPlaceholder" for="selfEditorInput">Username</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="cancelSelfRenamerModal" type="button" class="btn btn-secondary" >Ακύρωση</button>
                    <button disabled id="confirmSelfRenamerModal" type="button" class="btn btn-primary">Αποθήκευση</button>
                </div>
            </div>
        </div>
    </div>
`;

UserRenamer.reg('self-renamer');
