import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {CameraOverlay} from "../camera-overlay/camera-overlay";
import {QRCode} from './assets/qrcode'
import './assets/long-press-event.min'


import flatpickr from "flatpickr";
import "flatpickr/dist/themes/dark.css"
import {Greek} from "flatpickr/dist/l10n/gr"
import {english} from "flatpickr/dist/l10n/default"

import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";
import {TimelineContainer} from "../timeline-container/timeline-container";
import {allowedFiles} from "./assets/allowed-files";
import {MiscAssistant} from "../../utilities/misc-assistant/misc-assistant";
import {ActionVerifier} from "../action-verifier/action-verifier";
import {NavBar} from "../nav-bar/nav-bar";
import {UserNotifier} from "../user-notifier/user-notifier";
import {ClientsContainer} from "../clients-container/clients-container";
import {ShareButton} from "../share-button/share-button";
import {LoadingSplasher} from "../loading-splasher/loading-splasher";

const flatpickrFormat = "j-M-Y H:i";

const css = `
    <style>
        #defaultModalBody {
            max-height: 35rem;
        }
        
        event-editor i::before {
            font-size: 35px;
        }
        
        event-editor .files {
            display: inline-flex;
            align-items: center;
            font-size: small;
        }
        
        event-editor .custom-column {
            width: 48%;
            margin-left: 0.14rem;
            margin-right: 0.14rem;
        }
        
        event-editor button span {
            z-index: 2;
        }
        
        event-editor a .cancel:hover {
            color: #fff !important;
            background-color: red !important;
            border-color: red !important;
        }
        
        .file-to-be-deleted {
            border: 1px solid red !important;
        }
        
        .cancel {
            z-index: 9;
        }
        
        #drop-area.highlight {
            border-color: purple;
        }
        
        .eventAttachment {
            white-space: nowrap;
        }
        
        @media (orientation: portrait) {
            #lastEdited {
                font-size: 7px;
            }
        
            .cancel {
                display: none;
            }
        }
        
        @media (orientation: landscape) {
            #lastEdited {
                font-size: small;
            }
        
            .cancel {
                display: unset;
            }
        }
    </style>
`

export class EventEditor extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        EventEditor.translateShit();
        window.addEventListener('resize', EventEditor.resizeActions);

        document.getElementById("myInput1button").addEventListener('click', () => {
            document.getElementById("myInput1").click()
        });
        document.getElementById("myInput2button").addEventListener('click', EventEditor.cameraButtonWasPressed);
        document.getElementById("eventTitle").addEventListener('input', EventEditor.checkIfSaveButtonShouldChangeState)
        document.getElementById("eventDescription").addEventListener('input', EventEditor.checkIfSaveButtonShouldChangeState)
        document.getElementById("exampleModalLabel").addEventListener('input', EventEditor.checkIfSaveButtonShouldChangeState)
        document.getElementById("newTimeLineName").addEventListener('input', EventEditor.checkIfSaveButtonShouldChangeState)
        document.getElementById("back").addEventListener('click', EventEditor.backButtonWasPressed);
        document.getElementById("save").addEventListener('click', EventEditor.saveEvent);
        document.getElementById('myInput1').addEventListener('change', () => {
            EventEditor.showFilesOnEditor('local')
        })

        const myModalEl = document.getElementById('exampleModal') // events are hide.bs.modal, hidden.bs.modal, hidePrevented.bs.modal, show.bs.modal, shown.bs.modal
        myModalEl.addEventListener('show.bs.modal', EventEditor.beforeModalShows)
        myModalEl.addEventListener('hide.bs.modal', EventEditor.beforeModalHides)
        myModalEl.addEventListener('hidden.bs.modal', EventEditor.afterModalHides)
        myModalEl.addEventListener('shown.bs.modal', EventEditor.afterModalShows)

        document.getElementById('attachmentsGroup').addEventListener('click', EventEditor.simpleClickModalFile)
        document.getElementById('attachmentsGroup').addEventListener('long-press', EventEditor.longClickModalFile)
        document.getElementById('attachmentsGroup').addEventListener('mouseover', EventEditor.hoverOnModalFile)
        document.getElementById('attachmentsGroup').addEventListener('mouseout', EventEditor.hoverOffModalFile)
        EventEditor.resizeActions();
    }

    static backButtonWasPressed() {
        document.getElementById("qrcode").innerHTML = '';
        document.getElementById('defaultModalBody').style.display = ''
        document.getElementById('qrModalBody').style.display = 'none'
        EventEditor.checkIfSaveButtonShouldChangeState()
        document.getElementById('back').style.display = 'none'
    }

    static hoverOnModalFile(event) {
        if (event.target.classList.contains("cancel")) {
            event.target.closest('a').classList.remove("btn-outline-secondary");
            event.target.closest('a').classList.add("file-to-be-deleted");
        }
    }

    static hoverOffModalFile(event) {
        if (event.target.classList.contains("cancel")) {
            event.target.closest('a').classList.add("btn-outline-secondary");
            event.target.closest('a').classList.remove("file-to-be-deleted");
        }
    }

    static simpleClickModalFile(event) {
        if (event.target.classList.contains("cancel")) {
            event.preventDefault()
            EventEditor.removeFileFromEditor(event.target.closest('a'))
        }
    }

    static longClickModalFile(event) {
        const clicked = event.target.closest('a');
        if (clicked !== null && clicked !== undefined && window.editorCurrentEventShouldBeEditable) {
            ActionVerifier.enableActionVerifier(`${I18n.translateString('actionVerifier.removeFile')} '${clicked.querySelector('span').innerText}' ${I18n.translateString('actionVerifier.questionMark')}`, I18n.translateString('actionVerifier.remove'), function () {
                EventEditor.removeFileFromEditor(clicked)
            })
        }
    }

    static cameraButtonWasPressed(event) {
        if (window.innerHeight > window.innerWidth) {
            CameraOverlay.initiate()
        } else {
            EventEditor.activateModalQrCodeOverlay()
            FirebaseAssistant.initiateCameraPhotosTransferDirectory()
            window.mobileTransferPolling = window.setInterval(function () {
                if (document.getElementById('qrModalBody').style.display === 'none') {
                    clearInterval(window.mobileTransferPolling)
                }
                FirebaseAssistant.mobileTransferUploadsAreFinished(window.mobileTransferGuid, function (result) {
                    if (result) {
                        clearInterval(window.mobileTransferPolling)
                        document.getElementById('drop-area').insertAdjacentHTML("beforeend", `<div id="eventEditorSaveOverlay" style=" background: rgba(12,12,12,0.8); position: absolute; width: 100%; height: 100%; z-index: 9999; "><div style=" position: absolute; /* width: 100%; */ /* height: 100%; */ top: 50%; left: 50%; z-index: 9999; transform: translate(-50%, -50%); background-color: rgba(12,12,12,0.5); "><div class="spinner-grow" role="status"> <span class="visually-hidden">Loading...</span> </div><div class="spinner-grow" role="status"> <span class="visually-hidden">Loading...</span> </div><div class="spinner-grow" role="status"> <span class="visually-hidden">Loading...</span> </div></div></div>`)
                        document.getElementById("back").click()
                        FirebaseAssistant.downloadLocallyCameraPhotosToBeTransferredToEvent(window.mobileTransferGuid, (downloadedImages) => {
                            EventEditor.showFilesOnEditor('cameraTransfer', downloadedImages)
                            try {
                                document.getElementById('eventEditorSaveOverlay').remove()
                            } catch (e) {
                            }
                        })
                    }
                })
            }, 1000);
        }
    }

    static beforeModalShows() {
        NavBar.deactivateNavBar()
        ShareButton.hideButton()
        document.getElementById('myInput1').value = null
        document.getElementById('myInput2').value = null
        if (!window.location.href.includes('timeline') && window.editorCreateNewTimeline_forUserId === undefined) {
            RouterAssistant.redirect('timeline-page')
        }
        if (window.editorCurrentEventId === undefined) {
            const rightNow = new Date();
            const localeToBeUsed = (I18n.getUsersPreferredLanguage() === 'el') ? Greek : english;
            window.flatpickr = flatpickr(document.getElementById('exampleModalLabel'), {
                "locale": localeToBeUsed,
                enableTime: true,
                maxDate: rightNow,
                // time_24hr: true,
                dateFormat: flatpickrFormat
            })
            window.flatpickr.setDate(rightNow);
            document.getElementById('exampleModalLabel').value = window.flatpickr.formatDate(rightNow, flatpickrFormat)
        }
        EventEditor.takeAppropriateActionsIfEventIsFromProvider()
    }

    static afterModalShows() {
        window.localNewFileBucket = []
        window.cameraNewFileBucket = []
        window.cameraTransfersNewFileBucket = []
        if (window.editorCurrentEventId === undefined) {
            window.editorCurrentEventId = MiscAssistant.getUniqueIdentifier()
            window.editorCurrentEventVersion = undefined
            // const rightNow = new Date()
            // window.flatpickr.setDate(rightNow);
            // document.getElementById('exampleModalLabel').value = window.flatpickr.formatDate(rightNow, flatpickrFormat)
        }
        if (window.editorCreateNewTimeline_forUserId === undefined) {
            if(document.getElementById('eventTitle').value === '')
                document.getElementById('eventTitle').focus()
            document.getElementById('newTimeLineNameContainer').style.display = 'none'
        } else {
            document.getElementById('newTimeLineNameContainer').style.display = ''
            document.getElementById('newTimeLineName').focus()
        }
        window.cameraTransfers = []
        EventEditor.saveInitialEditorDataState()
        EventEditor.checkIfSaveButtonShouldChangeState()
    }

    static beforeModalHides() {
        FirebaseAssistant.userIsProvider(isProv => {
            NavBar.activateNavBar(isProv)
            ShareButton.showButton()
        })
    }

    static afterModalHides() {
        document.getElementById('editorModalHeaderText').innerText = I18n.translateString('eventEditor.editorModalHeaderNewEvent')
        document.getElementById('eventTitle').value = ''
        document.getElementById('eventDescription').value = ''
        // document.getElementById('lastEdited').innerText = ''
        document.getElementById("back").click()
        document.querySelectorAll('.eventEditorFile').forEach(el => {
            el.remove()
        })
        window.editorCurrentEventId = undefined
        window.editorCurrentEventVersion = undefined
        window.editorCurrentEventShouldBeEditable = true
        window.editorCurrentEventShouldShowLastEditor = false;
        window.editorCreateNewTimeline_forUserId = undefined;
        window.localNewFileBucket = []
        window.cameraNewFileBucket = []
        window.cameraTransfersNewFileBucket = []
        window.flatpickr.destroy()
    }

    static saveEvent() { // todo alter this to use 'window.editorCreateNewTimeline_forUserId'
        FirebaseAssistant.userIsProvider(function (isProvider) {
            let thisIsANewProviderEvent = false
            const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(window.currentTimeline);
            const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(window.currentTimeline);
            const creator = FirebaseAssistant.getUserId()
            const title = document.getElementById('eventTitle').value
            const description = document.getElementById('eventDescription').value
            const timestamp = FirebaseAssistant.getCurrentTimestamp()
            const userAssignedTimestamp = FirebaseAssistant.getSpecificTimestamp(window.flatpickr.selectedDates[0])
            const location = I18n.getUsersCurrentLocation()
            const allFileNames = ['placeholder']; // this is because firebase rtdb does not support empty arrays
            let newTimelineIdToBeUsed;
            let thisIsAnewEvent = false
            Array.prototype.forEach.call(document.getElementsByClassName('eventAttachment'), (el) => {
                allFileNames.push(el.innerText)
            })
            if (window.editorCurrentEventVersion === undefined) { // this is a new event
                thisIsAnewEvent = true;
                if (window.editorCreateNewTimeline_forUserId !== undefined) { // provider tries to create a new event to someone else
                    newTimelineIdToBeUsed = MiscAssistant.getUniqueIdentifier();
                    window.currentTimeline = newTimelineIdToBeUsed
                    window.providerData[window.editorCreateNewTimeline_forUserId].timelines[newTimelineIdToBeUsed] = {
                        timelineName: document.getElementById('newTimeLineName').value,
                        isArchived: false,
                        timelineOwnershipHistory: {
                            0: {
                                owner: window.editorCreateNewTimeline_forUserId, ownershipStartedOn: {
                                    timestamp: timestamp, location: location
                                }
                            }
                        },
                        hasAccessToTimeline: [window.editorCreateNewTimeline_forUserId],
                        hasHadAccessToTimeline: [window.editorCreateNewTimeline_forUserId],
                        events: {placeholder: 'placeholder'}
                    }
                    thisIsANewProviderEvent = true

                    window.providerData[window.editorCreateNewTimeline_forUserId].timelines[newTimelineIdToBeUsed].events[window.editorCurrentEventId] = {
                        isArchived: false,
                        isDemo: false,
                        isCreatedFromProvider: creator,
                        eventCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        versions: {
                            0: {
                                versionCreator: creator,
                                versionCreationTimestamp: {
                                    timestamp: timestamp,
                                    location: location
                                },
                                userAssignedTimestamp: userAssignedTimestamp,
                                title: title,
                                description: description,
                                files: allFileNames
                            }
                        },
                        timelineTransferHistory: {
                            0: {
                                user: window.editorCreateNewTimeline_forUserId,
                                timelineId: newTimelineIdToBeUsed
                            }
                        }
                    }
                } else if (window.currentTimeline in window.kUser.timelines) {
                    window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId] = {
                        isArchived: false,
                        isDemo: false,
                        isCreatedFromProvider: false,
                        eventCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        versions: {
                            0: {
                                versionCreator: creator,
                                versionCreationTimestamp: {
                                    timestamp: timestamp,
                                    location: location
                                },
                                userAssignedTimestamp: userAssignedTimestamp,
                                title: title,
                                description: description,
                                files: allFileNames
                            }
                        },
                        timelineTransferHistory: {
                            0: {
                                user: FirebaseAssistant.getUserId(),
                                timelineId: window.currentTimeline
                            }
                        }
                    }
                } else if (timelineIsInUserProviderData[0]) {
                    const lastOwnership = Object.keys(window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].timelineOwnershipHistory).pop()
                    const owner = window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                    thisIsANewProviderEvent = true

                    window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId] = {
                        isArchived: false,
                        isDemo: false,
                        isCreatedFromProvider: creator,
                        eventCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        versions: {
                            0: {
                                versionCreator: creator,
                                versionCreationTimestamp: {
                                    timestamp: timestamp,
                                    location: location
                                },
                                userAssignedTimestamp: userAssignedTimestamp,
                                title: title,
                                description: description,
                                files: allFileNames
                            }
                        },
                        timelineTransferHistory: {
                            0: {
                                user: owner,
                                timelineId: window.currentTimeline
                            }
                        }
                    }
                } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
                    const lastOwnership = Object.keys(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].timelineOwnershipHistory).pop()
                    const owner = window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                    thisIsANewProviderEvent = true

                    window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId] = {
                        isArchived: false,
                        isDemo: false,
                        isCreatedFromProvider: creator,
                        eventCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        versions: {
                            0: {
                                versionCreator: creator,
                                versionCreationTimestamp: {
                                    timestamp: timestamp,
                                    location: location
                                },
                                userAssignedTimestamp: userAssignedTimestamp,
                                title: title,
                                description: description,
                                files: allFileNames
                            }
                        },
                        timelineTransferHistory: {
                            0: {
                                user: owner,
                                timelineId: window.currentTimeline
                            }
                        }
                    }
                } else if (FirebaseAssistant.timelineIsShared(window.currentTimeline)) {
                    const lastOwnership = Object.keys(window.sharedTimelines[window.currentTimeline].timelineOwnershipHistory).pop()
                    const owner = window.sharedTimelines[window.currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                    const isCreatedFromProvider = isProvider ? creator : false
                    thisIsANewProviderEvent = isCreatedFromProvider

                    window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId] = {
                        isArchived: false,
                        isDemo: false,
                        isCreatedFromProvider: isCreatedFromProvider,
                        eventCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        versions: {
                            0: {
                                versionCreator: creator,
                                versionCreationTimestamp: {
                                    timestamp: timestamp,
                                    location: location
                                },
                                userAssignedTimestamp: userAssignedTimestamp,
                                title: title,
                                description: description,
                                files: allFileNames
                            }
                        },
                        timelineTransferHistory: {
                            0: {
                                user: owner,
                                timelineId: window.currentTimeline
                            }
                        }
                    }
                }
            } else { //this is an existing event where we need to just increment the version
                const newVersion = parseInt(window.editorCurrentEventVersion) + 1
                if (window.currentTimeline in window.kUser.timelines) {
                    window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[newVersion] = {
                        versionCreator: creator,
                        versionCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        userAssignedTimestamp: userAssignedTimestamp,
                        title: title,
                        description: description,
                        files: allFileNames
                    }
                } else if (timelineIsInUserProviderData[0]) {
                    window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[newVersion] = {
                        versionCreator: creator,
                        versionCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        userAssignedTimestamp: userAssignedTimestamp,
                        title: title,
                        description: description,
                        files: allFileNames
                    }
                } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
                    window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[newVersion] = {
                        versionCreator: creator,
                        versionCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        userAssignedTimestamp: userAssignedTimestamp,
                        title: title,
                        description: description,
                        files: allFileNames
                    }
                } else if (FirebaseAssistant.timelineIsShared(window.currentTimeline)) {
                    window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions[newVersion] = {
                        versionCreator: creator,
                        versionCreationTimestamp: {
                            timestamp: timestamp,
                            location: location
                        },
                        userAssignedTimestamp: userAssignedTimestamp,
                        title: title,
                        description: description,
                        files: allFileNames
                    }
                }
            }

            const eventThatWasActiveInEditor = window.editorCurrentEventId;
            const timelineThatWasActiveInEditor = window.currentTimeline;

            let newFileNames = []
            let newLocalFiles = []
            let newCameraFiles = []
            let newCameraTransferredFiles = []
            Array.prototype.forEach.call(document.getElementsByClassName('new-file'), elem => {
                newFileNames.push(elem.closest('a').querySelector('.eventAttachment').innerText)
            })
            window.localNewFileBucket.forEach(file => {
                if (newFileNames.includes(file.name)) {
                    newLocalFiles.push(file)
                }
            })

            if (window.photosList === undefined) {
                window.photosList = []
            }
            window.cameraNewFileBucket.forEach(photo => {
                if (newFileNames.includes(photo[0])) {
                    newCameraFiles.push(photo)
                }
            })
            if (window.cameraTransfers === undefined) {
                window.cameraTransfers = []
            }
            window.cameraTransfersNewFileBucket.forEach(cTranfer => { // [name, localUrl, imageBlob]
                if (newFileNames.includes(cTranfer[0])) {
                    newCameraTransferredFiles.push(cTranfer)
                }
            })

            const currentTimeline = window.currentTimeline
            const currentEventId = window.editorCurrentEventId
            const currentEventVersion = window.editorCurrentEventVersion
            const editorCreateNewTimeline_forUserId = window.editorCreateNewTimeline_forUserId

            document.getElementById('cancel').click()

            TimelineContainer.showSavingChangesBanner()

            if (window.currentTimeline in window.kUser.timelines) {
                FirebaseAssistant.updateUserFirebaseData(function () { // firestore setting done
                    FirebaseAssistant.uploadCameraPhotosAndLocalFilesToEvent(currentTimeline, currentEventId, currentEventVersion, newCameraFiles, newLocalFiles, newCameraTransferredFiles, function () {
                        EventEditor.updateTimelineAfterEventIsSaved(thisIsAnewEvent, eventThatWasActiveInEditor, timelineThatWasActiveInEditor)
                        FirebaseAssistant.notifyPotentialSharedUsersOfTimelineUpdates(currentTimeline, timestamp)
                    })
                })
            } else {
                let lastOwnership, owner;
                if (editorCreateNewTimeline_forUserId !== undefined) {
                    owner = editorCreateNewTimeline_forUserId;
                    FirebaseAssistant.addNewlyCreatedTimelineFromProviderToOrphansDictionary(newTimelineIdToBeUsed, editorCreateNewTimeline_forUserId)
                } else if (timelineIsInUserProviderData[0]) {
                    lastOwnership = Object.keys(window.providerData[timelineIsInUserProviderData[1]].timelines[currentTimeline].timelineOwnershipHistory).pop()
                    owner = window.providerData[timelineIsInUserProviderData[1]].timelines[currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
                    lastOwnership = Object.keys(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[currentTimeline].timelineOwnershipHistory).pop()
                    owner = window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                } else if (FirebaseAssistant.timelineIsShared(currentTimeline)) {
                    lastOwnership = Object.keys(window.sharedTimelines[currentTimeline].timelineOwnershipHistory).pop()
                    owner = window.sharedTimelines[currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                }
                FirebaseAssistant.updateSharedTimelineData(currentTimeline, timestamp, function () { // firestore setting done
                    FirebaseAssistant.uploadCameraPhotosAndLocalFilesToSharedEvent(owner, currentTimeline, currentEventId, currentEventVersion, newCameraFiles, newLocalFiles, newCameraTransferredFiles, function () {
                        FirebaseAssistant.userIsProviderInitialFetch(false).then((isProvider_redundant_variable) => { // to get back correct provider events
                            if (editorCreateNewTimeline_forUserId === undefined) FirebaseAssistant.notifyPotentialSharedUsersOfTimelineUpdates(currentTimeline, timestamp)
                            if (thisIsANewProviderEvent) { // here we refetch and populate visual table data that do not already exist
                                if (editorCreateNewTimeline_forUserId !== undefined) {
                                    window.editorCreateNewTimeline_forUserId = undefined
                                    FirebaseAssistant.addEventEntryInClienteleUser(editorCreateNewTimeline_forUserId, newTimelineIdToBeUsed, currentEventId, () => {
                                        TimelineContainer.changeTimeline(newTimelineIdToBeUsed, ()=> {
                                            EventEditor.updateTimelineAfterEventIsSaved(thisIsAnewEvent, eventThatWasActiveInEditor, timelineThatWasActiveInEditor)
                                        })
                                        // LoadingSplasher.deactivateSplasher();
                                        // ClientsContainer.populateTableFromData(function (zet) {
                                        //     ClientsContainer.doneAddingUserEventToClientEventsList(editorCreateNewTimeline_forUserId, newTimelineIdToBeUsed, currentEventId)
                                        // })
                                    })
                                } else {
                                    FirebaseAssistant.addEventEntryInClienteleUser(owner, currentTimeline, currentEventId, () => {
                                        EventEditor.updateTimelineAfterEventIsSaved(thisIsAnewEvent, eventThatWasActiveInEditor, timelineThatWasActiveInEditor)
                                        ClientsContainer.populateTableFromData(function (zet) {
                                            ClientsContainer.doneAddingUserEventToClientEventsList(owner, currentTimeline, currentEventId)
                                        })
                                    })
                                }
                            } else {
                                EventEditor.updateTimelineAfterEventIsSaved(thisIsAnewEvent, eventThatWasActiveInEditor, timelineThatWasActiveInEditor)
                            }
                        })
                    })
                })
            }
        })
    }

    static translateShit() {
        document.getElementById('close').innerText = I18n.translateString('eventEditor.close')
        document.getElementById('back').innerText = I18n.translateString('eventEditor.back')
        document.getElementById('cancel').innerText = I18n.translateString('eventEditor.cancel')
        document.getElementById('save').innerText = I18n.translateString('eventEditor.save')
        document.getElementById('mobilePhotoTransferCancelationInstruction').innerHTML = I18n.translateString('eventEditor.mobilePhotoTransferCancelationInstruction')
        document.getElementById('userSmartphoneInstruction').innerText = I18n.translateString('eventEditor.userSmartphoneInstruction')
        document.getElementById('mobileTransferStep1').innerText = I18n.translateString('eventEditor.mobileTransferStep1')
        document.getElementById('mobileTransferStep2').innerText = I18n.translateString('eventEditor.mobileTransferStep2')
        document.getElementById('mobileTransferStep3').innerText = I18n.translateString('eventEditor.mobileTransferStep3')
        document.getElementById('mobileTransferStep4').innerText = I18n.translateString('eventEditor.mobileTransferStep4')
        document.getElementById('uploadThroughCamera').innerText = I18n.translateString('eventEditor.uploadThroughCamera')
        document.getElementById('uploadFiles').innerText = I18n.translateString('eventEditor.uploadFiles')
        document.getElementById('calendarPlaceholderLabelText').innerText = I18n.translateString('eventEditor.calendarPlaceholderLabelText')
        document.getElementById('eventDescriptionPlaceholderText').innerText = I18n.translateString('eventEditor.eventDescriptionPlaceholderText')
        document.getElementById('eventTitlePlaceholderText').innerText = I18n.translateString('eventEditor.eventTitlePlaceholderText')
        document.getElementById('newTimeLineNamePlaceholderText').innerText = I18n.translateString('eventEditor.newTimelineNamePlaceholderText')
    }

    static resizeActions() {
        if (document.querySelectorAll('event-editor').length !== 0) {
            if (window.innerHeight > window.innerWidth) {
                document.getElementById('editorModalHeader').style.display = ''
                document.getElementById('defaultModalBody').style.maxHeight = 'unset'
                document.getElementById('editorModalDialogWrapper').classList.add('modal-fullscreen')
                document.getElementById('exampleModal').style.zIndex = '1059'
                document.getElementById('defaultModalFooter').style.marginBottom = '2rem'
            } else {
                if (window.editorCurrentEventShouldShowLastEditor !== true)
                    document.getElementById('editorModalHeader').style.display = 'none'
                else
                    document.getElementById('editorModalHeader').style.display = ''
                document.getElementById('defaultModalBody').style.maxHeight = '35rem'
                document.getElementById('editorModalDialogWrapper').classList.remove('modal-fullscreen')
                document.getElementById('exampleModal').style.zIndex = '1055'
                document.getElementById('defaultModalFooter').style.marginBottom = 'unset'
            }
        }
    }

    static updateTimelineAfterEventIsSaved(thisIsAnewEvent, eventThatWasActiveInEditor, timelineThatWasActiveInEditor) {
        if ((window.currentTimeline === timelineThatWasActiveInEditor) && window.location.href.includes("timeline-page")) {
            TimelineContainer.redrawTimelines(eventThatWasActiveInEditor)
        }
    }

    static removeFileFromEditor(fileAtagElement) {
        fileAtagElement.remove();
        EventEditor.checkIfSaveButtonShouldChangeState()
    }

    static eventEditorIsActive() {
        return (document.querySelectorAll('event-editor .show').length !== 0)
    }

    static checkIfSaveButtonShouldChangeState() {
        if (window.editorCreateNewTimeline_forUserId !== undefined && document.getElementById('newTimeLineName').value === "") {
            EventEditor.deActivateSaveButton()
        } else if (document.getElementById('eventTitle').value === "") {
            EventEditor.deActivateSaveButton()
        } else if (window.editorCurrentEventId !== undefined) {
            let currentExistingFiles = document.getElementsByClassName("existing")
            currentExistingFiles = (currentExistingFiles === undefined) ? 0 : currentExistingFiles.length;
            const currentTitle = document.getElementById('eventTitle').value
            const currentDescription = document.getElementById('eventDescription').value
            const currentTimestamp = FirebaseAssistant.getSpecificTimestamp(window.flatpickr.selectedDates[0]).seconds;
            if (document.getElementsByClassName("new-file").length > 0) {
                EventEditor.activateSaveButton()
            } else if (currentExistingFiles < window.editorModalInitialFiles) {
                EventEditor.activateSaveButton()
            } else if (currentTitle !== window.editorModalInitialTitle || currentDescription !== window.editorModalInitialDescription) {
                EventEditor.activateSaveButton()
            } else if (currentTimestamp !== window.editorModalInitialUserAssignedTimestamp) {
                EventEditor.activateSaveButton();
            } else {
                EventEditor.deActivateSaveButton()
            }
        }
    }

    static activateSaveButton() {
        document.getElementById('close').style.display = 'none'
        document.getElementById('cancel').style.display = ''
        document.getElementById('save').style.display = ''
        document.getElementById("save").removeAttribute('disabled')
    }

    static deActivateSaveButton() {
        document.getElementById('close').style.display = ''
        document.getElementById('cancel').style.display = 'none'
        document.getElementById('save').style.display = 'none'
        document.getElementById("save").setAttribute('disabled', "")
    }

    static showFilesOnEditor(mode, files) {
        let existingDocuments = []
        document.querySelectorAll('.eventAttachment').forEach(el => {
            existingDocuments.push(el.innerText)
        })
        if (mode === 'local') {
            const localFileList = document.getElementById('myInput1').files;
            let allowedFileList = []
            // console.debug(localFileList)
            Array.prototype.forEach.call(localFileList, (file) => {
                if (file.size > 15728640) {
                    alert(`${I18n.translateString('eventEditor.theFile')} ${file.name} ${I18n.translateString('eventEditor.isTooBig')}`)
                } else {
                    if (allowedFiles.includes(file.name.split(".").pop().toLowerCase())) {
                        allowedFileList.push(file)
                    } else {
                        alert(`${I18n.translateString('eventEditor.theFile')} ${file.name} ${I18n.translateString('eventEditor.isNotSupported')} ${allowedFiles.join(" ").toString()}`)
                    }
                }
            })
            allowedFileList.forEach((file) => {
                if (existingDocuments.indexOf(file.name) === -1) {
                    const localUrl = URL.createObjectURL(file)
                    const reference = MiscAssistant.getUniqueIdentifier()
                    // window[reference] = [file.name, localUrl, "foo"];
                    document.getElementById('attachmentsGroup').insertAdjacentHTML("beforeend", `<a data-reference="${reference}"  type="button" class="btn btn-outline-secondary files position-relative custom-column mb-2 eventEditorFile" style="padding-right: 1rem"> <i class="${EventEditor.loadAppropriateBoxIcon(file.name)}"></i> <span class="eventAttachment" style="overflow: hidden">${file.name}</span> <span class="position-absolute top-0 start-100 translate-middle badge  rounded-pill bg-dark border cancel">x</span><span class="position-absolute top-50  translate-middle badge  rounded-pill bg-success border new-file" style=" left: 85%!important; ">new</span> </a>`)
                    const element = document.querySelector(`[data-reference="${reference}"]`)
                    element.setAttribute("onclick", `(function(e){ if(!e.target.classList.contains('cancel')) window.open("${localUrl}"); return false; })(arguments[0]);return false;`)
                    // console.debug(localUrl)
                    window.localNewFileBucket.push(file)
                    EventEditor.checkIfSaveButtonShouldChangeState()
                } else {
                    alert(`Το αρχείο "${file.name}" υπάρχει ήδη`)
                }
            })

        } else if (mode === 'camera') {
            window.photosList.forEach(photo => {
                fetch(photo[2]).then(res => res.blob()).then(photoBlob => {
                    const localUrl = URL.createObjectURL(photoBlob);
                    const reference = MiscAssistant.getUniqueIdentifier()
                    // window[reference] = [photo[0], localUrl, "foo"];
                    document.getElementById('attachmentsGroup').insertAdjacentHTML("beforeend", `<a data-reference="${reference}" type="button" class="btn btn-outline-secondary files position-relative custom-column mb-2 eventEditorFile" style="padding-right: 1rem"> <i class="bx bx-camera"></i> <span class="eventAttachment" style="overflow: hidden">${photo[0]}</span> <span class="position-absolute top-0 start-100 translate-middle badge  rounded-pill bg-dark border cancel">x</span><span class="position-absolute top-50  translate-middle badge  rounded-pill bg-success border new-file" style=" left: 85%!important; ">new</span> </a>`)
                    const element = document.querySelector(`[data-reference="${reference}"]`)
                    // element.setAttribute("onclick", `(function(e){ if(!e.target.classList.contains('cancel')) window.open("${localUrl}"); return false; })(arguments[0]);return false;`)
                    element.setAttribute("href", localUrl)
                    element.setAttribute("target", '_blank')
                    window.cameraNewFileBucket.push(photo)
                    EventEditor.checkIfSaveButtonShouldChangeState()
                })
            })
        } else if (mode === 'cameraTransfer') {
            files.forEach(image => {
                const reference = MiscAssistant.getUniqueIdentifier()
                if (image[0] !== 'start.txt' && image[0] !== 'done.txt') {
                    window[reference] = image;
                    document.getElementById('attachmentsGroup').insertAdjacentHTML("beforeend", `<a data-reference="${reference}" type="button" class="btn btn-outline-secondary files position-relative custom-column mb-2 eventEditorFile" style="padding-right: 1rem"> <i class="bx bx-camera"></i> <span class="eventAttachment" style="overflow: hidden">${image[0]}</span> <span class="position-absolute top-0 start-100 translate-middle badge  rounded-pill bg-dark border cancel">x</span><span class="position-absolute top-50  translate-middle badge  rounded-pill bg-success border new-file" style=" left: 85%!important; ">new</span> </a>`)
                    const element = document.querySelector(`[data-reference="${reference}"]`)
                    element.setAttribute("onclick", `(function(e){ if(!e.target.classList.contains('cancel')) window.open("${image[1]}"); return false; })(arguments[0]);return false;`)
                    window.cameraTransfersNewFileBucket.push(image)
                    EventEditor.checkIfSaveButtonShouldChangeState()
                }
            })
        } else if (mode === "existing") {
            // console.debug(files)
            Array.prototype.forEach.call(files, (file) => {
                // const name = file._location.path.split("/").pop()
                const name = file
                const reference = MiscAssistant.getUniqueIdentifier()
                // window[reference] = [name, "foo", file._location.path];
                document.getElementById('attachmentsGroup').insertAdjacentHTML("beforeend", `<a data-reference="${reference}" type="button" class="btn btn-outline-secondary files position-relative custom-column mb-2 eventEditorFile existing" style="padding-right: 1rem"> <i class="${EventEditor.loadAppropriateBoxIcon(name)}"></i> <span class="eventAttachment" style="overflow: hidden">${name}</span> <span class="position-absolute top-0 start-100 translate-middle badge  rounded-pill bg-dark border cancel">x</span> </a>`)
                const element = document.querySelector(`[data-reference="${reference}"]`)
                let owner;

                const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(window.currentTimeline);
                const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(window.currentTimeline);

                if (window.currentTimeline in window.kUser.timelines) {
                    owner = FirebaseAssistant.getUserId()
                    FirebaseAssistant.getFileDownloadUrl(owner, window.currentTimeline, window.editorCurrentEventId, window.editorCurrentEventVersion, file, function (downloadUrl) {
                        element.setAttribute("href", downloadUrl)
                        element.setAttribute("target", '_blank')
                    })
                } else if (timelineIsInUserProviderData[0]) {
                    const lastOwnership = Object.keys(window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].timelineOwnershipHistory).pop()
                    owner = window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                    FirebaseAssistant.getSharedFileDownloadUrl(owner, window.currentTimeline, window.editorCurrentEventId, window.editorCurrentEventVersion, file, function (downloadUrl) {
                        element.setAttribute("href", downloadUrl)
                        element.setAttribute("target", '_blank')
                    })
                } else if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
                    const lastOwnership = Object.keys(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].timelineOwnershipHistory).pop()
                    owner = window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                    FirebaseAssistant.getSharedFileDownloadUrl(owner, window.currentTimeline, window.editorCurrentEventId, window.editorCurrentEventVersion, file, function (downloadUrl) {
                        element.setAttribute("href", downloadUrl)
                        element.setAttribute("target", '_blank')
                    })
                } else if (FirebaseAssistant.timelineIsShared(window.currentTimeline)) {
                    const lastOwnership = Object.keys(window.sharedTimelines[window.currentTimeline].timelineOwnershipHistory).pop()
                    owner = window.sharedTimelines[window.currentTimeline].timelineOwnershipHistory[lastOwnership].owner
                    FirebaseAssistant.getSharedFileDownloadUrl(owner, window.currentTimeline, window.editorCurrentEventId, window.editorCurrentEventVersion, file, function (downloadUrl) {
                        element.setAttribute("href", downloadUrl)
                        element.setAttribute("target", '_blank')
                    })
                }
            })
        }
        const potentialNewItems = document.querySelectorAll('.new-file')
        if (potentialNewItems.length !== 0)
            potentialNewItems[potentialNewItems.length - 1].scrollIntoView({block: "end", behavior: "smooth"});
    }

    static activateEditorFromExistingEvent(existingEventCLicked = null) {

        const event = existingEventCLicked.target.closest('.clr-timeline-step')
        if (event.querySelectorAll('.blurredEntry').length !== 0) {
            FirebaseAssistant.userIsProvider(function (isProvider) {
                if (isProvider) {
                    if (window.currentTimeline in window.kUser.timelines) {
                        RouterAssistant.redirect('upgrade-page')
                    } else if (FirebaseAssistant.timelineIsArchived(window.currentTimeline)) {
                        UserNotifier.notifyUser(I18n.translateString('eventEditor.timelineIsArchivedTitle'), I18n.translateString('eventEditor.timelineIsArchivedDescription'))
                    } else {
                        UserNotifier.notifyUser(I18n.translateString('eventEditor.youNeedFullAccessTitle'), I18n.translateString('eventEditor.youNeedFullAccessDescription'))
                    }
                } else {
                    RouterAssistant.redirect('upgrade-page')
                }
            })
            return;
        }
        if (event.querySelectorAll('.blurredEntryIsShared').length !== 0 && event.querySelectorAll('.editable').length === 0) {
            // console.debug('f')
            window.editorCurrentEventShouldBeEditable = false;
            window.editorCurrentEventShouldShowLastEditor = true;
            // editorModalHeaderText changes here but within the function saveInitialEditorDataState
        } else if (event.querySelectorAll('.blurredEntryIsShared').length !== 0 && event.querySelectorAll('.editable').length !== 0) {
            // console.debug('g')
            window.editorCurrentEventShouldBeEditable = true;
            window.editorCurrentEventShouldShowLastEditor = true;
        } else {
            // console.debug('h')
            window.editorCurrentEventShouldBeEditable = true;
            window.editorCurrentEventShouldShowLastEditor = false;
            document.getElementById('editorModalHeaderText').innerText = I18n.translateString('eventEditor.editorModalHeaderEditEvent')
        }
        window.editorCurrentEventId = event.getAttribute('data-eventid')
        window.editorCurrentEventVersion = event.getAttribute('data-version')
        // const eventTimestamp = event.getElementsByClassName('clr-timeline-step-header')[0].innerHTML.trim()
        const title = event.getElementsByClassName('clr-timeline-step-title')[0].innerText.trim()
        let description = ''
        try {
            description = event.getElementsByClassName('clr-timeline-step-description')[0].childNodes[0].nodeValue.trim()
        } catch (e) {
        }
        FirebaseAssistant.getEventAttachmentsFileNames(window.currentTimeline, window.editorCurrentEventId, window.editorCurrentEventVersion, function (results) {
            EventEditor.populateEditorData(title, description)
            EventEditor.showFilesOnEditor("existing", results)
            window.editorActivationButton.click();
        })
    }

    static activateEditorCreatingAnewTimeline(userToCreateTheTimelineFor) {
        window.editorCreateNewTimeline_forUserId = userToCreateTheTimelineFor
        document.getElementById('newEventButton').click()
    }

    static takeAppropriateActionsIfEventIsFromProvider() {
        // console.debug(window.editorCurrentEventShouldBeEditable)
        if (window.editorCurrentEventShouldBeEditable !== undefined && !window.editorCurrentEventShouldBeEditable) {
            console.debug('k')
            document.getElementById('eventTitle').setAttribute('disabled', '');
            document.getElementById('eventDescription').setAttribute('disabled', '');
            document.getElementById('exampleModalLabel').setAttribute('disabled', '');
            document.getElementById('myInput1button').style.display = 'none'
            document.getElementById('myInput2button').style.display = 'none'
            // document.getElementById('close').style.display = ''
            // document.getElementById('cancel').style.display = 'none'
            // document.getElementById('save').style.display = 'none'
            document.querySelectorAll('.cancel').forEach(el => {
                el.style.display = 'none'
            })
        } else {
            // console.debug('j')
            document.getElementById('eventTitle').removeAttribute('disabled', '');
            document.getElementById('eventDescription').removeAttribute('disabled', '');
            document.getElementById('exampleModalLabel').removeAttribute('disabled', '');
            document.getElementById('myInput1button').style.display = ''
            document.getElementById('myInput2button').style.display = ''
            // document.getElementById('close').style.display = 'none'
            // document.getElementById('cancel').style.display = ''
            // document.getElementById('save').style.display = ''
        }
        EventEditor.resizeActions();
    }

    static saveInitialEditorDataState() {
        window.editorModalInitialTitle = document.getElementById('eventTitle').value
        window.editorModalInitialDescription = document.getElementById('eventDescription').value
        window.editorModalInitialFiles = document.getElementsByClassName("existing")
        window.editorModalInitialFiles = (window.editorModalInitialFiles === undefined) ? 0 : window.editorModalInitialFiles.length;
        window.editorModalInitialUserAssignedTimestamp = FirebaseAssistant.getSpecificTimestamp(window.flatpickr.selectedDates[0]).seconds
    }

    static populateEditorData(title, description) {
        document.getElementById('eventTitle').value = title
        document.getElementById('eventDescription').value = description

        const timelineIsInUserProviderData = FirebaseAssistant.timelineIsInUserProviderData(window.currentTimeline);
        const timelineIsInUserProviderDataButProviderHasNoEntries = FirebaseAssistant.timelineIsInUserProviderDataButProviderHasNoEntries(window.currentTimeline);

        let numeric, latestVersion;

        if (timelineIsInUserProviderDataButProviderHasNoEntries[0]) {
            latestVersion = Object.keys(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions).pop()
            if (window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                numeric = parseInt(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds) * 1000
            } else {
                numeric = parseInt(window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp._seconds) * 1000
            }
            if (window.editorCurrentEventShouldShowLastEditor)
                FirebaseAssistant.getUsernamesFromUids([window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator], function (results) {
                    document.getElementById('editorModalHeaderText').innerText = `${I18n.translateString('eventEditor.createdFrom')} ${results.users[window.providerData[timelineIsInUserProviderDataButProviderHasNoEntries[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator]}`
                })
        } else if (timelineIsInUserProviderData[0]) {
            latestVersion = Object.keys(window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions).pop()
            if (window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                numeric = parseInt(window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds) * 1000
            } else {
                numeric = parseInt(window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp._seconds) * 1000
            }
            if (window.editorCurrentEventShouldShowLastEditor)
                FirebaseAssistant.getUsernamesFromUids([window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator], function (results) {
                    document.getElementById('editorModalHeaderText').innerText = `${I18n.translateString('eventEditor.createdFrom')} ${results.users[window.providerData[timelineIsInUserProviderData[1]].timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator]}`
                })
        } else if (FirebaseAssistant.timelineIsShared(window.currentTimeline)) {
            latestVersion = Object.keys(window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions).pop()
            if (window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                numeric = parseInt(window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds) * 1000
            } else {
                numeric = parseInt(window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp._seconds) * 1000
            }
            if (window.editorCurrentEventShouldShowLastEditor)
                FirebaseAssistant.getUsernamesFromUids([window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator], function (results) {
                    document.getElementById('editorModalHeaderText').innerText = `${I18n.translateString('eventEditor.createdFrom')} ${results.users[window.sharedTimelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator]}`
                })
        } else if (window.currentTimeline in window.kUser.timelines) {
            latestVersion = Object.keys(window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions).pop()
            if (window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds !== undefined) {
                numeric = parseInt(window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp.seconds) * 1000
            } else {
                numeric = parseInt(window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].userAssignedTimestamp._seconds) * 1000
            }
            if (window.editorCurrentEventShouldShowLastEditor)
                FirebaseAssistant.getUsernamesFromUids([window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator], function (results) {
                    document.getElementById('editorModalHeaderText').innerText = `${I18n.translateString('eventEditor.createdFrom')} ${results.users[window.kUser.timelines[window.currentTimeline].events[window.editorCurrentEventId].versions[latestVersion].versionCreator]}`
                })
        }

        const rightNow = new Date();
        const localeToBeUsed = (I18n.getUsersPreferredLanguage() === 'el') ? Greek : english;
        window.flatpickr = flatpickr(document.getElementById('exampleModalLabel'), {
            "locale": localeToBeUsed,
            enableTime: true,
            maxDate: rightNow,
            // time_24hr: true,
            dateFormat: flatpickrFormat
        })
        window.flatpickr.setDate(new Date(numeric));
        document.getElementById('exampleModalLabel').value = window.flatpickr.formatDate(new Date(numeric), flatpickrFormat)

        // const timestamp = new Date(numeric).toString();
        // const foo = timestamp.split(" ");
        // const translationKey = `timelinePage.timestamps.months.${foo[1]}`
        // const timestamp1 = `${foo[2]} ${I18n.translateString(translationKey)} ${foo[3]} ` // this space at the end is by design !
        // const timestamp1 = `${foo[2]} ${foo[1]} ${foo[3]} `
        // const timestamp2 = foo[4].split(":").slice(0, 2).join(":")
        // document.getElementById('lastEdited').innerText = `Τροποποιήθηκε ${timestamp1} ${timestamp2}`
    }

    static registerActivationButton(idOfTheButton) {
        const el = document.getElementById(idOfTheButton);
        el.setAttribute("data-bs-toggle", "modal")
        el.setAttribute("data-bs-target", "#exampleModal")
        window.editorActivationButton = el;
    }

    static loadAppropriateBoxIcon(fileName) {
        const suffix = fileName.split(".").pop().toLowerCase();
        switch (suffix) {
            case 'pdf':
                return "bx bxs-file-pdf"
            case 'doc':
            case 'docx':
            case 'odt':
                return "bx bxs-file-doc"
            case 'ppt':
            case 'pptx':
            case 'odp':
                return "bx bx-spreadsheet"
            case 'xls':
            case 'xlsx':
            case 'ods':
            case 'csv':
                return "bx bx-slideshow"
            case 'txt':
                return "bx bxs-file-txt"
            case 'png':
                return "bx bxs-file-png"
            case 'jpg':
            case 'jpeg':
                return "bx bxs-file-jpg"
            case 'gid':
                return "bx bxs-file-gif"
            case 'mp3':
                return "bx bx-music"
            case 'mp4':
                return "bx bxs-videos"
            default:
                return "bx bx-file-blank"
        }
    }

    static activateModalQrCodeOverlay() {
        window.mobileTransferGuid = MiscAssistant.getUniqueIdentifier()
        new QRCode(document.getElementById("qrcode"), {
            text: `${window.location.origin}/uploader/${FirebaseAssistant.getUserId()}/${window.mobileTransferGuid}`,
            width: 200,
            height: 200,
            colorDark: "#FFFFFF",
            colorLight: "#1E2125",
            correctLevel: QRCode.CorrectLevel.L
        });
        document.getElementById('defaultModalBody').style.display = 'none'
        document.getElementById('qrModalBody').style.display = ''

        document.getElementById('cancel').style.display = 'none'
        document.getElementById('save').style.display = 'none'
        document.getElementById('back').style.display = ''
    }
}

EventEditor.template = /*html*/ `                   
   <div class="modal fade" id="exampleModal"  data-bs-backdrop="static" data-bs-keyboard="false"   aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 1055;">
    <div id="editorModalDialogWrapper" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div id="drop-area" class="modal-content" >
            <div id="editorModalHeader" class="modal-header"  style="display: none">
                <h1 id="editorModalHeaderText" class="modal-title fs-5">Νέα καταχώρηση</h1>
<!--                <h1 id="editorModalHeaderText" class="modal-title fs-5"  data-bs-toggle="collapse" data-bs-target="#lastEdited" aria-expanded="false" aria-controls="lastEdited"></h1>-->
<!--                <span class="badge rounded-pill text-bg-secondary collapse" id="lastEdited" style="margin-left: 20px; font-weight: normal;"></span>-->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="display: none"></button>
            </div>
            <div id="defaultModalBody" class="modal-body">
                <form>
                    <div id="newTimeLineNameContainer" class="form-floating mb-3" style="display: none">
                        <input id="newTimeLineName" type="text" placeholder="do not remove this" class="form-control" style="border-top: none; border-right: none; border-left: none">
                        <label id="newTimeLineNamePlaceholderText" for="newTimeLineName"></label>
                    </div>
                     <div class="form-floating mb-3">
                        <input id="eventTitle" type="text" placeholder="do not remove this" class="form-control" style="border-top: none; border-right: none; border-left: none">
                        <label id="eventTitlePlaceholderText" for="eventTitle"></label>
                    </div>
                    <div class="form-floating mb-3">
                        <textarea class="form-control" placeholder="do not remove this" id="eventDescription" style="height: 200px"></textarea>
                        <label id="eventDescriptionPlaceholderText" for="eventDescription"></label>
                    </div>
                    <div class="form-floating mb-3">
                        <input id="exampleModalLabel" type="email" class="form-control" placeholder="foo" >
                        <label id="calendarPlaceholderLabelText" for="exampleModalLabel"></label>
                    </div>
                </form>
                <div id="attachmentsGroup" class="flex-row">
                    <button id="myInput1button" type="button" class="btn btn-outline-secondary files position-relative custom-column mb-2" style="padding-right: 0;padding-left: 0.4rem;margin-right: -0.1rem">
                        <i class='bx bx-plus-medical' style="color: #0079b8; margin-right: 15px;font-size: 25px"></i>
                        <span id="uploadFiles">Προσθήκη αρχείων</span>
                        <input id="myInput1" type="file" accept=".${allowedFiles.join(", .")}" multiple="multiple" style="display:none" />
                    </button> 
                    <button id="myInput2button" type="button" class="btn btn-outline-secondary files position-relative custom-column mb-2" style="padding-right: 0;padding-left: 0.2rem">
                        <i class='bx bx-mobile-alt' style="color: #0079b8;margin-right: 15px"></i>
                        <span id="uploadThroughCamera">Προσθήκη μέσω κάμερας</span>
                        <input id="myInput2" type="file" style="display:none" />
                    </button> 
                </div>
            </div>
            <div id="qrModalBody" class="modal-body container" style="display: none">
                <div  class="row">
                    <div class="col-12">
                        <span id="userSmartphoneInstruction">Χρησιμοποιηστε ενα οποιοδηποτε smartphone για να ανεβασετε αρχεια στην καταχωρηση:</span>
                        <br>
                        <br><span id="mobileTransferStep1">1. Σκαναρετε τον QR code που φαινεται παρακατω</span>
                        <br><span id="mobileTransferStep2">2. Τραβηξτε τις φωτογραφιες που θελετε</span>
                        <br><span id="mobileTransferStep3">3. Πατηστε στο κουμπι "Αποστολη Χ φωτογραφιων"</span>
                        <br><span id="mobileTransferStep4">4. Oι φωτογραφιες θα εμφανιστουν εδω αυτοματα</span>
                        <br>
                        <br>
                    </div>
                </div>
                <div  class="row justify-content-center text-center">
                    <div class="col-6 d-flex justify-content-center" id="qrcode">
                    </div>
                    <span id="mobilePhotoTransferCancelationInstruction">Εαν θελετε να ακυρωσετε την μεταφορτωση μεσω smartphone πατηστε στο κουμπι 'πίσω'</span>
                </div>
            </div>
            <div id="defaultModalFooter" class="modal-footer">
                <button id="close" type="button" class="btn btn-secondary" data-bs-dismiss="modal"></button>
                <button id="back" type="button" class="btn btn-secondary" style="display: none"></button>
                <button id="cancel" type="button" class="btn btn-secondary" style="display: none" data-bs-dismiss="modal"></button>
                <button id="save" type="button" class="btn btn-primary" style="display: none" disabled></button>
            </div>
        </div>
    </div>
</div>
`;

EventEditor.reg('event-editor');

