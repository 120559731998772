import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant";

const icon = require('../pwa-guard/assets/icons/any/icon-32x32.png')

const css = `
<style>
</style>
 `

export class AnnouncementAssistant extends BaseComponent { //todo  to be extended later in order to be a generic announcement component

    static initiate(){
        document.body.insertAdjacentHTML("beforeend", "<announcement-assistant></announcement-assistant>")
    }

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        window.announcementModal = new window.bootstrap.Modal(document.getElementById('announcementModal'))
        AnnouncementAssistant.translateShit()
    }

    static makeTrialAnnouncement(){ // todo edit to showcase correct provider data
        window.announcementModal.show();
    }

    static translateShit(){
        FirebaseAssistant.userIsProvider((isProvider)=> {
            if (isProvider) {
                document.getElementById('trialAwarenessDescription1').innerText = I18n.translateString('announcementAssistant.trialAwarenessDescriptionProv')
                document.getElementById('perk1').innerText = I18n.translateString('announcementAssistant.perk1prov')
                document.getElementById('perk2').innerText = I18n.translateString('announcementAssistant.perk2prov')
                document.getElementById('perk3').innerText = I18n.translateString('announcementAssistant.perk3prov')
            } else {
                document.getElementById('trialAwarenessDescription1').innerText = I18n.translateString('announcementAssistant.trialAwarenessDescription')
                document.getElementById('perk1').innerText = I18n.translateString('announcementAssistant.perk1')
                document.getElementById('perk2').innerText = I18n.translateString('announcementAssistant.perk2')
                document.getElementById('perk3').innerText = I18n.translateString('announcementAssistant.perk3')
            }
        })
        document.getElementById('trialAwarenessTitle').innerText = I18n.translateString('announcementAssistant.trialAwarenessHeader')
        document.getElementById('callToAction').innerText = I18n.translateString('announcementAssistant.confirmationButton')
    }
}


AnnouncementAssistant.template = /*html*/ `
    <div class="modal fade" id="announcementModal" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="card">
                    <div class="card-body">
                        <h2  id="trialAwarenessTitle" class="card-title mt-3 mb-4" style="margin-right: 2rem; /* font-size: 2.4rem; */ font-weight: bold; left: 2rem; position: relative;">Welcome, here's a gift!</h2>
                        <div style="margin-top: 2.4rem; position: relative; left: 2rem; margin-right: 2rem; width: fit-content;">
                            <p id="trialAwarenessDescription1" class="card-text">Upon registering you get 3 months of PRO, on us!</p>
                            <ul>
                                <li>
                                    <p id="perk1" class="card-text">Having multiple timelines</p>
                                </li>
                                <li>
                                    <p id="perk2" class="card-text">Sharing your timelines with others</p>
                                </li>
                                <li>
                                    <p id="perk3" class="card-text">Accessing timelines that were shared to you</p>
                                </li>
                            </ul>
                        </div>
                        <a id="callToAction" class="mt-3 btn btn-primary" data-bs-dismiss="modal" style="transform: translateX(-50%); position: relative; left: 50%;">Got it</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;

AnnouncementAssistant.reg('announcement-assistant');
