import {BaseComponent} from "@symbiotejs/symbiote";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";
import {I18n} from "../../utilities/i18n/i18n";
import {NavBar} from "../nav-bar/nav-bar";

const buttonActive = require('./assets/icon-512x512-transparent.png')

const mobileDownloadSplasher = {}
mobileDownloadSplasher['splashEl'] = require('./assets/getTheMobileApp_el.png')
mobileDownloadSplasher['splashEn'] = require('./assets/getTheMobileApp_en.png')

const css = `
    <style>
        #activateShareSplasher:hover{
            cursor: pointer;
            opacity: 1!important;
        }
        
        #splashShareImageContainer{
            transition: opacity 0.5s ;
        }
    </style>
`
export class ShareButton extends BaseComponent {
    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        document.getElementById('activateShareSplasher').addEventListener('click', ShareButton.buttonClicked)
        document.getElementById('cancelShareSplasher').addEventListener('click', ShareButton.closeSplasher)
        window.addEventListener('resize', ShareButton.resizeActions)
        ShareButton.resizeActions()
    }

    static initiate(){
        document.body.insertAdjacentHTML('beforeend', '<share-button></share-button>')
        document.getElementById('splasherImageDownloadMobile').src = mobileDownloadSplasher[I18n.translateString('shareMobileDownload.imgSrc')]
    }

    static resizeActions() {
        if (NavBar.navBarIsActive()) {
            if (window.innerHeight > window.innerWidth) {
                ShareButton.hideButton()
            } else {
                ShareButton.showButton()
            }
        }
    }

    static showButton(){
        if (window.innerWidth > window.innerHeight)
            document.getElementById('activateShareSplasher').style.display = ''
    }

    static hideButton(){
        document.getElementById('activateShareSplasher').style.display = 'none'
    }

    static buttonClicked() {
        RouterAssistant.activateBlueCurtain(1, 1060)
        document.getElementById('cancelShareSplasher').style.display = ''
        document.getElementById('splashShareImageContainer').style.opacity = '1'
        document.getElementById('splashShareImageContainer').style.zIndex = '1060'
    }

    static closeSplasher() {
        RouterAssistant.deactivateCurtain()
        document.getElementById('cancelShareSplasher').style.display = 'none'
        document.getElementById('splashShareImageContainer').style.opacity = '0'
        setTimeout(()=> {document.getElementById('splashShareImageContainer').style.zIndex = '-5'}, 1000)

    }
}

ShareButton.template = /*html*/ `
    <button id="cancelShareSplasher" style="display:none;font-size:xx-large;position:fixed;z-index: 1061;top: 2rem;right: 2rem" type="button" class="btn-close" aria-label="Close"></button>
    <div id="activateShareSplasher" class=" rounded-circle overflow-hidden" style="display: none;max-width: 3.3rem;position: fixed;bottom: 1rem;right: 2rem;border: 1px solid #4fa9ff; opacity: 0.5;">
        <img src="${buttonActive}" class="card-img-top" style="object-fit:cover" alt="s">
    </div>
    <div id="splashShareImageContainer" class=" overflow-hidden" style="max-width: 60rem;position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);z-index: -5;opacity: 0">
        <img id="splasherImageDownloadMobile" src="" class="card-img-top" style="object-fit:cover" alt="s">
    </div>
`;

ShareButton.reg('share-button');
