export const allowedFiles = [
    "doc",
    "docx",
    "odf",
    "odp",
    "odt",
    "jpg",
    "jpeg",
    "png",
    "pdf",
    "mp3",
    "gif",
    "mp4",
    "csv",
    "txt",
    "dat",
    "xls",
    "xlsx",
    "ppt",
    "pptx"
]
