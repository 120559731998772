import {PwaGuard} from "../../components/pwa-guard/pwa-guard";

export class SecurityAssistant {
    static initiate() {

        document.body.style.userSelect = 'none';
        document.body.style.webkitUserSelect = 'none';

        if (PwaGuard.accessIsThroughPwa() && PwaGuard.accessIsThroughMobile()) {
            // do nothing
        } else {
            document.addEventListener('contextmenu', event => {
                event.preventDefault();
            });
        }
    }
}
