import { SvelteComponent, init, safe_not_equal, noop, listen, is_function, run_all, component_subscribe, globals, set_store_value, bubble, subscribe, empty, insert, detach, element, attr, create_component, space, mount_component, transition_in, group_outros, transition_out, check_outros, destroy_component, binding_callbacks } from 'svelte/internal';
import { getContext } from 'svelte';
import { ancestor, addDuration, cloneDate, addDay, rect, createDuration, toEventWithLocalDates, toISOString, toViewWithLocalDates, cloneEvent, assign } from '@event-calendar/common';

function traverseTimeGrid(dayEl) {
    let colEl = child(ancestor(dayEl, 1), 1);
    let bodyEl = ancestor(dayEl, 3);
    let col = pos(dayEl) - 1;

    return [colEl, bodyEl, col];
}

function traverseResourceTimeGrid(dayEl, datesAboveResources) {
    let colEl = child(child(ancestor(dayEl, 2), 1), 0);
    let resourceEl = ancestor(dayEl, 1);
    let bodyEl = ancestor(resourceEl, 3);
    let col = pos(dayEl);
    let resourceCol = pos(resourceEl) - 1;

    return datesAboveResources ? [colEl, bodyEl, resourceCol, col] : [colEl, bodyEl, col, resourceCol];
}

function traverseDayGrid(dayEl) {
    let daysEl = ancestor(dayEl, 1);
    let contentEl = ancestor(daysEl, 1);
    let colEl = child(child(contentEl, 0), 0);
    let bodyEl = ancestor(contentEl, 1);
    let col = pos(dayEl);
    let row = pos(daysEl);

    return [colEl, bodyEl, col, row, contentEl.children];
}

function child(el, pos) {
    return el.children[pos];
}

function pos(el) {
    let result = 0;
    while ((el = el.previousElementSibling)) {
        ++result;
    }
    return result;
}

let busy = false;
function animate(fn) {
    if (!busy) {
        busy = true;
        window.requestAnimationFrame(() => {
            fn();
            busy = false;
        });
    }
}

function limit(value, max) {
    return Math.max(0, Math.min(max, value));
}

function floor(value) {
    return Math.floor(value);
}

/* packages/interaction/src/Action.svelte generated by Svelte v3.55.1 */

const { window: window_1 } = globals;

function create_fragment$3(ctx) {
	let mounted;
	let dispose;

	return {
		c: noop,
		m(target, anchor) {
			if (!mounted) {
				dispose = [
					listen(window_1, "pointermove", /*handlePointerMove*/ ctx[31]),
					listen(window_1, "pointerup", /*handlePointerUp*/ ctx[32]),
					listen(window_1, "pointercancel", /*handlePointerUp*/ ctx[32]),
					listen(window_1, "scroll", /*handleScroll*/ ctx[0]),
					listen(window_1, "selectstart", function () {
						if (is_function(createPreventDefaultHandler(/*selectstart_handler*/ ctx[40]))) createPreventDefaultHandler(/*selectstart_handler*/ ctx[40]).apply(this, arguments);
					}),
					listen(window_1, "contextmenu", function () {
						if (is_function(createPreventDefaultHandler(/*contextmenu_handler*/ ctx[41]))) createPreventDefaultHandler(/*contextmenu_handler*/ ctx[41]).apply(this, arguments);
					}),
					listen(window_1, "touchstart", /*handleTouchStart*/ ctx[33]),
					listen(window_1, "touchmove", /*touchmove_handler*/ ctx[39], { passive: false })
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		i: noop,
		o: noop,
		d(detaching) {
			mounted = false;
			run_all(dispose);
		}
	};
}

const ACTION_DRAG = 1;
const ACTION_RESIZE = 2;
const ACTION_SELECT = 3;
const VIEW_TIME_GRID = 1;
const VIEW_DAY_GRID = 2;

function createPreventDefaultHandler(condition) {
	return jsEvent => {
		if (condition()) {
			jsEvent.preventDefault();
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $_view;
	let $unselectFn;
	let $_events;
	let $_iEvents;
	let $selectBackgroundColor;
	let $_iClass;

	let $_viewResources,
		$$unsubscribe__viewResources = noop,
		$$subscribe__viewResources = () => ($$unsubscribe__viewResources(), $$unsubscribe__viewResources = subscribe(_viewResources, $$value => $$invalidate(71, $_viewResources = $$value)), _viewResources);

	let $eventDrop;
	let $eventResize;
	let $eventDragStop;
	let $eventResizeStop;
	let $select;
	let $unselectCancel;
	let $unselectAuto;
	let $dragScroll;
	let $eventDragStart;
	let $eventResizeStart;
	let $_viewDates;
	let $hiddenDays;
	let $slotDuration;
	let $slotHeight;
	let $datesAboveResources;
	let $_ignoreClick;
	let $eventDragMinDistance;
	let $selectMinDistance;
	let $longPressDelay;
	let $eventLongPressDelay;
	let $selectLongPressDelay;
	$$self.$$.on_destroy.push(() => $$unsubscribe__viewResources());
	let { _iEvents, _iClass, _ignoreClick, _events, _viewDates, _view, datesAboveResources, dragScroll, editable, eventStartEditable, eventDragMinDistance, eventDragStart, eventDragStop, eventDrop, eventLongPressDelay, eventResizeStart, eventResizeStop, eventResize, longPressDelay, select, selectBackgroundColor, selectLongPressDelay, selectMinDistance, slotDuration, slotHeight, hiddenDays, unselect: unselectFn, unselectAuto, unselectCancel } = getContext('state');
	component_subscribe($$self, _iEvents, value => $$invalidate(68, $_iEvents = value));
	component_subscribe($$self, _iClass, value => $$invalidate(70, $_iClass = value));
	component_subscribe($$self, _ignoreClick, value => $$invalidate(87, $_ignoreClick = value));
	component_subscribe($$self, _events, value => $$invalidate(67, $_events = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(82, $_viewDates = value));
	component_subscribe($$self, _view, value => $$invalidate(65, $_view = value));
	component_subscribe($$self, datesAboveResources, value => $$invalidate(86, $datesAboveResources = value));
	component_subscribe($$self, dragScroll, value => $$invalidate(79, $dragScroll = value));
	component_subscribe($$self, eventDragMinDistance, value => $$invalidate(88, $eventDragMinDistance = value));
	component_subscribe($$self, eventDragStart, value => $$invalidate(80, $eventDragStart = value));
	component_subscribe($$self, eventDragStop, value => $$invalidate(74, $eventDragStop = value));
	component_subscribe($$self, eventDrop, value => $$invalidate(72, $eventDrop = value));
	component_subscribe($$self, eventLongPressDelay, value => $$invalidate(91, $eventLongPressDelay = value));
	component_subscribe($$self, eventResizeStart, value => $$invalidate(81, $eventResizeStart = value));
	component_subscribe($$self, eventResizeStop, value => $$invalidate(75, $eventResizeStop = value));
	component_subscribe($$self, eventResize, value => $$invalidate(73, $eventResize = value));
	component_subscribe($$self, longPressDelay, value => $$invalidate(90, $longPressDelay = value));
	component_subscribe($$self, select, value => $$invalidate(76, $select = value));
	component_subscribe($$self, selectBackgroundColor, value => $$invalidate(69, $selectBackgroundColor = value));
	component_subscribe($$self, selectLongPressDelay, value => $$invalidate(92, $selectLongPressDelay = value));
	component_subscribe($$self, selectMinDistance, value => $$invalidate(89, $selectMinDistance = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(84, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(85, $slotHeight = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(83, $hiddenDays = value));
	component_subscribe($$self, unselectFn, value => $$invalidate(66, $unselectFn = value));
	component_subscribe($$self, unselectAuto, value => $$invalidate(78, $unselectAuto = value));
	component_subscribe($$self, unselectCancel, value => $$invalidate(77, $unselectCancel = value));
	let action;
	let view;
	let interacting;
	let event;
	let col, row;
	let offsetX, offsetY;
	let fromX, fromY;
	let toX, toY;
	let delta;
	let colEl, rowEls, bodyEl;
	let colRect, bodyRect;
	let _viewResources;
	let resourceCol, newResourceCol;
	let isAllDay = false;
	let iClass;
	let minEnd; // minimum end time when resizing
	let selected; // whether selection has been made
	let timer; // timer for long press delays

	function dragTimeGrid(eventToDrag, el, jsEvent, resourcesStore, allDay, resize) {
		if (!action && jsEvent.isPrimary) {
			$$invalidate(1, action = resize ? ACTION_RESIZE : ACTION_DRAG);
			view = VIEW_TIME_GRID;
			event = eventToDrag;
			$$subscribe__viewResources($$invalidate(2, _viewResources = resourcesStore));
			isAllDay = allDay;
			iClass = resize ? allDay ? 'resizingX' : 'resizingY' : 'dragging';
			let dayEl = ancestor(el, 2);

			if (resourcesStore) {
				[colEl, bodyEl, col, resourceCol] = traverseResourceTimeGrid(dayEl, $datesAboveResources);
			} else {
				[colEl, bodyEl, col] = traverseTimeGrid(dayEl);
			}

			common(jsEvent);
			offsetY = floor((jsEvent.clientY - colRect.top) / $slotHeight);
			offsetX = 0; // applicable for all-day slot

			if (allDay && (!resourcesStore || !$datesAboveResources)) {
				offsetX = floor((jsEvent.clientX - colRect.left) / colRect.width) - col - (resourceCol || 0) * $_viewDates.length;
			}

			if (resize) {
				minEnd = addDuration(cloneDate(event.start), $slotDuration);
			}

			move(jsEvent);
		}
	}

	function dragDayGrid(eventToDrag, el, jsEvent, inPopup, resize) {
		if (!action && jsEvent.isPrimary) {
			$$invalidate(1, action = resize ? ACTION_RESIZE : ACTION_DRAG);
			view = VIEW_DAY_GRID;
			event = eventToDrag;
			iClass = resize ? 'resizingX' : 'dragging';
			let dayEl = ancestor(el, inPopup ? 3 : 2);
			[colEl, bodyEl, col, row, rowEls] = traverseDayGrid(dayEl);
			common(jsEvent);

			offsetX = inPopup
			? 0
			: floor((jsEvent.clientX - colRect.left) / colRect.width) - col;

			if (resize) {
				minEnd = cloneDate(event.start);
				minEnd.setUTCHours(event.end.getUTCHours(), event.end.getUTCMinutes(), event.end.getUTCSeconds());

				if (minEnd < event.start) {
					addDay(minEnd);
				} // minEnd = addDuration(cloneDate(event.start), $slotDuration);  alternative version
			}

			move(jsEvent);
		}
	}

	function selectTimeGrid(colDate, dayEl, jsEvent, resourcesStore, _slotTimeLimits, allDay) {
		if (!action && jsEvent.isPrimary) {
			$$invalidate(1, action = ACTION_SELECT);
			view = VIEW_TIME_GRID;
			$$subscribe__viewResources($$invalidate(2, _viewResources = resourcesStore));
			isAllDay = allDay;
			iClass = 'selecting';

			if (resourcesStore) {
				[colEl, bodyEl, col, resourceCol] = traverseResourceTimeGrid(dayEl, $datesAboveResources);
			} else {
				[colEl, bodyEl, col] = traverseTimeGrid(dayEl);
			}

			common(jsEvent);
			offsetY = floor((jsEvent.clientY - colRect.top) / $slotHeight);
			offsetX = 0; // applicable for all-day slot

			if (allDay && (!resourcesStore || !$datesAboveResources)) {
				offsetX = floor((jsEvent.clientX - colRect.left) / colRect.width) - col - (resourceCol || 0) * $_viewDates.length;
			}

			// Create dummy source event
			let date = cloneDate(colDate);

			if (!allDay) {
				addDuration(date, $slotDuration, offsetY + _slotTimeLimits.min.seconds / $slotDuration.seconds);
			}

			event = {
				allDay,
				start: date,
				end: allDay
				? addDay(cloneDate(date))
				: addDuration(cloneDate(date), $slotDuration),
				resourceIds: _viewResources ? [$_viewResources[resourceCol].id] : []
			};

			move(jsEvent);
		}
	}

	function selectDayGrid(dayDate, dayEl, jsEvent) {
		if (!action && jsEvent.isPrimary) {
			$$invalidate(1, action = ACTION_SELECT);
			view = VIEW_DAY_GRID;
			iClass = 'selecting';
			[colEl, bodyEl, col, row, rowEls] = traverseDayGrid(dayEl);
			common(jsEvent);
			offsetX = floor((jsEvent.clientX - colRect.left) / colRect.width) - col;

			// Create dummy source event
			let date = cloneDate(dayDate);

			event = {
				allDay: false,
				start: date,
				end: addDay(cloneDate(date)),
				resourceIds: []
			};

			move(jsEvent);
		}
	}

	function common(jsEvent) {
		window.getSelection().removeAllRanges();
		colRect = rect(colEl);
		bodyRect = rect(bodyEl);
		fromX = toX = jsEvent.clientX;
		fromY = toY = jsEvent.clientY;

		if (jsEvent.pointerType !== 'mouse') {
			// For touch devices init long press delay
			$$invalidate(3, timer = setTimeout(
				() => {
					if (action) {
						interacting = true;
						move(jsEvent);
					}
				},
				(selecting()
				? $selectLongPressDelay
				: $eventLongPressDelay) ?? $longPressDelay
			));
		}
	}

	function move(jsEvent) {
		let rx = toX - colRect.left;
		let ry = toY - colRect.top;

		if (interacting || jsEvent && jsEvent.pointerType === 'mouse' && distance() >= (selecting() ? $selectMinDistance : $eventDragMinDistance)) {
			interacting = true;
			unselect(jsEvent);
			set_store_value(_iClass, $_iClass = iClass, $_iClass);
			set_store_value(_ignoreClick, $_ignoreClick = true, $_ignoreClick);
			let newCol = floor(rx / colRect.width);

			if (view === VIEW_TIME_GRID) {
				// timeGrid
				if (_viewResources) {
					if (dragging()) {
						if ($datesAboveResources) {
							let dayCol = limit(floor(newCol / $_viewResources.length), $_viewDates.length - 1);
							newResourceCol = limit(newCol - dayCol * $_viewResources.length, $_viewResources.length - 1);
							newCol = dayCol;
						} else {
							newResourceCol = limit(floor(newCol / $_viewDates.length), $_viewResources.length - 1);
							newCol -= newResourceCol * $_viewDates.length;
						}
					} else {
						if ($datesAboveResources) {
							newCol = floor(newCol / $_viewResources.length);
						} else {
							newCol -= resourceCol * $_viewDates.length;
						}
					}
				}

				newCol = limit(newCol, $_viewDates.length - 1);

				delta = createDuration({
					days: ($_viewDates[newCol] - $_viewDates[col]) / 1000 / 60 / 60 / 24 - offsetX,
					seconds: isAllDay
					? 0
					: (floor(ry / $slotHeight) - offsetY) * $slotDuration.seconds
				});
			} else {
				// dayGrid
				let cols = 7 - $hiddenDays.length;

				newCol = limit(newCol, cols - 1);
				let newRow = -1;

				do {
					++newRow;
					ry -= rowEls[newRow].offsetHeight;
				} while (ry > 0 && newRow < rowEls.length - 1);

				delta = createDuration({
					days: ($_viewDates[newRow * cols + newCol] - $_viewDates[row * cols + col]) / 1000 / 60 / 60 / 24 - offsetX
				});
			}

			if (!$_iEvents[0]) {
				if (selecting()) {
					createIEventSelect();
				} else {
					createIEvent(jsEvent, resizing() ? $eventResizeStart : $eventDragStart);
				}
			}

			set_store_value(_iEvents, $_iEvents[0].end = addDuration(cloneDate(event.end), delta), $_iEvents);

			if (resizing()) {
				if ($_iEvents[0].end < minEnd) {
					set_store_value(_iEvents, $_iEvents[0].end = minEnd, $_iEvents);
					delta = createDuration(($_iEvents[0].end - event.end) / 1000);
				}
			} else if (selecting()) {
				if ($_iEvents[0].end < event.end) {
					set_store_value(_iEvents, $_iEvents[0].end = event.end, $_iEvents);
					set_store_value(_iEvents, $_iEvents[0].start = addDuration(cloneDate(event.start), delta), $_iEvents);
				} else {
					set_store_value(_iEvents, $_iEvents[0].start = event.start, $_iEvents);
				}
			} else {
				set_store_value(_iEvents, $_iEvents[0].start = addDuration(cloneDate(event.start), delta), $_iEvents);

				if (_viewResources) {
					set_store_value(_iEvents, $_iEvents[0].resourceIds = event.resourceIds.filter(id => id !== $_viewResources[resourceCol].id), $_iEvents);
					$_iEvents[0].resourceIds.push($_viewResources[newResourceCol].id);
				}
			}
		}

		if ($dragScroll) {
			animate(() => {
				if (toY < 0) {
					window.scrollBy(0, Math.max(-10, toY / 3));
				}

				if (toY < bodyRect.top) {
					bodyEl.scrollTop += Math.max(-10, (toY - bodyRect.top) / 3);
				}

				if (toY > window.innerHeight) {
					window.scrollBy(0, Math.min(10, (toY - window.innerHeight) / 3));
				}

				if (toY > bodyRect.bottom) {
					bodyEl.scrollTop += Math.min(10, (toY - bodyRect.bottom) / 3);
				}
			});
		}
	}

	function handleScroll() {
		if (action) {
			colRect = rect(colEl);
			bodyRect = rect(bodyEl);
			move();
		}
	}

	function handlePointerMove(jsEvent) {
		if (action && jsEvent.isPrimary) {
			toX = jsEvent.clientX;
			toY = jsEvent.clientY;
			move(jsEvent);
		}
	}

	function handlePointerUp(jsEvent) {
		if (selected && $unselectAuto && !($unselectCancel && jsEvent.target.closest($unselectCancel))) {
			unselect(jsEvent);
		}

		if (action && jsEvent.isPrimary) {
			if (interacting) {
				if (selecting()) {
					selected = true;

					if (is_function($select)) {
						let { start, end } = toEventWithLocalDates($_iEvents[0]);

						$select({
							start,
							end,
							startStr: toISOString($_iEvents[0].start),
							endStr: toISOString($_iEvents[0].end),
							allDay: isAllDay,
							jsEvent,
							view: toViewWithLocalDates($_view),
							resource: resourceCol !== undefined
							? $_viewResources[resourceCol]
							: undefined
						});
					}
				} else {
					event.display = 'auto';
					let callback = resizing() ? $eventResizeStop : $eventDragStop;

					if (is_function(callback)) {
						callback({
							event: toEventWithLocalDates(event),
							jsEvent,
							view: toViewWithLocalDates($_view)
						});
					}

					let oldEvent = cloneEvent(event);
					updateEvent(event, $_iEvents[0]);
					destroyIEvent();
					callback = resizing() ? $eventResize : $eventDrop;

					if (is_function(callback)) {
						let eventRef = event;

						let info = resizing()
						? { endDelta: delta }
						: {
								delta,
								oldResource: resourceCol !== newResourceCol
								? $_viewResources[resourceCol]
								: undefined,
								newResource: resourceCol !== newResourceCol
								? $_viewResources[newResourceCol]
								: undefined
							};

						callback(assign(info, {
							event: toEventWithLocalDates(event),
							oldEvent: toEventWithLocalDates(oldEvent),
							jsEvent,
							view: toViewWithLocalDates($_view),
							revert() {
								updateEvent(eventRef, oldEvent);
							}
						}));
					}
				}
			}

			colEl = rowEls = bodyEl = null;
			resourceCol = newResourceCol = undefined;
			$$invalidate(1, action = view = interacting = false);
			isAllDay = false;
			set_store_value(_iClass, $_iClass = undefined, $_iClass);
			$$subscribe__viewResources($$invalidate(2, _viewResources = undefined));

			if (timer) {
				clearTimeout(timer);
				$$invalidate(3, timer = undefined);
			}
		}
	}

	function createIEvent(jsEvent, callback) {
		if (is_function(callback)) {
			callback({
				event: toEventWithLocalDates(event),
				jsEvent,
				view: toViewWithLocalDates($_view)
			});
		}

		event.display = 'preview';
		set_store_value(_iEvents, $_iEvents[0] = cloneEvent(event), $_iEvents);
		event.display = 'ghost';
		_events.set($_events);
	}

	function createIEventSelect() {
		set_store_value(
			_iEvents,
			$_iEvents[0] = {
				id: '{select}',
				allDay: event.allDay,
				start: event.start,
				title: '',
				display: 'preview',
				extendedProps: {},
				backgroundColor: $selectBackgroundColor,
				resourceIds: event.resourceIds
			},
			$_iEvents
		);
	}

	function destroyIEvent() {
		set_store_value(_iEvents, $_iEvents[0] = null, $_iEvents);
	}

	function updateEvent(target, source) {
		target.start = source.start;
		target.end = source.end;
		target.resourceIds = source.resourceIds;
		_events.set($_events);
	}

	function distance() {
		return Math.sqrt(Math.pow(toX - fromX, 2) + Math.pow(toY - fromY, 2));
	}

	function dragging() {
		return action === ACTION_DRAG;
	}

	function resizing() {
		return action === ACTION_RESIZE;
	}

	function selecting() {
		return action === ACTION_SELECT;
	}

	function unselect(jsEvent) {
		if (selected) {
			selected = false;
			destroyIEvent();

			if (is_function($unselectFn)) {
				$unselectFn({
					jsEvent,
					view: toViewWithLocalDates($_view)
				});
			}
		}
	}

	// Clear selection on view params change
	_view.subscribe(unselect);

	function handleTouchStart(jsEvent) {
		if (action) {
			let target = jsEvent.target;
			let stops = [];
			let stop = () => run_all(stops);
			stops.push(listen(target, 'touchmove', createPreventDefaultHandler(() => interacting)));
			stops.push(listen(target, 'touchend', stop));
			stops.push(listen(target, 'touchcancel', stop));
		}
	}

	function touchmove_handler(event) {
		bubble.call(this, $$self, event);
	}

	const selectstart_handler = () => action;
	const contextmenu_handler = () => timer;

	return [
		handleScroll,
		action,
		_viewResources,
		timer,
		_iEvents,
		_iClass,
		_ignoreClick,
		_events,
		_viewDates,
		_view,
		datesAboveResources,
		dragScroll,
		eventDragMinDistance,
		eventDragStart,
		eventDragStop,
		eventDrop,
		eventLongPressDelay,
		eventResizeStart,
		eventResizeStop,
		eventResize,
		longPressDelay,
		select,
		selectBackgroundColor,
		selectLongPressDelay,
		selectMinDistance,
		slotDuration,
		slotHeight,
		hiddenDays,
		unselectFn,
		unselectAuto,
		unselectCancel,
		handlePointerMove,
		handlePointerUp,
		handleTouchStart,
		dragTimeGrid,
		dragDayGrid,
		selectTimeGrid,
		selectDayGrid,
		unselect,
		touchmove_handler,
		selectstart_handler,
		contextmenu_handler
	];
}

class Action extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$3,
			create_fragment$3,
			safe_not_equal,
			{
				dragTimeGrid: 34,
				dragDayGrid: 35,
				selectTimeGrid: 36,
				selectDayGrid: 37,
				handleScroll: 0,
				unselect: 38
			},
			null,
			[-1, -1, -1, -1]
		);
	}

	get dragTimeGrid() {
		return this.$$.ctx[34];
	}

	get dragDayGrid() {
		return this.$$.ctx[35];
	}

	get selectTimeGrid() {
		return this.$$.ctx[36];
	}

	get selectDayGrid() {
		return this.$$.ctx[37];
	}

	get handleScroll() {
		return this.$$.ctx[0];
	}

	get unselect() {
		return this.$$.ctx[38];
	}
}

/* packages/interaction/src/Pointer.svelte generated by Svelte v3.55.1 */

function create_fragment$2(ctx) {
	let mounted;
	let dispose;

	return {
		c: noop,
		m(target, anchor) {
			if (!mounted) {
				dispose = [
					listen(window, "pointermove", /*handlePointerMove*/ ctx[5]),
					listen(window, "scroll", /*handleScroll*/ ctx[0])
				];

				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			mounted = false;
			run_all(dispose);
		}
	};
}

function validEvent(jsEvent) {
	return jsEvent.isPrimary && jsEvent.pointerType === 'mouse';
}

function instance$2($$self, $$props, $$invalidate) {
	let $_iEvents;
	let $slotDuration;

	let $_slotTimeLimits,
		$$unsubscribe__slotTimeLimits = noop,
		$$subscribe__slotTimeLimits = () => ($$unsubscribe__slotTimeLimits(), $$unsubscribe__slotTimeLimits = subscribe(_slotTimeLimits, $$value => $$invalidate(17, $_slotTimeLimits = $$value)), _slotTimeLimits);

	let $slotHeight;
	$$self.$$.on_destroy.push(() => $$unsubscribe__slotTimeLimits());
	let { _iEvents, _events, _viewDates, slotDuration, slotHeight, hiddenDays, _view, datesAboveResources, theme } = getContext('state');
	component_subscribe($$self, _iEvents, value => $$invalidate(9, $_iEvents = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(16, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(18, $slotHeight = value));
	let y;
	let colDate;
	let colEl;
	let colRect;
	let resource;
	let _slotTimeLimits;
	let date;

	function enterTimeGrid(date, el, jsEvent, slotTimeLimitsStore, resourceObj) {
		if (validEvent(jsEvent)) {
			colDate = date;
			colEl = el;
			colRect = rect(colEl);
			y = jsEvent.clientY;
			$$subscribe__slotTimeLimits($$invalidate(1, _slotTimeLimits = slotTimeLimitsStore));
			resource = resourceObj;
		}
	}

	function enterDayGrid(date, jsEvent) {
		if (validEvent(jsEvent)) {
			colDate = date;
			colEl = null;
			y = $$subscribe__slotTimeLimits($$invalidate(1, _slotTimeLimits = resource = undefined));
		}
	}

	function leave(jsEvent) {
		if (validEvent(jsEvent)) {
			removePointerEvent();
		}
	}

	function move() {
		if (!colDate) {
			return;
		}

		if (colEl) {
			// timeGrid
			let ry = y - colRect.top;

			date = addDuration(cloneDate(colDate), $slotDuration, floor(ry / $slotHeight + $_slotTimeLimits.min.seconds / $slotDuration.seconds));
		} else {
			// dayGrid
			date = colDate;
		}

		if (!$_iEvents[1]) {
			createPointerEvent();
		}

		set_store_value(_iEvents, $_iEvents[1].start = date, $_iEvents);
		set_store_value(_iEvents, $_iEvents[1].end = addDuration(cloneDate(date), $slotDuration), $_iEvents);
		set_store_value(_iEvents, $_iEvents[1].resourceIds = resource ? [resource.id] : [], $_iEvents);
	}

	function handleScroll() {
		if (colEl) {
			colRect = rect(colEl);
			move();
		}
	}

	function handlePointerMove(jsEvent) {
		if (validEvent(jsEvent)) {
			y = jsEvent.clientY;
			move();
		}
	}

	function createPointerEvent() {
		set_store_value(
			_iEvents,
			$_iEvents[1] = {
				id: '{pointer}',
				title: '',
				display: 'pointer',
				extendedProps: {},
				backgroundColor: 'transparent'
			},
			$_iEvents
		);
	}

	function removePointerEvent() {
		colDate = colEl = set_store_value(_iEvents, $_iEvents[1] = null, $_iEvents);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_iEvents*/ 512) {
			if ($_iEvents[0]) {
				removePointerEvent();
			}
		}
	};

	return [
		handleScroll,
		_slotTimeLimits,
		_iEvents,
		slotDuration,
		slotHeight,
		handlePointerMove,
		enterTimeGrid,
		enterDayGrid,
		leave,
		$_iEvents
	];
}

class Pointer extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$2, create_fragment$2, safe_not_equal, {
			enterTimeGrid: 6,
			enterDayGrid: 7,
			leave: 8,
			handleScroll: 0
		});
	}

	get enterTimeGrid() {
		return this.$$.ctx[6];
	}

	get enterDayGrid() {
		return this.$$.ctx[7];
	}

	get leave() {
		return this.$$.ctx[8];
	}

	get handleScroll() {
		return this.$$.ctx[0];
	}
}

/* packages/interaction/src/Resizer.svelte generated by Svelte v3.55.1 */

function create_if_block$1(ctx) {
	let div;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].resizer);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(div, "pointerdown", /*pointerdown_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].resizer)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment$1(ctx) {
	let show_if = /*event*/ ctx[0].display === 'auto' && /*$_resizable*/ ctx[1](/*event*/ ctx[0]);
	let if_block_anchor;
	let if_block = show_if && create_if_block$1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*event, $_resizable*/ 3) show_if = /*event*/ ctx[0].display === 'auto' && /*$_resizable*/ ctx[1](/*event*/ ctx[0]);

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $_resizable;
	let $theme;
	let { event } = $$props;
	let { theme, _resizable } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _resizable, value => $$invalidate(1, $_resizable = value));

	function pointerdown_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('event' in $$props) $$invalidate(0, event = $$props.event);
	};

	return [event, $_resizable, $theme, theme, _resizable, pointerdown_handler];
}

class Resizer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, { event: 0 });
	}
}

/* packages/interaction/src/Auxiliary.svelte generated by Svelte v3.55.1 */

function create_if_block(ctx) {
	let pointer_1;
	let current;
	let pointer_1_props = {};
	pointer_1 = new Pointer({ props: pointer_1_props });
	/*pointer_1_binding*/ ctx[18](pointer_1);

	return {
		c() {
			create_component(pointer_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(pointer_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const pointer_1_changes = {};
			pointer_1.$set(pointer_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(pointer_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(pointer_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			/*pointer_1_binding*/ ctx[18](null);
			destroy_component(pointer_1, detaching);
		}
	};
}

function create_fragment(ctx) {
	let action;
	let t;
	let if_block_anchor;
	let current;
	let action_props = {};
	action = new Action({ props: action_props });
	/*action_binding*/ ctx[17](action);
	let if_block = /*$pointer*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			create_component(action.$$.fragment);
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			mount_component(action, target, anchor);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const action_changes = {};
			action.$set(action_changes);

			if (/*$pointer*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$pointer*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(action.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(action.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			/*action_binding*/ ctx[17](null);
			destroy_component(action, detaching);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_interaction;
	let $_scroll;
	let $editable;
	let $eventDurationEditable;
	let $_resizable;
	let $eventStartEditable;
	let $_draggable;
	let $theme;
	let $_classes;
	let $pointer;
	let { theme, editable, eventStartEditable, eventDurationEditable, pointer, _interaction, _classes, _draggable, _resizable, _scroll } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(16, $theme = value));
	component_subscribe($$self, editable, value => $$invalidate(12, $editable = value));
	component_subscribe($$self, eventStartEditable, value => $$invalidate(14, $eventStartEditable = value));
	component_subscribe($$self, eventDurationEditable, value => $$invalidate(13, $eventDurationEditable = value));
	component_subscribe($$self, pointer, value => $$invalidate(1, $pointer = value));
	component_subscribe($$self, _interaction, value => $$invalidate(0, $_interaction = value));
	component_subscribe($$self, _classes, value => $$invalidate(21, $_classes = value));
	component_subscribe($$self, _draggable, value => $$invalidate(15, $_draggable = value));
	component_subscribe($$self, _resizable, value => $$invalidate(20, $_resizable = value));
	component_subscribe($$self, _scroll, value => $$invalidate(19, $_scroll = value));
	set_store_value(_interaction, $_interaction.resizer = Resizer, $_interaction);

	set_store_value(
		_scroll,
		$_scroll = () => {
			for (let component of Object.values($_interaction)) {
				component?.handleScroll?.();
			}
		},
		$_scroll
	);

	function action_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_interaction.action = $$value;
			_interaction.set($_interaction);
		});
	}

	function pointer_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_interaction.pointer = $$value;
			_interaction.set($_interaction);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$eventStartEditable, $editable*/ 20480) {
			set_store_value(_draggable, $_draggable = event => (event.startEditable ?? $eventStartEditable) || (event.editable ?? $editable), $_draggable);
		}

		if ($$self.$$.dirty & /*$_draggable, $theme*/ 98304) {
			set_store_value(
				_classes,
				$_classes = (className, event) => {
					switch (event.display) {
						case 'auto':
							return className + ($_draggable(event) ? ' ' + $theme.draggable : '');
						case 'ghost':
							return `${$theme.event} ${$theme.ghost}`;
						case 'preview':
							return `${$theme.event} ${$theme.preview}`;
						case 'pointer':
							return `${$theme.event} ${$theme.pointer}`;
						default:
							return className;
					}
				},
				$_classes
			);
		}

		if ($$self.$$.dirty & /*$eventDurationEditable, $editable*/ 12288) {
			set_store_value(_resizable, $_resizable = event => (event.durationEditable ?? $eventDurationEditable) || (event.editable ?? $editable), $_resizable);
		}
	};

	return [
		$_interaction,
		$pointer,
		theme,
		editable,
		eventStartEditable,
		eventDurationEditable,
		pointer,
		_interaction,
		_classes,
		_draggable,
		_resizable,
		_scroll,
		$editable,
		$eventDurationEditable,
		$eventStartEditable,
		$_draggable,
		$theme,
		action_binding,
		pointer_1_binding
	];
}

class Auxiliary extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		options.dragScroll = true;
		options.editable = false;
		options.eventDragMinDistance = 5;
		options.eventDragStart = undefined;
		options.eventDragStop = undefined;
		options.eventDrop = undefined;
		options.eventDurationEditable = true;
		options.eventLongPressDelay = undefined;
		options.eventResizeStart = undefined;
		options.eventResizeStop = undefined;
		options.eventResize = undefined;
		options.eventStartEditable = true;
		options.longPressDelay = 1000;
		options.pointer = false;
		options.select = undefined;
		options.selectBackgroundColor = undefined;  // ec option
		options.selectLongPressDelay = undefined;
		options.selectMinDistance = 5;
		options.unselect = undefined;
		options.unselectAuto = true;
		options.unselectCancel = '';
		options.theme.draggable = 'ec-draggable';
		options.theme.ghost = 'ec-ghost';
		options.theme.preview = 'ec-preview';
		options.theme.pointer = 'ec-pointer';
		options.theme.resizer = 'ec-resizer';
		options.theme.dragging = 'ec-dragging';
		options.theme.resizingY = 'ec-resizing-y';
		options.theme.resizingX = 'ec-resizing-x';
		options.theme.selecting = 'ec-selecting';
	},

	createStores(state) {
		state._auxiliary.update($_auxiliary => [...$_auxiliary, Auxiliary]);
	}
};

export { index as default };
