import {BaseComponent} from "@symbiotejs/symbiote";
import {PwaGuard} from "../pwa-guard/pwa-guard.js";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant.js";
import {I18n} from "../../utilities/i18n/i18n.js";

const image = require('./assets/success.gif')
const arrow = require('./assets/arrow.gif')


const css = `
    <style>
    success-container {
            z-index:2000000;
        background-color: var(--bs-body-bg);
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: none;
        }
        
        success-container #splashBadge {
            position: relative;
            left: 50%;
            transform: translatex(-51%);
            height: 50dvh;
        }
    </style>
 `

export class SuccessContainer extends BaseComponent {
    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        SuccessContainer.translateShit();
        document.getElementById('leaveSuccess').addEventListener('click', function () {
            RouterAssistant.redirect('profiles-page')
        })
        setTimeout(function () {
            if (PwaGuard.accessIsThroughIphone() === 0) document.getElementById('leaveSuccess').style.opacity = '1';
             else document.getElementById('exitWebView').style.opacity = '1';
        }, 3000)
    }

    static translateShit(){
        document.getElementById('successPageHeader').innerText = I18n.translateString('successPage.title')
        document.getElementById('successPageDescription').innerText = I18n.translateString('successPage.description')
        document.getElementById('leaveSuccess').innerText = I18n.translateString('successPage.button')
    }
}

SuccessContainer.template = /*html*/ `
<div style="
    width: fit-content;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
"><img id="splashBadge" src="${image}">
<h1 id="successPageHeader" class="text-center" id="networkGuardTitle">Congratulations!</h1>
<h4 id="successPageDescription" class="text-center" id="networkGuardDescription">You are now a PRO</h4>
<button id="leaveSuccess" class="btn btn-primary mt-5" style="
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    transition: all 1s ease-in-out;
    opacity: 0;
">Continue</button></div>
<img id="exitWebView" src="${arrow}" style="
    width: 10rem;
    transform: rotate(250deg);
    position: fixed;
    left: -5%;
    top: 0;
    transition: all 1s ease-in-out;
    opacity: 0;
">
`;

SuccessContainer.reg('success-container');
