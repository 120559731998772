import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n.js";
import 'js-camera';
import {FirebaseAssistant} from "../../utilities/firebase-assistant/firebase-assistant.js";
import {EventEditor} from "../event-editor/event-editor.js";

const css = `
    <style>
    img[src=""],
img:not([src]){
  opacity: 0;
}
    </style>
 `

export class CameraOverlay extends BaseComponent {
    initCallback() {
        try {
            document.head.insertAdjacentHTML("beforeend", css)
            document.getElementById('cameraAccessPrompt').innerHTML = I18n.translateString('cameraOverlay.accessPrompt')
            document.getElementById('cameraAccessPrompt').style.display = 'none'
            const camera = document.querySelector('#camera');

            navigator.permissions.query({name: "camera"})
                .then(res => {
                    if (res.state == "granted") {
                        console.debug(res.state)
                        //do nothing all good here
                    } else {
                        console.debug(res.state)
                        CameraOverlay.showcaseCameraAccessPromptText()
                    }
                })
                .catch(function (err) {
                    console.debug(err)
                    CameraOverlay.showcaseCameraAccessPromptText()
                });

            window.camera = camera;
            window.photosList = []

            camera
                .on('opened', () => {
                    window.photosTaken = 0;
                    window.photosList = []
                    document.getElementById('camera').style.opacity = '1'
                })
                .on('tookphoto', evt => {
                    // Returns the photo taken from the shoot button on the camera controller
                    // The captured image can be received from "event.detail.dat" in base64 format.
                    if (window.photosTaken === 0) {
                        document.getElementById('cameraReset').style.opacity = '1';
                        document.getElementsByClassName("camera-captured")[0].style.opacity = '1';
                    }
                    window.photosTaken += 1;
                    const el = document.getElementsByClassName("camera-captured")[0];
                    if (window.photosTaken === 1) {
                        el.innerHTML = el.innerHTML.split('>')[0] + `>${I18n.translateString('cameraOverlay.upload')}<br>${window.photosTaken} ${I18n.translateString('cameraOverlay.photo')}`;
                    } else {
                        el.innerHTML = el.innerHTML.split('>')[0] + `>${I18n.translateString('cameraOverlay.upload')}<br>${window.photosTaken} ${I18n.translateString('cameraOverlay.photos')}`;
                    }
                    let timestamp = new Date()
                    timestamp = timestamp.toUTCString().replaceAll(" ", "_").replaceAll(",", "").replaceAll(":", "_").replace("GMT", `${timestamp.getMilliseconds()}_GMT`)
                    window.photosList.push([timestamp + '.png', evt.detail.base64.slice(22), evt.detail.base64])
                });
            document.getElementsByClassName("camera-player")[0].remove()
            document.getElementsByClassName("camera-switch-face-button")[0].remove()

            document.querySelectorAll('.camera-captured img')[0].style.width = '3rem'
            document.querySelectorAll('.camera-captured img')[0].style.height = '3rem'
            document.querySelectorAll('.camera-captured img')[0].style.marginRight = '1rem'

            const elem = document.getElementsByClassName("camera-captured")[0];
            elem.style.display = 'flex'
            elem.style.position = 'absolute'
            elem.classList.add('align-items-center')
            elem.classList.add('btn')
            elem.classList.add('btn-outline-primary')
            elem.style.height = '4rem'
            elem.style.width = '40%'
            elem.style.left = '1rem'
            elem.style.marginBottom = '0.4rem'
            elem.style.padding = 'none'
            elem.style.opacity = '0'
            elem.style.border = 'none'

            document.getElementsByClassName("camera-capture-button")[0].style.position = 'absolute'
            document.getElementsByClassName("camera-capture-button")[0].style.top = '50%'
            document.getElementsByClassName("camera-capture-button")[0].style.left = '50%'
            document.getElementsByClassName("camera-capture-button")[0].style.transform = 'translate(-50%, -50%)'


            // document.getElementsByClassName("camera-controls")[0].style.bottom = '8dvh'
            // document.getElementsByClassName("camera-controls")[0].style.bottom = '2rem'

            const videoElement = document.getElementsByClassName("camera-video")[0];
            videoElement.style.objectFit = 'contain'
            videoElement.addEventListener("loadedmetadata", function (e) {
                // setTimeout(function () {
                videoElement.style.height = 'unset'
                videoElement.style.top = '50%'
                videoElement.style.transform = 'translate(0, -50%)'

                const videoBoundingRectangle = videoElement.getBoundingClientRect()
                const controlsBoundingRectangle = document.getElementsByClassName("camera-captured")[0].getBoundingClientRect()
                document.getElementsByClassName("camera-controls")[0].style.position = 'fixed'
                document.getElementsByClassName("camera-controls")[0].style.bottom = `${videoBoundingRectangle.bottom - videoBoundingRectangle.height - controlsBoundingRectangle.height + 10}px`

                // }, 500)
            }, false);


            document.getElementsByClassName("camera-controls-content")[0].insertAdjacentHTML("beforeend", `<button id="cameraReset" class="btn btn-outline-light" style="text-align:center;right:1rem;line-height:1.5rem;width:37%;opacity: 0;border-radius: 4px;position: absolute">${I18n.translateString('cameraOverlay.resetPhotos')}</button>`)
            document.getElementsByClassName("camera-controls-content")[0].classList.add('align-items-center')
            document.getElementsByClassName("camera-controls-content")[0].style.padding = ""
            document.getElementById("cameraReset").style.minHeight = elem.clientHeight + 'px';
            document.getElementById('cameraReset').addEventListener('click', () => {
                window.photosTaken = 0;
                window.photosList = [];
                document.getElementById('cameraReset').style.opacity = 0;
                document.getElementsByClassName("camera-captured")[0].style.opacity = 0;
            })
            document.getElementsByClassName("camera-captured")[0].addEventListener('click', () => {
                CameraOverlay.finaliseCameraProcedure()
                document.body.insertAdjacentHTML("beforeend", `<div id="cameraUploaderSplash" style=" text-align: center; position: fixed; width: 100%; height: 100%; top: 0; left: 0; background: #1E2125; z-index: 9999; "><span id="photoUploadCurrentState" style=" position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">${I18n.translateString('cameraOverlay.photosUploadingSplasher')}<br><br><div ><div class="spinner-grow" role="status"> <span class="visually-hidden">Loading...</span> </div><div class="spinner-grow" role="status"> <span class="visually-hidden">Loading...</span> </div><div class="spinner-grow" role="status"> <span class="visually-hidden">Loading...</span> </div></div> </span></div>`)
                if (window.location.href.includes('uploader')) {
                    const guid = window.location.href.split('uploader').pop().split("/")[2]
                    const userId = window.location.href.split('uploader').pop().split("/")[1]
                    FirebaseAssistant.uploadCameraPhotosToBeTransferredToEvent(guid, userId, window.photosList, function () {
                        FirebaseAssistant.finaliseCameraPhotosTransferDirectory(userId, guid)
                        document.getElementById('photoUploadCurrentState').innerHTML = I18n.translateString('cameraOverlay.uploadDone')
                    })
                } else {
                    // do nothing everything will be done when the user saves the form (we just need to show the files in the form)
                    EventEditor.showFilesOnEditor('camera');
                    document.getElementById('cameraUploaderSplash').remove()
                }
            })

        } catch (e) {
            alert(e)
        }
    }

    static showcaseCameraAccessPromptText() {
        document.getElementById('cameraAccessPrompt').style.display = 'block'
    }

    static disabledInputs(boolean) {
        const inputs = document.getElementsByTagName("INPUT");
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].disabled = boolean;
        }
        const textAreas = document.getElementsByTagName("TEXTAREA");
        for (let i = 0; i < textAreas.length; i++) {
            textAreas[i].disabled = boolean;
        }
    }


    static finaliseCameraProcedure() {
        CameraOverlay.disabledInputs(false)
        window.camera.close()
        document.getElementsByTagName('camera-overlay')[0].remove();
        try {
            document.getElementById('exitCamera').remove();
        } catch (e) {
        }
    }

    static initiate() {
        if (window.location.href.includes('uploader')) {
            // nothing for now
        } else {
            document.body.insertAdjacentHTML("beforeend", `<button id="exitCamera" type="button" class="btn" style=" font-size: xxx-large; position: fixed; z-index: 5000; left: 1rem; top: 1rem; color: lavenderblush; "><i class="bx bx-arrow-back"></i></button>`)
            document.getElementById("exitCamera").addEventListener('click', CameraOverlay.finaliseCameraProcedure)
        }
        CameraOverlay.disabledInputs(true)
        document.body.insertAdjacentHTML("beforeend", `<camera-overlay style=" position: fixed; top: 0; width: 100%; height: 100%; z-index: 2000;background: black;">`)
    }
}

CameraOverlay.template = /*html*/ `
<span id="cameraAccessPrompt" style="
    position: absolute;
    left: 50%;
    top: 50%;
    display: none;
    text-align: center;
    transform: translate(-50%, -50%);
"></span>
    <js-camera id="camera" controls autoplay facing="back" width="1920" height="1440" style="opacity: 0"></js-camera>
`;

CameraOverlay.reg('camera-overlay');
