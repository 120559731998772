import {BaseComponent} from "@symbiotejs/symbiote";
import {I18n} from "../../utilities/i18n/i18n";
import {RouterAssistant} from "../../utilities/router-assistant/router-assistant";

const css = ``

export class ActionVerifier extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)

        window.actionVerifierModal = new window.bootstrap.Modal(document.getElementById('actionVerifierModal'))
        window.actionVerifierModalCallbackConfirmed = function () {
        }
        document.getElementById('cancelActionVerifierModal').addEventListener('click', () => {
            window.actionVerifierModal.hide()
            RouterAssistant.deactivateCurtain()
        })
        document.getElementById('confirmActionVerifierModal').addEventListener('click', () => {
            window.actionVerifierModalCallbackConfirmed()
        })

        document.getElementById('cancelActionVerifierModal').innerText = I18n.translateString('actionVerifier.cancel')
    }

    static enableActionVerifier(message, confirmButtonText, confirmationCallback) {
        // console.debug('attempting to activate')
        // if (!document.getElementById('actionVerifierModal').classList.contains('show')) {
        document.getElementById('actionVerifierModalMessage').innerText = message
        document.getElementById('confirmActionVerifierModal').innerText = confirmButtonText
        window.actionVerifierModal.show()
        // RouterAssistant.activateCurtain(0.8, 1056)
        RouterAssistant.activateCurtain(0.8, 1060)
        window.actionVerifierModalCallbackConfirmed = function () {
            RouterAssistant.deactivateCurtain()
            window.actionVerifierModal.hide()
            confirmationCallback()
        }
        // }
    }
}

ActionVerifier.template = /*html*/ `
<div class="modal fade" id="actionVerifierModal" style="z-index: 1000000000" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div id="actionVerifierModalBody" class="modal-body">
          <h5 id="actionVerifierModalMessage" class="mt-2 mb-1">Αφαίρεση αρχείου "ταδε.png";</h5>      
            </div>
            <div class="modal-footer">
                <button id="cancelActionVerifierModal" type="button" class="btn btn-secondary" >Ακύρωση</button>
                <button id="confirmActionVerifierModal" type="button" class="btn btn-danger">Αφαίρεση</button>
            </div>
        </div>
    </div>
</div>
`

ActionVerifier.reg('action-verifier');
